import _ from 'lodash';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { GlobalState } from 'reducers';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Grid, Input, Checkbox, ObjectDropdown } from 'buildingBlocks';
import { OutcomeDetailsType, WizardFormValues } from 'containers/StrategyWizard/types';
import { REVENUE_TYPES_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import { validatedFieldWrapper } from 'utils/wrapperComponentsReactHookForms';
import { getBudgetKeyWithPrefix, numberValidate, removeAdditionalDots } from 'containers/StrategyWizard/steps/GoalSelection/utils';
import { RevTypeOption } from 'containers/StrategyWizard/steps/GoalSelection/constants';
import ValidationMessages from './ValidationMessages';

const {
  leftPadding, noMarginTop, weightRowStyle,
  borderRight, outcomesRowCheckbox, outcomeValueWidth,
} = REVENUE_TYPES_STYLES;

type OutcomesOptionRowProps = {
  outcomeDetails: OutcomeDetailsType
  externalId: string
  selectedObjects: Array<string>
  setSelectedObjs: Dispatch<SetStateAction<Array<string>>>
  updateSingleOutcome: (extId: string, value: string | number) => void
  revenueTypeOptions: Array<RevTypeOption>
};

const ValidatedInput = validatedFieldWrapper(Input);

const OutcomesOptionRow = (props: OutcomesOptionRowProps) => {
  const {
    selectedObjects, setSelectedObjs, updateSingleOutcome,
    externalId, outcomeDetails, revenueTypeOptions,
  } = props;

  const {
    attachFlightsStep: { defaultCurrency },
  } = useSelector<GlobalState>((state) => state.strategyWizard) as WizardFormValues;

  const currencyCode = _.get(defaultCurrency, 'code', 'USD');
  const { control, setValue, formState: { errors } } = useFormContext();
  const { externalType, revenueValue, name } = outcomeDetails;
  const budgetKey = getBudgetKeyWithPrefix(externalId);
  const hasOutcomeErrors = _.get(errors, `budget[${budgetKey}].outcome`);
  const hasValueErrors = _.get(errors, `budget[${budgetKey}].revenueValue`);
  const budgetObj = useWatch({ name: `budget[${budgetKey}]` });
  const revValue = useWatch({ name: `budget[${budgetKey}].revenueValue`, control });
  const optionExtType = useWatch({ name: `budget[${budgetKey}].externalType`, control });

  useEffect(() => {
    // ensures externalType is set on form. in edgecases where user swaps flight in single platform flow, externalType isnt being set properly
    if (!optionExtType) {
      setValue(`budget.${budgetKey}.externalType`, externalType);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [budgetObj]);

  const checkboxClickHandle = (extId) => {
    const tempArray = [...selectedObjects];
    if (_.includes(tempArray, extId)) {
      const index = tempArray.indexOf(extId);
      tempArray.splice(index, 1);
    } else {
      tempArray.push(extId);
    }
    setSelectedObjs(tempArray);
  };

  return (
    <Grid key={externalId} style={noMarginTop}>
      <Grid.Row columns={4} style={weightRowStyle} className="multipleRevenueObjectsRow">
        <Grid.Column width={2} style={borderRight}>
          <Checkbox
            onClick={() => checkboxClickHandle(externalId)}
            checked={_.includes(selectedObjects, externalId)}
            style={outcomesRowCheckbox}
          />
          <span>{externalId}</span>
        </Grid.Column>
        <Grid.Column width={6}>
          <span className="revenueObjName">{name}</span>
        </Grid.Column>
        <Grid.Column width={2} style={leftPadding}>
          <Controller
            name={`budget[${budgetKey}].outcome`}
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <ObjectDropdown
                options={revenueTypeOptions}
                keyFn={(o) => o.text}
                onChange={(e) => {
                  updateSingleOutcome(externalId, e.value);
                  onChange(e.value);
                }}
                text={_.toUpper(value)}
                selection
                fluid
                className={hasOutcomeErrors && 'error'}
              />
            )}
          />
        </Grid.Column>
        <Grid.Column width={2} style={leftPadding}>
          <Controller
            name={`budget[${budgetKey}].revenueValue`}
            control={control}
            rules={{
              required: true,
              validate: {
                greaterThanZero: (v) => _.toNumber(v) > 0,
              },
            }}
            render={(properties) => (
              <ValidatedInput
                style={outcomeValueWidth}
                type="text"
                onChange={(e) => {
                  // Allow for just 4 decimal places
                  const val = removeAdditionalDots(e.target.value).split('.').map((el, i) => (i ? el.split('').slice(0, 4).join('') : el)).join('.');
                  e.target.value = val === '.' ? '0.' : val;
                  // When decimal values are removed via backspace, cursor should not go to the beginning
                  const prevIncludesDecimal = _.includes(revenueValue?.toString(), '.');
                  const currentIncludesDecimal = _.includes(e.target.value, '.');
                  if (prevIncludesDecimal && !currentIncludesDecimal) {
                    e.target.selectionStart = 100;
                    e.target.selectionEnd = 100;
                  }
                  properties.field.onChange(e.target.value);
                  updateSingleOutcome(externalId, e.target.value);
                }}
                title=""
                label={currencyCode}
                labelPosition="right"
                onKeyDown={(event) => numberValidate(event)}
                {..._.omit(properties, 'formState')}
                className={hasValueErrors && 'error'}
              />
            )}
          />
        </Grid.Column>
      </Grid.Row>
      <ValidationMessages
        hasOutcomeErrors={!!hasOutcomeErrors}
        hasValueErrors={!!hasValueErrors}
        isValueEmpty={_.isEmpty(revValue)}
      />
    </Grid>
  );
};

export default OutcomesOptionRow;
