import _ from 'lodash';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Image, Modal } from 'buildingBlocks';
import { MODAL_STYLES } from 'containers/StrategyWizard/steps/AttachFlights/styles';
import { IMPORT_FILE_IMG, MODAL_CLOSE_IMG } from 'containers/StrategyWizard/steps/GoalSelection/constants';
import { FORMULA_SECTION_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import { useAWGContext } from 'containers/StrategyWizard/steps/GoalSelection/GoalSection/AWG/contexts/AWGProvider';
import { useFormulaContext } from 'containers/StrategyWizard/steps/GoalSelection/GoalSection/AWG/contexts/FormulaProvider';
import GrabFormulaModalContent from './GrabFormulaModalContent';

const { grabFormulaStyle, iconPaddingStyle } = FORMULA_SECTION_STYLES;

type GrabFormulaModalProps = {
  openModal: boolean
  setOpenModal: Dispatch<SetStateAction<boolean>>
  onModalClose: Function
};

const GrabFormulaModal = ({ openModal, setOpenModal, onModalClose }: GrabFormulaModalProps) => (
  <Modal
    closeOnEscape={false}
    closeOnDimmerClick={false}
    open={openModal}
    onClose={() => onModalClose()}
    size="large"
    dimmer="blurring"
    style={MODAL_STYLES.modal}
    className="grabFormulaModal"
  >
    <Image src={MODAL_CLOSE_IMG} style={MODAL_STYLES.closeIcon} onClick={() => onModalClose()} />
    <Modal.Header style={MODAL_STYLES.header}>Grab Formula</Modal.Header>
    <Modal.Content style={FORMULA_SECTION_STYLES.grabFormulaModalContent}>
      <GrabFormulaModalContent setOpenModal={setOpenModal} />
    </Modal.Content>
  </Modal>
);

const GrabFormula = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { searchInputRef, dropzoneRef } = useFormulaContext();
  const { selectedFormula } = useAWGContext();
  const { setValue } = useFormContext();
  const customGoal = useWatch({ name: 'customGoal' });

  const onClickHandle = () => {
    setOpenModal(true);
  };

  const onModalClose = () => {
    const customFormulaArr = _.get(customGoal, 'formula');
    const selectedFormulaArr = _.get(selectedFormula, 'formula');
    if (!customFormulaArr && selectedFormulaArr) {
      setValue('customGoal.formula', selectedFormulaArr);
    }

    setOpenModal(false);
    searchInputRef.current = null;
    dropzoneRef.current.focus();
  };

  return (
    <>
      <div style={grabFormulaStyle} onClick={onClickHandle} role="button" tabIndex={0}>
        <Image src={IMPORT_FILE_IMG} style={iconPaddingStyle} alt="grabFormula" />
        Grab Formula
      </div>
      <GrabFormulaModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        onModalClose={onModalClose}
      />
    </>
  );
};

export default GrabFormula;
