import _ from 'lodash';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Dropdown } from 'buildingBlocks';
import { WizardFormGoalSelection } from 'containers/StrategyWizard/types';
import { isAWGOrImpact } from 'containers/StrategyWizard/steps/GoalSelection/utils';
import { useGoalSectionContext } from 'containers/StrategyWizard/steps/GoalSelection/contexts/GoalSectionProvider';
import { checkPermissions, Permission } from 'utils/featureFlags';

type Props = {
  initialFormValues: WizardFormGoalSelection
  resetConfirmedGoal: Function
};

const REVENUE_TYPE_OPTIONS = [
  {
    key: 'disabled',
    text: 'Platform Reported',
    value: false,
  },
  {
    key: 'enabled',
    text: 'Customize',
    value: true,
  },
];

const RevenueTypeConfiguration = ({
  initialFormValues,
  resetConfirmedGoal,
}: Props) => {
  const {
    revenueTypeEnabled, setRevenueTypeEnabled, disableRevType,
    isCrossPlatformOptimization, strategyId, user, isCampaignOptimization,
    reduxWizardFormValues: {
      goalSelectionStep: { budget: reduxBudget },
    },
  } = useGoalSectionContext();

  const { setValue, reset } = useFormContext();

  const goalType = useWatch({ name: 'goal.type' });
  const customGoal = useWatch({ name: 'customGoal' });
  const budgetSettings = useWatch({ name: 'budgetSettings' });

  const isAwgOrImpact = isAWGOrImpact(goalType);
  const hasCrossPlatformRevTypePermission = checkPermissions(user, [Permission.crossPlatformRevenueTypeGoals]);

  const handleRevTypeToggleChange = (_e, { value }) => {
    if (value) {
      // handles toggling off and on rev type in edit flow
      if (_.size(reduxBudget)) {
        reset({ ...initialFormValues, budgetSettings });
      } else {
        // these two keys should not exist for when revenue type config is on
        setValue('goal.impValueFilters', null);
        // if awg or impact configured already, we don't reset awg config
        if (!isAwgOrImpact) {
          setValue('customGoal', null);
          setValue('metricsConfig', null);
          setValue('goal.target', null);
        }
      }
    } else {
      if (_.isNil(strategyId) && _.isEmpty(customGoal)) {
        resetConfirmedGoal();
      }
      // only reset goal.type when goal.type isnt an AWG goal
      if (_.isEmpty(customGoal)) {
        setValue('goal.type', null);
      }
      setValue('revenueOutcomeType', null);
      setValue('budget', null);
    }
    // resetting of form when unchecked happens in useEffect in GoalSection.
    setRevenueTypeEnabled(!revenueTypeEnabled);
  };

  return (
    <>
      {(!disableRevType && (isCampaignOptimization || (isCrossPlatformOptimization && hasCrossPlatformRevTypePermission)))
        ? (
          <Dropdown
            className="revenue-calculation"
            placeholder="Platform Reported"
            fluid
            selection
            options={REVENUE_TYPE_OPTIONS}
            onChange={handleRevTypeToggleChange}
            value={revenueTypeEnabled}
          />
        )
        : <div style={{ textAlign: 'start' }}>Platform Reported</div>}
    </>
  );
};

export default RevenueTypeConfiguration;
