import React, { Dispatch, SetStateAction } from 'react';
import { Button } from 'buildingBlocks';
import { REVENUE_TYPES_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import { RevenueBulkUpdateTypes } from 'containers/StrategyWizard/types';

const { buttonStyle } = REVENUE_TYPES_STYLES;

type BulkUpdateProps = {
  setShowBulkUpdate: Dispatch<SetStateAction<boolean>>
  setShowBulkEdit: Dispatch<SetStateAction<boolean>>
  updateOutcomeObjects: Dispatch<SetStateAction<string>>
  resetRevOptions: () => void
};

const BulkUpdate = (props: BulkUpdateProps) => {
  const { setShowBulkUpdate, setShowBulkEdit, updateOutcomeObjects, resetRevOptions } = props;
  return (
    <div style={{ margin: '10px 0px' }}>
      <Button style={buttonStyle} onClick={() => { setShowBulkEdit(true); setShowBulkUpdate(false); }}>Bulk Edit</Button>
      <Button style={buttonStyle} onClick={() => { updateOutcomeObjects(RevenueBulkUpdateTypes.reset); resetRevOptions(); }}>Bulk Reset</Button>
      <Button style={buttonStyle} onClick={() => resetRevOptions()}>Cancel</Button>
    </div>
  );
};

export default BulkUpdate;
