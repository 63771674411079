import _ from 'lodash';
import React from 'react';
import { Button } from 'buildingBlocks';
import { GrabFormulaFilters } from 'containers/StrategyWizard/steps/GoalSelection/constants';
import { FORMULA_SECTION_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';

type FormulaFilterButtonProps = {
  filterName: GrabFormulaFilters
  filterValue: GrabFormulaFilters
  setFilterValue: (value: GrabFormulaFilters) => void
};

const GrabFormulaFilterButton = ({ filterName, filterValue, setFilterValue }: FormulaFilterButtonProps) => (
  <Button
    onClick={() => setFilterValue(filterName)}
    style={filterValue === filterName ? { ...FORMULA_SECTION_STYLES.button, ...FORMULA_SECTION_STYLES.activeButton } : FORMULA_SECTION_STYLES.button}
  >
    {_.capitalize(filterName)}
  </Button>
);

export default GrabFormulaFilterButton;
