import _ from 'lodash';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Button, Modal, Header } from 'buildingBlocks';
import { AWG_WEIGHTS_MODAL_STYLES, BUDGET_SECTION_STYLES, CONFIRMATION_MODAL } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import { FormulaType, WizardFormGoalSelection } from 'containers/StrategyWizard/types';
import { GlobalState } from 'reducers';

const { cancelBtnStyle, submitBtnStyle } = AWG_WEIGHTS_MODAL_STYLES;
const { modal, contentContainer, subheaderStyle,
  backButtonModalHeight, backButtonContentGap, modalButtonStyle,
} = CONFIRMATION_MODAL;

type BackButtonModalProps = {
  setShowAwgPage: Dispatch<SetStateAction<boolean>>
};

const BackButtonConfirmModal = ({ setShowAwgPage }: BackButtonModalProps) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const reduxGoalStep = useSelector<GlobalState>((state) => state.strategyWizard.goalSelectionStep) as WizardFormGoalSelection;
  const { resetField, clearErrors } = useFormContext();

  const handleBackButton = () => {
    clearErrors('customGoal');
    resetField('customGoal', { defaultValue: _.get(reduxGoalStep, 'customGoal', {}) as Partial<FormulaType> });
    resetField('metricsConfig', { defaultValue: _.get(reduxGoalStep, 'metricsConfig', {}) as Partial<FormulaType> });
    setShowAwgPage(false);
  };

  return (
    <Modal
      open={openModal}
      style={{ ...modal, ...backButtonModalHeight }}
      trigger={(
        <Button
          primary
          type="button"
          content="Back"
          style={cancelBtnStyle}
          onClick={() => setOpenModal(true)}
        />
      )}
    >
      <Modal.Content style={{ ...contentContainer, ...backButtonContentGap }}>
        <Header as="h4" content="Are you sure you want to go back?" style={BUDGET_SECTION_STYLES.header} />
        <div style={subheaderStyle}>All changes will be discarded. </div>
        <div style={modalButtonStyle}>
          <Button
            type="button"
            content="Cancel"
            style={cancelBtnStyle}
            onClick={() => setOpenModal(false)}
          />
          <Button
            type="button"
            primary
            content="Yes"
            style={submitBtnStyle}
            onClick={handleBackButton}
          />
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default BackButtonConfirmModal;
