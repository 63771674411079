import React from 'react';
import { Table, Popup, Icon, Button } from 'buildingBlocks';
import DspIcon from 'components/DspIcon';
import FlightDetailsPopUp from 'components/FlightDetailsPopUp';
import style from 'containers/StrategyWizard/styles';
import { getDspFromExtTypeId, getFlightPopupMessage } from 'containers/StrategyWizard/utils';
import { COPILOT_COLORS, COPILOT_LAYOUT } from 'globalStyles';
import { Flight, ButtonClickHandler } from 'utils/types';
import { FlightStatus } from './FlightStatus';

const { NEW_DESIGN_SYSTEM: { YELLOWS, NEUTRALS } } = COPILOT_COLORS;

type DefaultFlightDetailsWrapperProps = {
  flight: Flight
  advertiserExtId: string
  memberExtId: string
  alreadyAttached: boolean
};

const DefaultFlightDetailsWrapper = ({
  flight,
  advertiserExtId,
  memberExtId,
  alreadyAttached,
} : DefaultFlightDetailsWrapperProps) => (
  <div style={{ display: 'flex' }}>
    <Popup
      hoverable
      popper={<div className="popup" />}
      trigger={(
        <div style={style.flightRowIconContainer}>
          <Icon
            className="attach-modal-info-icon"
            name="info"
            style={{ ...style.flightRowIcon, color: (alreadyAttached ? YELLOWS.Y500_GOLD : NEUTRALS.N600_ABBEY), border: `1px solid ${alreadyAttached ? YELLOWS.Y500_GOLD : NEUTRALS.N600_ABBEY}` }}
            bordered
            circular
          />
        </div>
      )}
      content={(
        <FlightDetailsPopUp
          messages={getFlightPopupMessage(flight, advertiserExtId, memberExtId)}
          header="DSP Object Details"
        />
      )}
      position="left center"
      wide="very"
    />
    <DspIcon dspId={getDspFromExtTypeId(flight.externalType)} style={{ marginRight: COPILOT_LAYOUT.SPACING[8] }} />
    <span style={style.modalFlightName}>{`[${flight.externalId}] - ${flight.name}`}</span>
  </div>
);

type Props = {
  flightStatus: Flight
  strategy: number
  flightOverrideStrategy: ButtonClickHandler
  color: string
  alreadyAttached: boolean
  notEligible: boolean
  flight: Flight
  advertiserExtId: string
  memberExtId: string
  removeFlight?: ButtonClickHandler
};

export const FlightRow = ({
  flightStatus,
  strategy,
  flightOverrideStrategy,
  color,
  alreadyAttached,
  notEligible,
  flight,
  advertiserExtId,
  memberExtId,
  removeFlight,
}: Props) => (
  <Table.Row>
    <Table.Cell
      width={12}
      style={{ display: 'table', margin: 'initial', padding: 'initial', paddingLeft: '8px', tableLayout: 'fixed', wordBreak: 'break-word', borderSpacing: '8px' }}
    >
      <div
        style={{
          display: 'table-cell',
          padding: COPILOT_LAYOUT.SPACING[8],
          borderLeft: `4px solid ${color}`,
          borderRadius: 4,
          position: 'relative',
          left: -COPILOT_LAYOUT.SPACING[8],
        }}
      >
        <DefaultFlightDetailsWrapper flight={flight} advertiserExtId={advertiserExtId} memberExtId={memberExtId} alreadyAttached={alreadyAttached} />
        <FlightStatus
          status={flightStatus}
          strategy={strategy}
          flightOverrideStrategy={flightOverrideStrategy}
          removeFlight={removeFlight}
          alreadyAttached={alreadyAttached}
          notEligible={notEligible}
        />
      </div>
      <div style={{ display: 'table-cell', verticalAlign: 'middle', textAlign: 'right', width: '5%' }}>
        {
            flightStatus && removeFlight
            && (
            <Button
              basic
              style={style.a}
              onClick={removeFlight}
            >
              <Icon name="remove" />
            </Button>
            )
          }
      </div>
    </Table.Cell>
  </Table.Row>
);

export default FlightRow;
