import _ from 'lodash';
import React, { useState } from 'react';
import { Accordion } from 'buildingBlocks';
import { AttachFlightsInfoType } from 'containers/StrategyWizard/types';
import { flightKey } from 'containers/StrategyWizard/utils';
import { Flight } from 'utils/types';
import AttachFlightsDetails from './AttachFlightsDetails';
import ChildLevelFlightDetails from './ChildLevelFlightDetails';

type ActiveFlightsTablesProps = {
  activeFlights: Array<Flight>
  removeFlight: Function
  attachFlightsInfo: AttachFlightsInfoType
  isHigherOrderOptLvl: boolean
  editingCrossPlatformStrat: boolean
};

type HigherOrderFlightDetailsProps = {
  flight: Flight
  index: number
  flightCategory: string
  isHigherOrderOptLvl: boolean
  removeFlight: Function
  editingCrossPlatformStrat: boolean
};

const HigherOrderFlightDetails = (props: HigherOrderFlightDetailsProps) => {
  const { flight, index, flightCategory, isHigherOrderOptLvl, removeFlight, editingCrossPlatformStrat } = props;
  const [activeIndex, setActiveIndex] = useState<number>(-1);

  const handleOnClick = (ind: number) => setActiveIndex(activeIndex === ind ? -1 : ind);

  return (
    <Accordion>
      <Accordion.Title
        active={activeIndex === index}
        index={index}
        style={{ paddingBottom: '0px', ...(index > 0 && { paddingTop: '0px' }) }}
      >
        <AttachFlightsDetails
          key={`${flightKey(flight)} - ${flight.id}`}
          index={index}
          flight={flight}
          isHigherOrderOptLvl={isHigherOrderOptLvl}
          flightCategory={flightCategory}
          removeFlight={removeFlight}
          activeIndex={activeIndex}
          handleOnClick={handleOnClick}
          editingCrossPlatformStrat={editingCrossPlatformStrat}
        />
      </Accordion.Title>
      <Accordion.Content
        active={activeIndex === index}
        style={{ paddingTop: '0px' }}
      >
        <ChildLevelFlightDetails parentFlightExtId={flight.externalId} timezone={flight.timezone} />
      </Accordion.Content>
    </Accordion>
  );
};

const ActiveFlightsTable = (props: ActiveFlightsTablesProps) => {
  const {
    activeFlights,
    removeFlight,
    attachFlightsInfo,
    isHigherOrderOptLvl,
    editingCrossPlatformStrat,
  } = props;

  const { attachedToThisStrategy } = attachFlightsInfo;
  const getFlightCategory = (flight) => (_.includes(attachedToThisStrategy, flight) ? 'attachedToThisStrategy' : 'eligibleFlights');

  return (
    <>
      {isHigherOrderOptLvl
        ? (
          <>
            {_.map(activeFlights, (flight: Flight, index: number) => (
              <HigherOrderFlightDetails
                key={index}
                index={index}
                flight={flight}
                isHigherOrderOptLvl={isHigherOrderOptLvl}
                flightCategory={getFlightCategory(flight)}
                removeFlight={removeFlight}
                editingCrossPlatformStrat={editingCrossPlatformStrat}
              />
            ))}
          </>
        )
        : (_.map(activeFlights, (flight: Flight, index: number) => (
          <AttachFlightsDetails
            key={`${flightKey(flight)} - ${flight.id}`}
            flight={flight}
            isHigherOrderOptLvl={isHigherOrderOptLvl}
            flightCategory={getFlightCategory(flight)}
            removeFlight={removeFlight}
            index={index}
          />
        )))}
    </>
  );
};

export default ActiveFlightsTable;
