/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import _ from 'lodash';
import moment from 'moment';
import React, { useState, useEffect, SetStateAction, Dispatch, useRef, MutableRefObject } from 'react';
import { useFormContext, useFieldArray, useWatch } from 'react-hook-form';
import { Grid, Header, Icon } from 'buildingBlocks';
import { GOAL_VALUE_TYPE } from 'constantsBase';
import { WizardFormGoalSelection } from 'containers/StrategyWizard/types';
import { getTotalDelivered } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/utils';
import Tooltip from 'containers/Tooltip';
import CrossPlatformBudgetInterval from './CrossPlatformBudgetInterval';
import { BUDGET_INTERVALS, BUDGET_SECTION_STYLES } from '../styles';
import { getGoalTargetProps } from '../GoalSection/GoalConfiguration';
import { CrossPlatformBudgetSettingsValidationType, getRevTypeToolTipContent, validateCrossPlatformBudgetSettings as validate } from '../utils';
import { BudgetSettingsType } from '../types';
import { useGoalSectionContext } from '../contexts/GoalSectionProvider';

const MAX_INTERVAL_LIMIT = 12;
const MIN_INTERVAL_LIMIT = 3;
const { tooltipIcon, budgetIntervalLabel } = BUDGET_SECTION_STYLES;
const {
  addBudgetIntervalButton, budgetIntervalOverlapError, noIntervalsMessage, dateHeader, budgetHeader, deliveryHeader, revenueCalculationHeader, headerRow,
  noIntervalsIcon, addIntervalPlus, addIntervalText, showIntervalsContainer, showIntervalsText, showIntervalsIcon, disabledStyle, budgetIntervalListStyle, gridStyle,
} = BUDGET_INTERVALS;

type CrossPlatformIntervalHeaderProps = {
  strategyId: number
  toolTipContent: string
  hasRevTypePermission: boolean
};

const CrossPlatformIntervalHeader = ({ strategyId, toolTipContent, hasRevTypePermission }: CrossPlatformIntervalHeaderProps) => (
  <Grid.Row style={headerRow}>
    <Grid.Column style={budgetIntervalListStyle} />
    <Grid.Column style={dateHeader}><Header style={budgetIntervalLabel} content="Start Date" /></Grid.Column>
    <Grid.Column style={dateHeader}><Header style={budgetIntervalLabel} content="End Date" /></Grid.Column>
    <Grid.Column style={budgetHeader}><Header style={budgetIntervalLabel} content="Budget" /></Grid.Column>
    {strategyId && (<Grid.Column style={deliveryHeader}><Header style={budgetIntervalLabel} content="Delivered" /></Grid.Column>)}
    {hasRevTypePermission && (
      <Grid.Column style={revenueCalculationHeader}>
        <Header style={budgetIntervalLabel} content="Revenue Calculation" />
        <Tooltip content={toolTipContent} tooltipstyling={tooltipIcon} />
      </Grid.Column>
    )}
  </Grid.Row>
);

type CrossPlatformBudgetSettingsProps = {
  crossPlatformBudgetSettingsValidation: CrossPlatformBudgetSettingsValidationType
  setCrossPlatformBudgetSettingsValidation: Dispatch<SetStateAction<CrossPlatformBudgetSettingsValidationType>>
  initialFormValues: WizardFormGoalSelection
  resetConfirmedGoal: Function
  finishCalculations: MutableRefObject<boolean>
};

const CrossPlatformBudgetSettings = (props: CrossPlatformBudgetSettingsProps) => {
  const {
    crossPlatformBudgetSettingsValidation, setCrossPlatformBudgetSettingsValidation,
    initialFormValues, resetConfirmedGoal,
    finishCalculations,
  } = props;
  const {
    currencyCode: currency, strategyId, isImpsBudgetType, hasAmznFlights, hasRevTypePermission,
    reduxWizardFormValues: {
      budgetAllocationState,
    },
  } = useGoalSectionContext();

  const budgetData = _.get(budgetAllocationState, 'data');

  const { control } = useFormContext();
  const formBudgetSettings = useWatch({ name: 'budgetSettings' });
  const revTypeConfig = useWatch({ name: 'budget' });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'budgetSettings',
    shouldUnregister: false,
  });
  const [budgetSettings, setBudgetSettings] = useState<BudgetSettingsType>(_.get(initialFormValues, 'budgetSettings', {}));
  const [showAllBudgetIntervals, setShowAllBudgetIntervals] = useState<boolean>(false);
  const budgetSettingsLength = fields.length;

  const editedBudgetIndex = useRef(new Set());

  const goalTargetProps = getGoalTargetProps(GOAL_VALUE_TYPE.CURRENCY, currency);

  const delivery = (strategyId && !_.isEmpty(budgetData)) && getTotalDelivered(budgetData, _.values(budgetSettings), revTypeConfig, null, finishCalculations);

  useEffect(() => {
    if (formBudgetSettings) {
      setBudgetSettings(_.assign({}, formBudgetSettings));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formBudgetSettings]);

  useEffect(() => {
    setCrossPlatformBudgetSettingsValidation(validate(formBudgetSettings));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formBudgetSettings]);

  const selectedDate = (index: number) => {
    if (moment(budgetSettings[index]?.startDate).isAfter(moment(budgetSettings[index]?.endDate))) {
      return budgetSettings[index]?.startDate;
    }
    return budgetSettings[index]?.endDate;
  };

  // change the budget intervals displayed based on showAllBudgetIntervals
  const checkEndDateActiveIntervals = _.filter(budgetSettings, (interval) => moment(interval.endDate).isSameOrAfter(moment(), 'day'));
  const checkInActiveIntervals = _.filter(budgetSettings, (interval) => moment(interval.endDate).isBefore(moment(), 'day'));
  const budgetIntervalLimit = checkEndDateActiveIntervals.length <= MIN_INTERVAL_LIMIT ? MIN_INTERVAL_LIMIT : checkEndDateActiveIntervals.length;
  let intervals = fields;
  if (fields.length > budgetIntervalLimit && !showAllBudgetIntervals) {
    const startIndex = fields.length - budgetIntervalLimit;
    intervals = fields
      .reduce((obj, val, key) => {
        if (+key >= startIndex) {
          // eslint-disable-next-line no-param-reassign
          obj[key] = val;
        }
        return obj;
      }, []);
  }
  const displayIntervalsBtn = (intervals.length > MIN_INTERVAL_LIMIT) && (checkInActiveIntervals.length >= 1);
  const toolTipContent = getRevTypeToolTipContent(isImpsBudgetType, hasAmznFlights);

  return (
    <>
      {!!_.size(budgetSettings) && (
      <Grid style={gridStyle}>
        <CrossPlatformIntervalHeader
          toolTipContent={toolTipContent}
          strategyId={strategyId}
          hasRevTypePermission={hasRevTypePermission}
        />
        {_.size(budgetSettings)
          ? intervals.map((item, index) => (
            <CrossPlatformBudgetInterval
              key={item.id}
              index={index}
              currency={currency}
              budgetSettings={budgetSettings}
              delivery={delivery}
              editedBudgetIndex={editedBudgetIndex}
              selectedDate={selectedDate}
              goalTargetProps={goalTargetProps}
              remove={remove}
              initialFormValues={initialFormValues}
              resetConfirmedGoal={resetConfirmedGoal}
              finishCalculations={finishCalculations}
            />
          ))
          : null}
      </Grid>
      )}
      <div style={budgetIntervalOverlapError}>
        {intervals.length > 1 && crossPlatformBudgetSettingsValidation.rangeOverlap ? (<span>* Budget Intervals cannot overlap.</span>) : null}
      </div>
      {!intervals.length && (
        <div style={noIntervalsMessage}>
          <span><img alt="info circle" src="/img/icons/16x16/infocircle2.png" style={noIntervalsIcon} /></span>
          There are no active or upcoming intervals for the attached objects.
        </div>
      )}
      <button
        type="button"
        disabled={budgetSettingsLength === MAX_INTERVAL_LIMIT}
        style={intervals.length === MAX_INTERVAL_LIMIT ? { ...addBudgetIntervalButton, ...disabledStyle } : addBudgetIntervalButton}
        onClick={() => {
          let newDate = budgetSettingsLength
            ? moment(formBudgetSettings[budgetSettingsLength - 1].endDate).add(1, 'd').toDate()
            : new Date();
          newDate = newDate < new Date() ? new Date() : newDate;
          append({ startDate: newDate, endDate: newDate, type: 'amount', newlyAdded: true, budget: null });
        }}
      >
        <span style={intervals.length === MAX_INTERVAL_LIMIT ? { ...addIntervalPlus, ...disabledStyle } : addIntervalPlus}>+</span>
        <span style={intervals.length === MAX_INTERVAL_LIMIT ? { ...addIntervalText, ...disabledStyle } : addIntervalText}>Add Budget Intervals</span>
      </button>
      {displayIntervalsBtn && (
        <div
          style={showIntervalsContainer}
          onClick={() => setShowAllBudgetIntervals(!showAllBudgetIntervals)}
          role="button"
          tabIndex={-1}
        >
          <span style={showIntervalsText}>
            {showAllBudgetIntervals ? 'Hide budget intervals' : 'Show all budget intervals'}
          </span>
          <Icon
            style={showIntervalsIcon}
            name={`chevron ${showAllBudgetIntervals ? 'up' : 'down'}`}
          />
        </div>
      )}
    </>
  );
};

export default CrossPlatformBudgetSettings;
