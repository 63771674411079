/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash';
import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { GlobalState } from 'reducers';
import { Grid, Modal, Image, Loader, Dimmer } from 'buildingBlocks';
import { MODAL_STYLES } from 'containers/StrategyWizard/steps/AttachFlights/styles';
import { AWG_WEIGHTS_MODAL_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import { WizardFormValues } from 'containers/StrategyWizard/types';
import { Status } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/constants';
import ErrorMessage from 'components/ErrorComponent/ErrorMessage';
import { MODAL_CLOSE_IMG } from 'containers/StrategyWizard/steps/GoalSelection/constants';
import WeightsModalContentProvider from '../contexts/WeightsModalContentProvider';
import { useWeightsSectionContext } from '../contexts/WeightsSectionProvider';
import WeightsModalContent from './WeightsModalContent';

const ERROR_MSG = 'Error loading budget optimization wizard. Please try again later.';

type WeightsModalProps = {
  metrics: Array<string>
  trigger: ReactNode
};

const WeightsModal = ({ metrics, trigger }: WeightsModalProps) => {
  const { awgWeightsModalOpen, sessionMetrics, handleModalClose } = useWeightsSectionContext();

  const { budgetAllocationState } = useSelector<GlobalState>((state) => state.strategyWizard) as WizardFormValues;

  const renderModalContent = () => {
    const budgetAllocationStateStatus = _.get(budgetAllocationState, 'kind');
    switch (budgetAllocationStateStatus) {
      case (Status.error):
        return (
          <Grid style={AWG_WEIGHTS_MODAL_STYLES.errorContainer}>
            <ErrorMessage
              errorText={ERROR_MSG}
              style={AWG_WEIGHTS_MODAL_STYLES.errorMessage}
            />
          </Grid>
        );
      case (Status.hasData):
        return (
          <WeightsModalContentProvider sessionMetrics={sessionMetrics}>
            <WeightsModalContent metrics={metrics} />
          </WeightsModalContentProvider>
        );
      case (Status.initial):
      case (Status.loading):
      default:
        return (
          <Grid style={{ height: '100%' }}>
            <Dimmer active inverted>
              <Loader active inverted size="massive" />
            </Dimmer>
          </Grid>
        );
    }
  };

  return (
    <Modal
      closeOnEscape={false}
      closeOnDimmerClick={false}
      open={awgWeightsModalOpen}
      onClose={handleModalClose}
      size="large"
      dimmer="blurring"
      style={MODAL_STYLES.modal}
      className="awgWeightsModal"
      trigger={trigger}
    >
      <Image src={MODAL_CLOSE_IMG} style={MODAL_STYLES.closeIcon} onClick={handleModalClose} />
      <Modal.Header style={MODAL_STYLES.header}>Weighting</Modal.Header>
      <Modal.Content style={AWG_WEIGHTS_MODAL_STYLES.content}>
        {renderModalContent()}
      </Modal.Content>
    </Modal>
  );
};

export default WeightsModal;
