/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Map as ImmutableMap } from 'immutable';
import { Grid, Input } from 'buildingBlocks';
import { MODAL_ERROR_MSG } from 'containers/StrategyWizard/steps/GoalSelection/constants';
import { AWG_WEIGHTS_MODAL_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import { WizardFormValues, WeightObj, WeightRowObj, MetricConfig, MetricConfigObj } from 'containers/StrategyWizard/types';
import { getPlatformOptions, isWeighted } from 'containers/StrategyWizard/steps/GoalSelection/utils';
import { GlobalState } from 'reducers';
import { useWeightsModalContentContext } from '../contexts/WeightsModalContentProvider';
import { useWeightsSectionContext } from '../contexts/WeightsSectionProvider';

const { weightRow, input, errorMsg, weightsList, inputFieldError } = AWG_WEIGHTS_MODAL_STYLES;

type PlatformWeightRowProps = {
  platform: WeightRowObj
  platformWeightsList: WeightObj
  setPlatformWeightsList: Dispatch<SetStateAction<WeightObj>>
};

const PlatformWeightRow = ({
  platform, platformWeightsList, setPlatformWeightsList,
}: PlatformWeightRowProps) => {
  const { sessionMetrics, selectedMetric, setSessionMetrics } = useWeightsSectionContext();
  const { attemptSave, formErrors, setFormErrors } = useWeightsModalContentContext();
  const showError = attemptSave && formErrors[platform.id];

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    if (value === '') {
      setFormErrors({
        ...formErrors,
        [platform.id]: MODAL_ERROR_MSG,
      });
    } else {
      // eslint-disable-next-line no-param-reassign
      delete formErrors[platform.id];
      const sessionMetricsWeighting = sessionMetrics.getIn([selectedMetric, 'weighting'], {}) as ImmutableMap<string, WeightObj>;
      const weightingObj = sessionMetricsWeighting.toJS();
      if (value === '1' && _.size(weightingObj) && _.has(weightingObj, platform.id)) {
        setSessionMetrics(sessionMetrics.deleteIn([selectedMetric, 'weighting', _.toString(platform.id)]));
      } if (value !== '1') {
        // Allow for only 4 decimal places
        value = value.split('.').map((el, i) => (i ? el.split('').slice(0, 4).join('') : el)).join('.');
        setSessionMetrics(sessionMetrics.mergeDeep({
          [selectedMetric]: {
            weighting: { [platform.id]: _.toNumber(value) },
          },
        } as unknown as MetricConfig));
      }
    }
    setPlatformWeightsList({
      ...platformWeightsList,
      [platform.id]: {
        ...platformWeightsList[platform.id],
        weight: value,
      },
    });
  };

  return (
    <>
      <Grid.Row columns={2} className="flexStyles" style={weightRow}>
        <Grid.Column width={9}>
          <span>{platform.displayName}</span>
        </Grid.Column>
        <Grid.Column width={3} style={input}>
          <Input
            id={showError ? 'metricInputError' : ''}
            type="number"
            onChange={handleOnChange}
            value={_.toString(platform?.weight)}
            style={{ width: '85%', ...(showError && inputFieldError) }}
          />
        </Grid.Column>
      </Grid.Row>
      {showError && (
        <span style={errorMsg}>{formErrors[platform.id]}</span>
      )}
    </>
  );
};

const PlatformWeightsList = () => {
  const { attachFlightsStep: { attachedFlights } } = useSelector<GlobalState>((state) => state.strategyWizard) as WizardFormValues;
  const { sessionMetrics, selectedMetric, setSessionMetrics } = useWeightsSectionContext();
  const [platformWeightsList, setPlatformWeightsList] = useState<WeightObj>({});

  useEffect(() => {
    if (_.size(attachedFlights)) {
      // pass sessionMetrics[selectedMetric].weighting as a JS Object to getPlatformOptions
      const sessionMetricsWeighting = sessionMetrics.getIn([selectedMetric, 'weighting'], {}) as ImmutableMap<string, WeightObj>;
      const flightsToDisplay = getPlatformOptions(attachedFlights, sessionMetricsWeighting.toJS() as Pick<MetricConfigObj, 'weighting'>);
      setPlatformWeightsList(flightsToDisplay);
    }
  }, [sessionMetrics.getIn([selectedMetric, 'weighting'], {})]);

  useEffect(() => {
    if (_.size(platformWeightsList)) {
      setSessionMetrics(sessionMetrics.mergeDeep({
        [selectedMetric]: {
          isWeighted: isWeighted(platformWeightsList),
        },
      } as MetricConfig));
    }
  }, [platformWeightsList]);

  return (
    <Grid className="weightsList" style={weightsList}>
      {_.map(platformWeightsList, (platform: WeightRowObj, dspId: string) => (
        <PlatformWeightRow
          key={dspId}
          platform={platform}
          platformWeightsList={platformWeightsList}
          setPlatformWeightsList={setPlatformWeightsList}
        />
      ))}
    </Grid>
  );
};

export default PlatformWeightsList;
