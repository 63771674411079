import _ from 'lodash';
import React, { CSSProperties, useState } from 'react';
import { useSelector } from 'react-redux';
import { GlobalState } from 'reducers';
import { Icon, Loader, Popup, Table } from 'buildingBlocks';
import DspIcon from 'components/DspIcon';
import { FLIGHT_EXTERNAL_TYPE } from 'constantsBase';
import { flightKey, getDspFromExtTypeId } from 'containers/StrategyWizard/utils';
import { Status } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/constants';
import { BudgetAllocationResponse } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/types';
import { FlightCategory } from 'containers/StrategyWizard/steps/AttachFlights/constants';
import { FLIGHT_DETAIL_STYLES } from 'containers/StrategyWizard/steps/AttachFlights/styles';
import { Flight } from 'utils/types';
import { displayDate } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/utils';
import ActivationFlightModal from './ActivationFlightModal';

const {
  baseTable,
  additionalExpiredStyles,
  additionalToBeDetachedStyles,
  reactivatedFlightStyles,
  loaderOrAccordion,
  firstHeaderCell,
  flightName,
  dspIcon,
  objectType,
  flightDates,
  endIcon,
  iconStyle,
  popUp,
} = FLIGHT_DETAIL_STYLES;

type AttachFlightsDetailsProps = {
  flight: Flight
  isHigherOrderOptLvl: boolean
  removeFlight: Function
  flightCategory?: string
  index?: number
  activeIndex?: number
  handleOnClick?: (index: number) => void
  flightLimitReached?: boolean
  eligibile?: boolean
  editingCrossPlatformStrat?: boolean
};

const AttachFlightsDetails = (props: AttachFlightsDetailsProps) => {
  const {
    flight,
    isHigherOrderOptLvl,
    flightCategory,
    removeFlight,
    activeIndex,
    handleOnClick,
    eligibile,
    index,
    flightLimitReached,
    editingCrossPlatformStrat,
  } = props;

  const { name, startDate, endDate, timezone, externalType } = flight;
  const [activationModalOpen, setActivationModalOpen] = useState<boolean>(false);

  const key = flightKey(flight);
  const isAttachedToThisStrategy = _.isEqual(flightCategory, FlightCategory.attachedToThisStrategy);
  const flightToBeDetached = _.isEqual(flightCategory, FlightCategory.toBeDetached);
  const crossPlatformFlightToBeDeactivated = _.isEqual(flightCategory, FlightCategory.toBeDeactivated);
  const ineligToBeDeactivatedCrossPlatFlight = crossPlatformFlightToBeDeactivated && !eligibile;
  const reactivatedFlight = _.isEqual(flightCategory, FlightCategory.reactivatedFlights);
  const deactivedCrossPlatformFlight = _.isEqual(flightCategory, FlightCategory.deactivatedFlights);
  const ineligibleFlight = flightCategory === FlightCategory.ineligibleFlights;
  const eligCPFlightWithIncompatibleBudgetType = _.isEqual(flightCategory, FlightCategory.eligCPFlightsWithoutAmountBudgetType);
  const eligCPFlightWithSpend = _.isEqual(flightCategory, FlightCategory.eligCPFlightsWithSpend);
  const isRemovableFlight = _.isEqual(flightCategory, FlightCategory.eligibleFlights)
    || eligCPFlightWithIncompatibleBudgetType
    || eligCPFlightWithSpend
    || (editingCrossPlatformStrat && reactivatedFlight)
    || (!editingCrossPlatformStrat && (ineligibleFlight || isAttachedToThisStrategy));
  const isReattachableFlight = flightToBeDetached || crossPlatformFlightToBeDeactivated;

  const showAccordion = isHigherOrderOptLvl && (flightCategory === FlightCategory.eligibleFlights || isAttachedToThisStrategy);
  const accordionOpen = activeIndex === index;
  const tableStyles = { ...baseTable, ...(accordionOpen && { borderBottom: 'none' }) };
  const styleForIneligibleDetachedOrDeactivated = ((eligibile && !deactivedCrossPlatformFlight) || isReattachableFlight)
    ? { ...tableStyles, ...additionalToBeDetachedStyles }
    : { ...tableStyles, ...additionalExpiredStyles };
  const deactivatedFlightConditionalStyling = deactivedCrossPlatformFlight && { opacity: '60%' };
  const ineligibleDetachedOrDeactivated = ineligibleFlight || deactivedCrossPlatformFlight || isReattachableFlight || eligCPFlightWithIncompatibleBudgetType || eligCPFlightWithSpend;
  const style = ineligibleDetachedOrDeactivated ? styleForIneligibleDetachedOrDeactivated : { ...tableStyles, ...(reactivatedFlight && reactivatedFlightStyles) };
  const getCellStyles = (cellStyle: CSSProperties): CSSProperties => ({ ...cellStyle, ...deactivatedFlightConditionalStyling });
  const flightDatesToDisplay = `${displayDate(startDate, externalType, timezone)} - ${displayDate(endDate, externalType, timezone)}`;

  const externalTypeDisplayName = _.get(FLIGHT_EXTERNAL_TYPE.getById(externalType), 'wizardDisplayName') ?? FLIGHT_EXTERNAL_TYPE.getById(externalType).displayName;
  const budgetAllocationState = useSelector<GlobalState>((state) => state.strategyWizard.budgetAllocationState) as BudgetAllocationResponse;
  const { kind } = budgetAllocationState;

  const handleRemoveFlight = () => {
    switch (flightCategory) {
      case (FlightCategory.ineligibleFlights):
      case (FlightCategory.attachedToThisStrategy):
        removeFlight(key, flightCategory, FlightCategory.toBeDetached);
        break;
      case (FlightCategory.reactivatedFlights):
        removeFlight(key, flightCategory, FlightCategory.deactivatedFlights);
        break;
      default:
        removeFlight(key, flightCategory);
    }
  };

  return (
    <Table style={(index && index > 0) ? { ...style, borderTop: 'none' } : style}>
      <Table.Body>
        <Table.Row>
          <Table.Cell style={(ineligibleDetachedOrDeactivated || !isHigherOrderOptLvl || !_.isEqual(kind, Status.hasData)) ? firstHeaderCell : loaderOrAccordion}>
            {(showAccordion && kind === Status.loading)
              && (
                <Loader active size="mini" inline />
              )}
            {(showAccordion && (kind === Status.error || kind === Status.hasData))
              && (
                <Icon
                  name={accordionOpen ? 'angle down' : 'angle right'}
                  onClick={() => handleOnClick(index)}
                />
              )}
          </Table.Cell>
          <Table.Cell style={getCellStyles(flightName)} collapsing><b title={name}>{name}</b></Table.Cell>
          <Table.Cell style={getCellStyles(dspIcon)}><DspIcon dspId={getDspFromExtTypeId(externalType)} /></Table.Cell>
          <Table.Cell style={getCellStyles(objectType)}>{externalTypeDisplayName}</Table.Cell>
          <Table.Cell style={getCellStyles(flightDates)}>{flightDatesToDisplay}</Table.Cell>
          <Table.Cell style={endIcon}>
            {isRemovableFlight && (
              <Icon
                name="times"
                onClick={handleRemoveFlight}
                style={iconStyle}
              />
            )}
            {(editingCrossPlatformStrat && (isAttachedToThisStrategy || ineligibleFlight)) && (
              <ActivationFlightModal
                activationModalOpen={activationModalOpen}
                setActivationModalOpen={setActivationModalOpen}
                flight={flight}
                removeFlight={() => {
                  removeFlight(key, flightCategory, FlightCategory.toBeDeactivated);
                }}
                isDeactivation
              />
            )}
            {isReattachableFlight && (((eligibile && !flightLimitReached) || ineligToBeDeactivatedCrossPlatFlight)
              ? (
                <Icon
                  name="plus"
                  onClick={() => {
                    removeFlight(
                      key,
                      flightCategory,
                      (ineligToBeDeactivatedCrossPlatFlight ? FlightCategory.ineligibleFlights : FlightCategory.attachedToThisStrategy),
                    );
                  }}
                  style={iconStyle}
                />
              ) : (
                <Popup
                  style={popUp}
                  position="top right"
                  content={flightLimitReached ? 'Object attachment limit reached.' : 'This flight is ineligible.'}
                  trigger={(
                    <Icon
                      name="plus"
                      disabled
                    />
                  )}
                />
              )
            )}
            {(editingCrossPlatformStrat && deactivedCrossPlatformFlight) && (
              <ActivationFlightModal
                activationModalOpen={activationModalOpen}
                setActivationModalOpen={setActivationModalOpen}
                flight={flight}
                removeFlight={() => {
                  removeFlight(key, flightCategory, FlightCategory.reactivatedFlights);
                }}
              />
            )}
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
};

export default AttachFlightsDetails;
