import React, { Dispatch, SetStateAction } from 'react';
import _ from 'lodash';
import { GoalSuccessEvent } from 'constantsBase';
import { Button } from 'buildingBlocks';
import { GOAL_SECTION_STYLES } from '../styles';
import { useGoalSectionContext } from '../contexts/GoalSectionProvider';

type Props = {
  successEventFilter: GoalSuccessEvent | 'all',
  currentGoalSuccessEventFilter: GoalSuccessEvent | 'all',
  setSearchValue: Dispatch<SetStateAction<string>>
};

const GoalSuccessEventFilterButton = ({
  successEventFilter,
  currentGoalSuccessEventFilter,
  setSearchValue,
}: Props) => {
  const { setGoalSuccessEventFilter } = useGoalSectionContext();
  return (
    <Button
      style={(successEventFilter === currentGoalSuccessEventFilter) ? { ...GOAL_SECTION_STYLES.successEventButton, ...GOAL_SECTION_STYLES.activeGoalSuccessEventButton } : GOAL_SECTION_STYLES.successEventButton}
      onClick={() => {
        setGoalSuccessEventFilter(successEventFilter);
        setSearchValue('');
      }}
    >
      {_.upperFirst(successEventFilter)}
    </Button>
  ); };

export default GoalSuccessEventFilterButton;
