import _ from 'lodash';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { Icon } from 'buildingBlocks';
import dspIconStyles from 'components/DspIcon/style';
import { REVENUE_TYPES_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import { COPILOT_LAYOUT } from 'globalStyles';
import { DSP } from 'constantsBase';
import { RevTypeOption } from 'containers/StrategyWizard/steps/GoalSelection/constants';
import { RevenueTypeConfig } from 'containers/StrategyWizard/types';
import OutcomeOption from './OutcomeOption';

const { SPACING } = COPILOT_LAYOUT;

type DspOutcomesOptionsProps = {
  dsp: number
  outcomes: RevenueTypeConfig
  headerStyles: Object
  selectedObjects: Array<string>
  setSelectedObjs: Dispatch<SetStateAction<Array<string>>>
  updateSingleOutcome: (extId: string, value: string | number) => void
  revenueTypeOptions: Array<RevTypeOption>
};

const DspOutcomesOptions = (props: DspOutcomesOptionsProps) => {
  const { dsp, outcomes, headerStyles, selectedObjects, setSelectedObjs,
    updateSingleOutcome, revenueTypeOptions } = props;
  const [showFlightsList, setShowFlightsList] = useState<boolean>(true);
  const { code: dspName } = DSP.getById(dsp);
  const dspHeaderStyle = { ...dspIconStyles[dspName], ...(!showFlightsList && { marginBottom: SPACING[4] }) };

  return (
    <>
      <div
        key={dsp}
        style={_.merge({}, REVENUE_TYPES_STYLES.stickyHeader, dspHeaderStyle, headerStyles)}
        className="stickyHeader"
        onClick={() => setShowFlightsList(!showFlightsList)}
        role="presentation"
      >
        <Icon
          name={showFlightsList ? 'angle down' : 'angle right'}
          size="large"
        />
        {dspName}
      </div>
      <div style={{ ...(!showFlightsList && { display: 'none' }) }}>
        <OutcomeOption
          outcomesList={outcomes}
          selectedObjects={selectedObjects}
          setSelectedObjs={setSelectedObjs}
          updateSingleOutcome={updateSingleOutcome}
          revenueTypeOptions={revenueTypeOptions}
        />
      </div>
    </>
  );
};

export default DspOutcomesOptions;
