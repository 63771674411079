/* eslint-disable no-param-reassign */
import _ from 'lodash';
import React, { Dispatch, SetStateAction } from 'react';
import { Map as ImmutableMap } from 'immutable';
import { Grid, Input } from 'buildingBlocks';
import { MODAL_ERROR_MSG } from 'containers/StrategyWizard/steps/GoalSelection/constants';
import { AWG_WEIGHTS_MODAL_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import { MetricConfig, MetricConfigObj, WeightConfig, WeightObj, WeightRowObj } from 'containers/StrategyWizard/types';
import { useWeightsModalContentContext } from '../contexts/WeightsModalContentProvider';
import { useWeightsSectionContext } from '../contexts/WeightsSectionProvider';

const { weightRowStyle, weightId, weightText, weightInput, input, errorMsg, inputFieldError } = AWG_WEIGHTS_MODAL_STYLES;

type WeightRowProps = {
  listItem: WeightRowObj
  flightsToDisplay: ImmutableMap<string, WeightObj>
  setFlightsToDisplay: Dispatch<SetStateAction<ImmutableMap<string, WeightObj>>>
  dspId: string
};

const WeightRow = ({
  listItem, flightsToDisplay, setFlightsToDisplay, dspId,
}: WeightRowProps) => {
  const { selectedMetric, sessionMetrics, setSessionMetrics } = useWeightsSectionContext();
  const { attemptSave, formErrors, setFormErrors } = useWeightsModalContentContext();
  const showError = attemptSave && formErrors[listItem.id];

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    if (value === '') {
      setFormErrors({
        ...formErrors,
        [listItem.id]: MODAL_ERROR_MSG,
      });
    } else {
      delete formErrors[listItem.id];
      let updatedSessionMetric;
      // convert sessionMetrics to JS Obj then check weighting[dspId]
      // getIn([selectedMetric, 'weighting', dspId]) returns undefined at times even though it exists
      const selectedMetricConfig = sessionMetrics.getIn([selectedMetric], {}) as ImmutableMap<string, MetricConfigObj>;
      const dspWeightConfig = _.get(selectedMetricConfig.toJS(), `weighting[${dspId}]`, {});
      // set weighting to {} if we change the only weighted option back to 1
      if (value === '1' && (_.size(dspWeightConfig) === 1 && _.has(dspWeightConfig, listItem.id))) {
        updatedSessionMetric = sessionMetrics.deleteIn([selectedMetric, 'weighting', dspId]);
      }
      // only remove listItem.id key if value is '1' and dspWeightConfig has listItem.id
      if (value === '1' && _.size(dspWeightConfig) > 1 && _.has(dspWeightConfig, listItem.id)) {
        updatedSessionMetric = sessionMetrics.deleteIn([selectedMetric, 'weighting', dspId, _.toString(listItem.id)]);
      }
      if (value !== '1') {
        // Allow for only 4 decimal places
        value = value.split('.').map((el, i) => (i ? el.split('').slice(0, 4).join('') : el)).join('.');
        updatedSessionMetric = sessionMetrics.mergeDeep({
          [selectedMetric]: {
            weighting: { [dspId]: { [listItem.id]: _.toNumber(value) as unknown } },
          },
        } as MetricConfig);
      }
      if (updatedSessionMetric) {
        setSessionMetrics(updatedSessionMetric);
      }
    }
    setFlightsToDisplay(flightsToDisplay.mergeDeep({
      [dspId]: {
        [listItem.id]: { weight: value },
      },
    } as WeightConfig));
  };

  return (
    <Grid style={{ margin: 0 }}>
      <Grid.Row columns={3} style={weightRowStyle}>
        <Grid.Column width={2} style={{ ...weightId, ...weightText }}>
          <span>{listItem.id}</span>
        </Grid.Column>
        <Grid.Column width={8} style={weightText}>
          <span title={listItem.displayName}>{listItem.displayName}</span>
        </Grid.Column>
        <Grid.Column width={2} style={{ ...weightInput, ...input, ...weightText }}>
          <Input
            id={showError ? 'metricInputError' : ''}
            type="number"
            style={{ width: '100%', ...(showError && inputFieldError) }}
            value={listItem.weight}
            onChange={handleOnChange}
          />
        </Grid.Column>
      </Grid.Row>
      {showError && (
        <span style={{ ...errorMsg, width: '100%', padding: 0 }}>{formErrors[listItem.id]}</span>
      )}
    </Grid>
  );
};

type WeightsListProps = {
  weightsList: WeightObj
  flightsToDisplay: ImmutableMap<string, WeightObj>
  setFlightsToDisplay: Dispatch<SetStateAction<ImmutableMap<string, WeightObj>>>
  dspId: string
};

const WeightsList = ({ weightsList, flightsToDisplay, setFlightsToDisplay, dspId }: WeightsListProps) => (
  <div style={{ margin: '4px 0' }}>
    {_.map(weightsList, (listItem: WeightRowObj) => (
      <WeightRow
        dspId={dspId}
        key={listItem.id}
        listItem={listItem}
        flightsToDisplay={flightsToDisplay}
        setFlightsToDisplay={setFlightsToDisplay}
      />
    ))}
  </div>
);

export default WeightsList;
