import _ from 'lodash';
import React, { MutableRefObject, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Button, Grid, Icon, Image, Modal } from 'buildingBlocks';
import { MODAL_STYLES } from 'containers/StrategyWizard/steps/AttachFlights/styles';
import { WizardFormGoalSelection } from 'containers/StrategyWizard/types';
import { INFO_ICON_SRC } from 'containers/StrategyWizard/steps/GoalSelection/constants';
import { CYOD_MODAL_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import CYODGoalConfiguration from './CYODGoalConfiguration';

type CYODModalProps = {
  CYODModalOpen: boolean
  setCYODModalOpen: (x: any) => void
  setConfirmedGoal: (x: any) => void
  cyodRef: MutableRefObject<boolean>
};

const CYODModal = (props: CYODModalProps) => {
  const { CYODModalOpen, setCYODModalOpen, setConfirmedGoal, cyodRef } = props;
  const { getValues, setValue, clearErrors } = useFormContext<WizardFormGoalSelection>();
  const [modalGoalType, setModalGoalType] = useState<string>(getValues('goal.type'));
  const [modalGoalTarget, setModalGoalTarget] = useState<number | null>(getValues('goal.target') ?? null);
  const [goalTargetError, setGoalTargetError] = useState<string>('');

  const handleModalClose = (e) => {
    e.stopPropagation();
    setCYODModalOpen(false);
  };

  const handleSubmit = (e) => {
    e.stopPropagation();
    // submit button will only be enabled if there are no errors
    // clear any previous target errors on the form
    clearErrors('goal.target');
    setCYODModalOpen(false);
    cyodRef.current = true;
    setValue('goal.type', modalGoalType);
    setValue('goal.target', modalGoalTarget);
    setConfirmedGoal(modalGoalType);
  };

  return (
    <Modal
      closeOnEscape={false}
      closeOnDimmerClick={false}
      open={CYODModalOpen}
      onOpen={() => setCYODModalOpen(true)}
      onClose={handleModalClose}
      size="large"
      dimmer="blurring"
      style={MODAL_STYLES.modal}
    >
      <Icon
        name="times"
        style={MODAL_STYLES.closeIcon}
        onClick={handleModalClose}
      />
      <Modal.Header style={MODAL_STYLES.header}>Upload External Measurement Configuration</Modal.Header>
      <Modal.Content style={MODAL_STYLES.content}>
        <div>
          <p style={CYOD_MODAL_STYLES.description}>
            This goal type enables you to optimize to data not available in the DSP and
            <span style={CYOD_MODAL_STYLES.boldedText}> puts you in control of uploading new performance data </span>
            as often as you want. We refer to the performance data you upload as <span style={CYOD_MODAL_STYLES.boldedText}>Success Events</span>.
            Copilot optimizes your campaign based on the data you upload. Make sure to comply with company policies and best
            practices regarding data privacy and ethics when uploading. If you have questions, consult  your manager.
          </p>
        </div>
        <Grid style={CYOD_MODAL_STYLES.noMargin}>
          <CYODGoalConfiguration
            modalGoalType={modalGoalType}
            setModalGoalType={setModalGoalType}
            modalGoalTarget={modalGoalTarget}
            setModalGoalTarget={setModalGoalTarget}
            goalTargetError={goalTargetError}
            setGoalTargetError={setGoalTargetError}
          />
          <Grid.Row columns={1} style={CYOD_MODAL_STYLES.noPadding}>
            <Grid.Column style={CYOD_MODAL_STYLES.noPadding}>
              <div style={CYOD_MODAL_STYLES.messageContainer}>
                <div>
                  <Image alt="info circle" src={INFO_ICON_SRC} style={CYOD_MODAL_STYLES.infoIcon} />
                </div>
                <div>
                  <b>Data Upload and Pacing</b>
                  <p>
                    Once you have created this Strategy, you will be able to upload your data in the Strategy Analytics page.
                    <br />
                    Until data is uploaded, Copilot will not perform goal optimizations, but it will still pace the Strategy.
                  </p>
                </div>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row style={CYOD_MODAL_STYLES.btnRow}>
            <Grid.Column
              style={CYOD_MODAL_STYLES.noPadding}
              floated="right"
            >
              <Button
                type="submit"
                onClick={handleSubmit}
                disabled={_.isNil(modalGoalTarget) || !!goalTargetError}
                style={CYOD_MODAL_STYLES.submitBtn}
                primary
              >
                Submit
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
    </Modal>
  );
};

export default CYODModal;
