import _ from 'lodash';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Table } from 'buildingBlocks';
import { isHigherOrderExternalType } from 'containers/StrategyWizard/utils';
import { changeFlightStatusUtilHelper } from 'containers/StrategyWizard/steps/AttachFlights/components/AttachFlightsModal/utils';
import { WizardFormAttachFlights, AttachFlightsInfoType } from 'containers/StrategyWizard/types';
import { COPILOT_TYPOGRAPHY, COPILOT_COLORS } from 'globalStyles';
import { ALL_FLIGHT_LIST_NAMES, FlightListName, FlightLists } from './AttachFlightsModal/AttachFlightsModalTable';
import ActiveFlightsTable from './AttachFlightsTable/ActiveFlightsTable';
import ExpiredOrDetachedFlightsAccordion from './AttachFlightsTable/ExpiredOrDetachedFlightsAccordion';
import { FlightCategory } from '../constants';
import { FLIGHT_DETAIL_STYLES } from '../styles';

const { NEW_DESIGN_SYSTEM: { NEUTRALS } } = COPILOT_COLORS;
const { firstHeaderCell, flightName, dspHeader, objectType, flightDates, endIcon } = FLIGHT_DETAIL_STYLES;

const AttachFlightsHeader = () => (
  <Table style={{ ...COPILOT_TYPOGRAPHY.DESCRIPTION.BOLD, width: '100%', color: NEUTRALS.N800_MATTER, border: 'none', marginBottom: 0 }}>
    <Table.Body>
      <Table.Row>
        <Table.Cell style={firstHeaderCell} />
        <Table.Cell style={flightName}>Object Name</Table.Cell>
        <Table.Cell style={dspHeader}>DSP</Table.Cell>
        <Table.Cell style={objectType}>Object Type</Table.Cell>
        <Table.Cell style={flightDates}>Flight Dates</Table.Cell>
        <Table.Cell style={endIcon} />
      </Table.Row>
    </Table.Body>
  </Table>
);

type AttachFlightsTableProps = {
  attachFlightsInfo: AttachFlightsInfoType
  setAttachFlightsInfo: (x: any) => void
  flightLimitReached: boolean
  strategyId: number
  configuringCrossPlatformStrat: boolean
};

const AttachFlightsTable = ({
  attachFlightsInfo,
  setAttachFlightsInfo,
  flightLimitReached,
  strategyId,
  configuringCrossPlatformStrat,
}: AttachFlightsTableProps) => {
  const { setValue, getValues } = useFormContext<WizardFormAttachFlights>();
  const { optimizationLevel } = getValues();
  const editingCrossPlatformStrat = !_.isNil(strategyId) && configuringCrossPlatformStrat;

  const {
    eligibleFlights,
    ineligibleFlights,
    attachedToThisStrategy,
    toBeDetached,
    flightsStatus,
  } = attachFlightsInfo;

  // default these flight categories to an empty array when configuring non cross-platform strategies
  const reactivatedFlights = _.get(attachFlightsInfo, 'reactivatedFlights', []);
  const toBeDeactivated = _.get(attachFlightsInfo, 'toBeDeactivated', []);
  const deactivatedFlights = _.get(attachFlightsInfo, 'deactivatedFlights', []);
  const eligCPFlightsWithoutAmountBudgetType = _.get(attachFlightsInfo, 'eligCPFlightsWithoutAmountBudgetType', []);
  const eligCPFlightsWithSpend = _.get(attachFlightsInfo, 'eligCPFlightsWithSpend', []);
  const activeFlights = _.uniqBy(_.concat(attachedToThisStrategy, eligibleFlights), 'id');
  const isHigherOrderOptLvl = isHigherOrderExternalType(_.head(_.get(optimizationLevel, 'externalTypeIds')));

  const removeFlight = (key: string, from: FlightListName, to?: FlightListName) => {
    const getCurrentFlightLists = () => _.pick(attachFlightsInfo, ALL_FLIGHT_LIST_NAMES);
    const currentFlightLists = {
      ...getCurrentFlightLists(),
      ...(configuringCrossPlatformStrat && { reactivatedFlights, toBeDeactivated, deactivatedFlights, eligCPFlightsWithoutAmountBudgetType, eligCPFlightsWithSpend }),
    } as FlightLists;
    changeFlightStatusUtilHelper(
      currentFlightLists,
      setValue,
      flightsStatus,
      key,
      from,
      attachFlightsInfo,
      setAttachFlightsInfo,
      to,
    );
  };

  return (
    <>
      {_.concat(activeFlights, toBeDetached, ineligibleFlights, reactivatedFlights, toBeDeactivated, deactivatedFlights).length > 0 && (
      <AttachFlightsHeader />
      )}
      {activeFlights.length > 0 && (
      <ActiveFlightsTable
        activeFlights={activeFlights}
        removeFlight={removeFlight}
        attachFlightsInfo={attachFlightsInfo}
        isHigherOrderOptLvl={isHigherOrderOptLvl}
        editingCrossPlatformStrat={editingCrossPlatformStrat}
      />
      )}
      {toBeDetached.length > 0 && (
      <ExpiredOrDetachedFlightsAccordion
        flights={toBeDetached}
        flightCategory={FlightCategory.toBeDetached}
        removeFlight={removeFlight}
        flightsStatus={flightsStatus}
        isHigherOrderOptLvl={isHigherOrderOptLvl}
        flightLimitReached={flightLimitReached}
      />
      )}
      {ineligibleFlights.length > 0 && (
      <ExpiredOrDetachedFlightsAccordion
        flights={ineligibleFlights}
        flightCategory={FlightCategory.ineligibleFlights}
        removeFlight={removeFlight}
        flightsStatus={flightsStatus}
        isHigherOrderOptLvl={isHigherOrderOptLvl}
        strategyId={strategyId}
        configuringCrossPlatformStrat={configuringCrossPlatformStrat}
      />
      )}
      {reactivatedFlights.length > 0 && (
      <ExpiredOrDetachedFlightsAccordion
        flights={reactivatedFlights}
        flightCategory={FlightCategory.reactivatedFlights}
        removeFlight={removeFlight}
        flightsStatus={flightsStatus}
        isHigherOrderOptLvl={isHigherOrderOptLvl}
        strategyId={strategyId}
        configuringCrossPlatformStrat={configuringCrossPlatformStrat}
      />
      )}
      {toBeDeactivated.length > 0 && (
      <ExpiredOrDetachedFlightsAccordion
        flights={toBeDeactivated}
        flightCategory={FlightCategory.toBeDeactivated}
        removeFlight={removeFlight}
        flightsStatus={flightsStatus}
        isHigherOrderOptLvl={isHigherOrderOptLvl}
        flightLimitReached={flightLimitReached}
        strategyId={strategyId}
        configuringCrossPlatformStrat={configuringCrossPlatformStrat}
      />
      )}
      {deactivatedFlights.length > 0 && (
      <ExpiredOrDetachedFlightsAccordion
        flights={deactivatedFlights}
        flightCategory={FlightCategory.deactivatedFlights}
        removeFlight={removeFlight}
        flightsStatus={flightsStatus}
        isHigherOrderOptLvl={isHigherOrderOptLvl}
        strategyId={strategyId}
        configuringCrossPlatformStrat={configuringCrossPlatformStrat}
      />
      )}
      {(configuringCrossPlatformStrat && eligCPFlightsWithoutAmountBudgetType.length > 0) && (
      <ExpiredOrDetachedFlightsAccordion
        flights={eligCPFlightsWithoutAmountBudgetType}
        flightCategory={FlightCategory.eligCPFlightsWithoutAmountBudgetType}
        removeFlight={removeFlight}
        flightsStatus={flightsStatus}
        isHigherOrderOptLvl={isHigherOrderOptLvl}
        strategyId={strategyId}
        configuringCrossPlatformStrat={configuringCrossPlatformStrat}
      />
      )}
      {(configuringCrossPlatformStrat && eligCPFlightsWithSpend.length > 0) && (
      <ExpiredOrDetachedFlightsAccordion
        flights={eligCPFlightsWithSpend}
        flightCategory={FlightCategory.eligCPFlightsWithSpend}
        removeFlight={removeFlight}
        flightsStatus={flightsStatus}
        isHigherOrderOptLvl={isHigherOrderOptLvl}
        strategyId={strategyId}
        configuringCrossPlatformStrat={configuringCrossPlatformStrat}
      />
      )}
    </>
  );
};

export default AttachFlightsTable;
