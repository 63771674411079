/* eslint-disable react/prop-types */
import _ from 'lodash';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Form, Grid, Header, Input } from 'buildingBlocks';
import { validatedFieldWrapper } from 'utils/wrapperComponentsReactHookForms';
import { BUDGET_SECTION_STYLES, GOAL_SETTINGS } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import OptimizationOptions from './OptimizationOptions';

const { labelStyle, divStyle, columnStyle, additionalPadding } = GOAL_SETTINGS;

// Memoizing this component will prevent the  re-rendering every time the form updates, thereby preventing the input fields from losing focus
const GoalSettings = React.memo(() => {
  const ValidatedInput = validatedFieldWrapper(Input);
  const { control } = useFormContext();

  return (
    <>
      <Header as="h4" style={BUDGET_SECTION_STYLES.header}>Goal Settings</Header>
      <Form.Group>
        <Grid>
          <Grid.Row style={divStyle} columns={2}>
            <Grid.Column style={columnStyle} width={4}>
              <Form.Field>
                <label style={labelStyle}>Goal Name</label>
                <Controller
                  name="customGoal.name"
                  control={control}
                  rules={{
                    required: 'Goal Name is required.',
                    maxLength: {
                      value: 30,
                      message: 'Must not exceed 30 characters.',
                    },
                  }}
                  render={(props) => (
                    <ValidatedInput
                      type="text"
                      placeholder="Name your goal"
                      value={props.field.value}
                      onChange={props.field.onChange}
                      skipIsDirtyCheck
                      {..._.omit(props, 'formState')}
                      fluid
                    />
                  )}
                />
              </Form.Field>
            </Grid.Column>
            <Grid.Column style={columnStyle} width={4}>
              <Form.Field>
                <label style={labelStyle}>Goal Acronym (Max length 5)</label>
                <Controller
                  name="customGoal.value"
                  control={control}
                  rules={{
                    required: 'Goal Acronym is required.', // Error message for required
                    maxLength: {
                      value: 5,
                      message: 'Must not exceed 5 characters.',
                    },
                    pattern: {
                      value: /^\S*$/,
                      message: 'No spaces allowed',
                    },
                  }}
                  render={(props) => (
                    <ValidatedInput
                      type="text"
                      placeholder="Give your goal a short name or acronym"
                      value={props.field.value}
                      onChange={props.field.onChange}
                      skipIsDirtyCheck
                      {..._.omit(props, 'formState')}
                      fluid
                    />
                  )}
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row style={{ ...divStyle, ...additionalPadding }}>
            <Form.Field style={{ width: '100%' }}>
              <label style={labelStyle}>How would you like Copilot to optimize the campaign?</label>
              <OptimizationOptions />
            </Form.Field>
          </Grid.Row>
        </Grid>
      </Form.Group>
    </>
  );
});

export default GoalSettings;
