import _ from 'lodash';
import {
  WeightsSectionActionTypes, ModalContentAction, ModalContentActionTypes, ModalContentState,
  WeightsSectionAction, WeightsSectionState, AWGState, AWGAction, AWGActionTypes, FormulaState, FormulaActionTypes, FormulaAction,
} from './types';

// Reducer for AWGProvider
export const awgReducer = (state: AWGState, action: AWGAction) => {
  switch (action.type) {
    case AWGActionTypes.UPDATE_FORMULA_METRICS:
      return { ...state, formulaMetrics: action.payload };
    case AWGActionTypes.UPDATE_SELECTED_FORMULA:
      return { ...state, selectedFormula: action.payload };
    default:
      return state;
  }
};

// Reducer for WeightsSectionProvider
export const weightsSectionReducer = (state: WeightsSectionState, action: WeightsSectionAction) => {
  switch (action.type) {
    case WeightsSectionActionTypes.UPDATE_SELECTED_METRIC:
      return { ...state, selectedMetric: action.payload };
    case WeightsSectionActionTypes.UPDATE_SELECTED_DIMENSION:
      return { ...state, selectedDimension: action.payload };
    case WeightsSectionActionTypes.UPDATE_SESSION_METRICS:
      return { ...state, sessionMetrics: action.payload };
    case WeightsSectionActionTypes.UPDATE_AWG_WEIGHTS_MODAL_OPEN:
      return { ...state, awgWeightsModalOpen: action.payload };
    case WeightsSectionActionTypes.UPDATE_WEIGHTED_METRIC_SELECTED:
      return { ...state, weightedMetricSelected: action.payload };
    case WeightsSectionActionTypes.UPDATE_WEIGHTED_DIMENSION_SELECTED:
      return { ...state, weightedDimensionSelected: action.payload };
    case WeightsSectionActionTypes.OPEN_MODAL:
      return { ...state, awgWeightsModalOpen: true, weightedMetricSelected: null };
    case WeightsSectionActionTypes.CLOSE_MODAL:
      return {
        ...state,
        sessionMetrics: _.get(action.payload, 'sessionMetrics'),
        selectedDimension: _.get(action.payload, 'selectedDimension'),
        awgWeightsModalOpen: false,
        weightedMetricSelected: null,
        weightedDimensionSelected: null,
      };
    case WeightsSectionActionTypes.EDIT_WEIGHTED_METRIC:
      return {
        ...state,
        weightedMetricSelected: action.payload.name,
        weightedDimensionSelected: action.payload.dimension,
        awgWeightsModalOpen: true,
      };
    default:
      return state;
  }
};

// Reducer for WeightsModalContentProvider
export const modalContentReducer = (state: ModalContentState, action: ModalContentAction) => {
  switch (action.type) {
    case ModalContentActionTypes.UPDATE_ATTEMPT_SAVE:
      return { ...state, attemptSave: action.payload };
    case ModalContentActionTypes.UPDATE_FORM_ERRORS:
      return { ...state, formErrors: action.payload };
    default:
      return state;
  }
};

// Reducer for FormulaProvider
export const formulaReducer = (state: FormulaState, action: FormulaAction) => {
  switch (action.type) {
    case FormulaActionTypes.ADD_NEW_ITEM:
      return { ...state, dropItems: [...state.dropItems, action.payload] };
    case FormulaActionTypes.UPDATE_DROP_ITEMS:
      return { ...state, dropItems: action.payload };
    case FormulaActionTypes.UPDATE_DROPZONE_ACTIVE:
      return { ...state, dropzoneActive: action.payload };
    case FormulaActionTypes.UPDATE_CURSOR_IDX:
      return { ...state, cursorIdx: action.payload };
    case FormulaActionTypes.UPDATE_UNDO_ACTIONS:
      return { ...state, undoActions: action.payload };
    case FormulaActionTypes.UPDATE_REDO_ACTIONS:
      return { ...state, redoActions: action.payload };
    case FormulaActionTypes.ADD_UNDO_ACTION:
      return { ...state, undoActions: [...state.undoActions, action.payload] };
    case FormulaActionTypes.ADD_REDO_ACTION:
      return { ...state, redoActions: [...state.redoActions, action.payload] };
    case FormulaActionTypes.UPDATE_CHARACTER_COUNT:
      return { ...state, characterCount: action.payload };
    default:
      return state;
  }
};
