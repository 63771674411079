import _ from 'lodash';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { DataRange, FormulaStateType, GoalData } from 'containers/StrategyWizard/steps/GoalSelection/types';
import { GrabFormulaFilters } from 'containers/StrategyWizard/steps/GoalSelection/constants';
import PaginationControl from 'components/PaginationControl';
import { FORMULA_SECTION_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import StrategyGoalCard from './StrategyGoalCard';

const { goalDataDivStyle, paginationDivStyle } = FORMULA_SECTION_STYLES;

type GrabFormulaListProps = {
  onDataRangeChange: (obj: DataRange) => void
  goalCount: number
  filterValue: GrabFormulaFilters
  setOpenModal: Dispatch<SetStateAction<boolean>>
  formulaState: FormulaStateType
  stratGoals: Array<GoalData>
};

const GrabFormulaList = (props: GrabFormulaListProps) => {
  const { onDataRangeChange, goalCount, filterValue, setOpenModal, formulaState, stratGoals } = props;
  const [selectedFormulaFromList, setSelectedFormulaFromList] = useState<string>('');

  return (
    <>
      {goalCount > 0
        && (
          <div style={goalDataDivStyle}>
            {_.map(stratGoals, (goal, index) => (
              <StrategyGoalCard
                goal={goal}
                selectedFilter={filterValue}
                key={index}
                setOpenModal={setOpenModal}
                selectedFormulaFromList={selectedFormulaFromList}
                setSelectedFormulaFromList={setSelectedFormulaFromList}
              />
            ))}
            <div style={paginationDivStyle}>
              <PaginationControl
                numberOfElements={goalCount}
                onDataRangeChange={(obj: { limit: number; start: number; }) => onDataRangeChange(obj)}
                startingElementsPerPage={formulaState.limit}
                startingPage={formulaState.startingPage}
              />
            </div>
          </div>
        )}
    </>
  );
};

export default GrabFormulaList;
