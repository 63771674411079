import React from 'react';
import { Button, Grid, Input } from 'buildingBlocks';
import { FORMULA_SECTION_STYLES, REVENUE_TYPES_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import { GrabFormulaFilters, REVENUE_SEARCH_ICON } from 'containers/StrategyWizard/steps/GoalSelection/constants';
import GrabFormulaFilterButton from './GrabFormula/GrabFormulaFilterButton';
import { useFormulaContext } from '../contexts/FormulaProvider';

const { filterLabelStyle, buttonGroup, noPointerEvents, formulaFiltersDiv, textAlignRight,
  searchBarWidth } = FORMULA_SECTION_STYLES;

type FormulaFiltersProps = {
  searchValue: string
  onSearchChange: (newValue: string) => void
  filterValue: GrabFormulaFilters
  setFilterValue: (value: GrabFormulaFilters) => void
  loading: boolean
};

const FormulaFilters = ({ searchValue, onSearchChange, filterValue, setFilterValue, loading }: FormulaFiltersProps) => {
  const { searchInputRef, dropzoneRef } = useFormulaContext();
  const pointerStyle = loading ? noPointerEvents : '';
  const searchPlaceholder = filterValue === GrabFormulaFilters.copilot ? 'Search by goal' : 'Search by goal, strategy name or ID';

  return (
    <Grid>
      <Grid.Row style={formulaFiltersDiv}>
        <Grid.Column width={6}>
          <Input
            icon={<img src={REVENUE_SEARCH_ICON} alt="search-icon" />}
            placeholder={searchPlaceholder}
            value={searchValue}
            style={{ ...REVENUE_TYPES_STYLES.searchBarStyle, ...searchBarWidth }}
            className="revenueTypeSearch"
            onChange={(e) => onSearchChange(e.target.value)}
            onClick={() => { dropzoneRef.current.blur(); }}
            ref={searchInputRef}
          />
        </Grid.Column>
        <Grid.Column width={6} style={textAlignRight}>
          <label style={filterLabelStyle}>{filterValue === GrabFormulaFilters.users ? 'Formula' : 'Goal'} Created By</label>
          <Button.Group style={{ ...buttonGroup, ...pointerStyle }}>
            <GrabFormulaFilterButton
              filterName={GrabFormulaFilters.users}
              filterValue={filterValue}
              setFilterValue={setFilterValue}
            />
            <GrabFormulaFilterButton
              filterName={GrabFormulaFilters.copilot}
              filterValue={filterValue}
              setFilterValue={setFilterValue}
            />
          </Button.Group>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default FormulaFilters;
