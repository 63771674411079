/* eslint-disable no-param-reassign */
import _ from 'lodash';
import React, { Dispatch, SetStateAction, useEffect, useState, MutableRefObject } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Button, Card, Header, Image } from 'buildingBlocks';
import { GOAL_TYPES } from 'constantsBase';
import { WizardFormGoalSelection } from 'containers/StrategyWizard/types';
import { DspToPixel } from 'containers/StrategyWizard/components/PixelPicker/types';
import { COPILOT_LAYOUT } from 'globalStyles';
import { GoalType } from 'utils/types';
import { Configuration } from './GoalConfiguration';
import { ARROW_DOWN_RIGHT_SRC, ARROW_UP_RIGHT_SRC, CYOD_UPLOAD_ICON_SRC, SUCCESS_EVENT_COLOR_MAPPING, BLUE_FORMULA_ICON_SRC, NEW_GOAL_SRC } from '../constants';
import { GOAL_SECTION_STYLES } from '../styles';
import { isCYODGoalTypeValue, isAWGGoalType } from '../utils';
import { getFormulaText } from './AWG/ConfirmationModal';

const { SPACING } = COPILOT_LAYOUT;

const {
  card, selectedCard, hoveredCard, cardContent, cardHeader, newText,
  cardGoalSuccessEvent, cardDescription, icon, descHeader, confirmButton,
} = GOAL_SECTION_STYLES;

type GoalCardProps = GoalType & {
  selected?: boolean
  confirmed?: boolean
  setConfirmedGoal: Dispatch<SetStateAction<string>>
  impValueFilters: DspToPixel
  cyodRef: MutableRefObject<boolean>
  setCYODModalOpen?: (x: any) => void
  setShowAwgPage: (x: any) => void
  awgRef: MutableRefObject<boolean>
};

const getStyle = (selected: boolean, hovered: boolean) => {
  const baseStyle = card;
  let style = baseStyle;
  if (selected) {
    style = { ...baseStyle, ...selectedCard };
  } else if (hovered) {
    style = { ...baseStyle, ...hoveredCard };
  }
  return style;
};

const GoalCard = ({
  strategyWizardAbbreviation,
  strategyWizardLongText,
  value,
  valueType,
  label,
  lowerIsBetter = false,
  successEvent,
  selected = false,
  confirmed = false,
  setConfirmedGoal,
  impValueFilters,
  cyodRef,
  setCYODModalOpen,
  setShowAwgPage,
  awgRef,
}: GoalCardProps) => {
  const { control, setValue } = useFormContext<WizardFormGoalSelection>();
  const [hovered, setHovered] = useState<boolean>(false);

  const goalType = useWatch({ name: 'goal.type', control });
  const customGoal = useWatch({ name: 'customGoal', control });
  const metricsConfig = useWatch({ name: 'metricsConfig', control });

  const isCyodGoalType = isCYODGoalTypeValue(value);
  const isImpactGoal = _.isEqual(value, GOAL_TYPES.impactOutcome.value);
  const isAWGGoal = isAWGGoalType(value);
  const isAwgOrImpact = isImpactGoal || isAWGGoal;
  const showConfigureButton = (isCyodGoalType || isAwgOrImpact) && confirmed;
  const showAwgFormula = isAwgOrImpact && customGoal && metricsConfig && confirmed;
  const isNewGoal = GOAL_TYPES[value]?.isNewAdvanced;
  const displayCustomGoalName = isAWGGoal && metricsConfig && !_.isEqual(customGoal?.value, GOAL_TYPES.impactOutcome.value);

  useEffect(() => {
    if (goalType !== value) {
      setHovered(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goalType]);

  const handleConfirmClick = () => {
    if (setCYODModalOpen && isCyodGoalType) {
      setCYODModalOpen(true);
    }
    if (isAwgOrImpact) {
      setShowAwgPage(true);
    }
    setConfirmedGoal(value);
  };

  const handleClick = () => {
    // reinitialize the cyodRef
    cyodRef.current = null;
    awgRef.current = null;
    setValue('goal.type', value);
  };

  const handleImageSrc = () => {
    if (isAwgOrImpact) return BLUE_FORMULA_ICON_SRC;
    if (isCyodGoalType) return CYOD_UPLOAD_ICON_SRC;
    if (lowerIsBetter) return ARROW_DOWN_RIGHT_SRC;
    return ARROW_UP_RIGHT_SRC;
  };

  return (
    <Card
      style={getStyle(selected, hovered)}
      onClick={handleClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Card.Content style={cardContent}>
        {(isNewGoal && !confirmed && !selected) && (
          <div style={{ marginBottom: SPACING[8] }}>
            <Image src={NEW_GOAL_SRC} style={{ marginRight: SPACING[2] }} />
            <span style={newText}>NEW</span>
          </div>
        )}
        <Card.Header style={cardHeader}>
          {strategyWizardAbbreviation}
          <Card.Meta style={{ ...cardGoalSuccessEvent, color: SUCCESS_EVENT_COLOR_MAPPING[successEvent], borderColor: SUCCESS_EVENT_COLOR_MAPPING[successEvent] }}>
            {successEvent}
          </Card.Meta>
        </Card.Header>
        <Card.Description style={cardDescription}>
          <Image
            src={handleImageSrc()}
            style={icon}
          />
          {displayCustomGoalName ? _.get(customGoal, 'name') : strategyWizardLongText}
        </Card.Description>
        {showAwgFormula && (
          <Card.Description style={{ marginTop: SPACING[16] }}>
            <Header as="h6" content="Formula" style={descHeader} />
            {getFormulaText(customGoal?.formula, metricsConfig)}
          </Card.Description>
        )}
        {confirmed && (
          <Configuration
            control={control}
            valueType={valueType}
            label={label}
            value={value}
            impValueFilters={impValueFilters}
            showAwgFormula={showAwgFormula}
            isImpactGoal={isImpactGoal}
          />
        )}
        {selected && (!confirmed || showConfigureButton) && (
          <Button
            style={confirmButton}
            onClick={handleConfirmClick}
            fluid
          >
            {showConfigureButton ? 'Configure' : 'Confirm'}
          </Button>
        )}
      </Card.Content>
    </Card>
  );
};

export default GoalCard;
