import _ from 'lodash';
import React, { useEffect, ChangeEvent } from 'react';
import { useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Grid, Card, Radio, Label, Input } from 'buildingBlocks';
import { getRealValue } from 'buildingBlocks/ReactHookFormComponents/PercentageInput';
import { CYOD_GOAL_TYPE_OBJS, GOAL_TYPES, GOAL_VALUE_TYPE } from 'constantsBase';
import { WizardFormGoalSelection } from 'containers/StrategyWizard/types';
import { CYOD_GOAL_DESCRIPTIONS } from 'containers/StrategyWizard/steps/GoalSelection/constants';
import { CYOD_MODAL_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import { GlobalState } from 'reducers';
import { GoalType } from 'utils/types';

const {
  baseCardStyles, selectedCardStyles, radio, radioText, selectedRadioText,
  goalDescription, goalDescriptionText, goalTypes, goalShortText, optLvlLabel,
  targetRow, targetLabel, targetInput, errorLabel, errorMessage,
} = CYOD_MODAL_STYLES;

const getControlledValue = (valueType: string, target: null | string | number) => {
  if (_.isNil(target) || _.isEqual(target, '')) return '';
  return valueType === GOAL_VALUE_TYPE.PERCENTAGE ? +getRealValue(target, 2) : +target;
};

type CYODGoalConfigurationProps = {
  goal: GoalType
  selected: boolean
  setModalGoalType: (x: any) => void
  setModalGoalTarget: (x: any) => void
};

const CYODGoalConfiguration = (props: CYODGoalConfigurationProps) => {
  const { goal, selected, setModalGoalType, setModalGoalTarget } = props;
  const goalType = useWatch<WizardFormGoalSelection>({ name: 'goal.type' }) as string;
  const goalTarget = useWatch<WizardFormGoalSelection>({ name: 'goal.target' }) as number;
  const cardStyles = { ...baseCardStyles, ...(selected && selectedCardStyles) };
  const radioTextStyles = { ...radioText, ...(selected && selectedRadioText) };

  const handleClick = (e) => {
    e.stopPropagation();
    setModalGoalType(goal.value);
    setModalGoalTarget(!_.isEqual(goalType, goal.value) || _.isNil(goalTarget) ? null : goalTarget);
  };

  return (
    <Card
      color={selected ? 'blue' : undefined}
      className={selected ? 'cyod-goal-card-selected' : ''}
      onClick={handleClick}
    >
      <Card.Content
        style={cardStyles}
      >
        <div style={radioTextStyles}>
          <Radio
            className="opt-lvl"
            style={radio}
            checked={selected}
          />
          <div>
            {goal.text}
            <div style={goalDescription}>
              <p style={goalDescriptionText}>{_.get(CYOD_GOAL_DESCRIPTIONS, goal.value)}</p>
              <p style={goalShortText}>{goal.shortText}</p>
            </div>
          </div>
        </div>
      </Card.Content>
    </Card>
  );
};

type CYODGoalTypeFieldProps = {
  modalGoalType: string
  setModalGoalType: (x: any) => void
  modalGoalTarget: number
  setModalGoalTarget: (x: any) => void
  goalTargetError: string
  setGoalTargetError: (x: any) => void
};

const CYODGoalTypeField = (props: CYODGoalTypeFieldProps) => {
  const { modalGoalType, setModalGoalType, modalGoalTarget, setModalGoalTarget, goalTargetError, setGoalTargetError } = props;

  const valueType = _.get(GOAL_TYPES, `${modalGoalType}.valueType`);
  const currencyCode = _.get(
    useSelector<GlobalState>((state) => state.strategyWizard.attachFlightsStep),
    'defaultCurrency.code',
  );
  const label = valueType === GOAL_VALUE_TYPE.PERCENTAGE ? '%' : currencyCode;

  useEffect(() => {
    if (_.isNil(modalGoalTarget)) {
      setGoalTargetError('');
      return;
    }
    if (modalGoalTarget < 0) {
      setGoalTargetError('Goal target must be at least 0');
      return;
    }
    if (valueType === GOAL_VALUE_TYPE.PERCENTAGE && modalGoalTarget > 1) {
      setGoalTargetError('Goal target must be at most 100');
      return;
    }
    setGoalTargetError('');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalGoalTarget]);

  const handleTargetChange = (_e: ChangeEvent<HTMLInputElement>, data: { value: string }): void => {
    const configuredValue = valueType === GOAL_VALUE_TYPE.PERCENTAGE ? getRealValue(data.value, -2) : (+data.value);
    setModalGoalTarget(data.value ? configuredValue : null);
  };

  return (
    <>
      <Grid.Row columns="1" style={goalTypes}>
        <label htmlFor="optimization" style={optLvlLabel}>
          Optimization
        </label>
        <Card.Group itemsPerRow={2}>
          {_.map(CYOD_GOAL_TYPE_OBJS, (goal) => (
            <CYODGoalConfiguration
              key={goal.value}
              goal={goal}
              selected={_.isEqual(modalGoalType, goal.value)}
              setModalGoalType={setModalGoalType}
              setModalGoalTarget={setModalGoalTarget}
            />
          ))}
        </Card.Group>
      </Grid.Row>
      <Grid.Row style={targetRow}>
        <label htmlFor="optimization" style={targetLabel}>
          Goal Target (For Reporting Only)
        </label>
        <Input
          style={targetInput}
          type="number"
          fluid
          labelPosition="right"
          label={<Label style={goalTargetError ? errorLabel : {}}>{label}</Label>}
          value={getControlledValue(valueType, modalGoalTarget)}
          onChange={handleTargetChange}
          error={!!goalTargetError}
        />
        {goalTargetError && <Label basic style={errorMessage}>{goalTargetError}</Label>}
      </Grid.Row>
    </>
  );
};

export default CYODGoalTypeField;
