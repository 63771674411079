import _ from 'lodash';
import React from 'react';
import { FORMULA_SECTION_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';

type AWGMetric = {
  isWeighted: boolean
  dimension: string
  weighting: object
  name: string
};

type FormulaMetricProps = {
  metric: AWGMetric
};

const FormulaMetric = (props: FormulaMetricProps) => {
  const { metric } = props;
  const metricName = _.startCase(metric?.name);
  return (
    <>
      {metric?.isWeighted
        ? (
          <div style={FORMULA_SECTION_STYLES.weightedBorderStyle}>
            <div style={FORMULA_SECTION_STYLES.weightStyles}>weighted</div> <div style={FORMULA_SECTION_STYLES.weightedMetricStyle}>{metricName}</div>
          </div>
        )
        : <div style={FORMULA_SECTION_STYLES.unWeightedStyle}>{metricName}</div>}
    </>
  );
};

export default FormulaMetric;
