import _ from 'lodash';
import React, { Dispatch, SetStateAction, useState, MutableRefObject } from 'react';
import { useWatch } from 'react-hook-form';
import { Card } from 'buildingBlocks';
import { CYOD_GOAL_TYPES, GOAL_TYPES } from 'constantsBase';
import { DspToPixel } from 'containers/StrategyWizard/components/PixelPicker/types';
import { GoalType } from 'utils/types';
import CYODModal from './CYOD/CYODModal';
import GoalCard from './GoalCard';
import { GOAL_SECTION_STYLES } from '../styles';
import { isCYODGoalTypeValue } from '../utils';

const { newAdvancedGoalsContainer, cardGroup } = GOAL_SECTION_STYLES;

type CyodGoalProps = {
  setConfirmedGoal: Dispatch<SetStateAction<string>>
  confirmedGoal: string
  impValueFilters: DspToPixel
  advancedGoals: Array<GoalType>
  cyodRef: any
  customGoals: Array<GoalType>
  setShowAwgPage:(x: any) => void
  awgRef: MutableRefObject<boolean>
};

const NewAdvancedGoalSection = ({
  setConfirmedGoal,
  confirmedGoal,
  impValueFilters,
  advancedGoals,
  cyodRef,
  customGoals,
  setShowAwgPage,
  awgRef,
}: CyodGoalProps) => {
  const [CYODModalOpen, setCYODModalOpen] = useState<boolean>(false);
  const goalType = useWatch({ name: 'goal.type' });
  const cyodGoalSelected = isCYODGoalTypeValue(goalType);
  const cyodGoalTypeToOmit = cyodGoalSelected ? _.find(CYOD_GOAL_TYPES, (g) => g !== goalType) : GOAL_TYPES.cyodRateOfSuccessEvent.value;
  const goalsToDisplay = _.filter(advancedGoals, (gT) => gT.value !== cyodGoalTypeToOmit);

  const getAdvancedSectionStyle = () => {
    if (_.isEmpty(customGoals)) {
      return { ...newAdvancedGoalsContainer, borderTop: 'none' };
    }
    if (!confirmedGoal) {
      return newAdvancedGoalsContainer;
    }
    return {};
  };

  return (
    <div style={getAdvancedSectionStyle()}>
      <Card.Group itemsPerRow="four" style={cardGroup}>
        {_.map(_.sortBy(goalsToDisplay, 'strategyWizardAbbreviation'), (gT) => (
          <GoalCard
            key={gT.value}
            {...gT}
            selected={(cyodGoalSelected && isCYODGoalTypeValue(gT.value)) || goalType === gT.value}
            confirmed={(cyodGoalSelected && isCYODGoalTypeValue(confirmedGoal)) || confirmedGoal === gT.value}
            setConfirmedGoal={setConfirmedGoal}
            impValueFilters={impValueFilters}
            cyodRef={cyodRef}
            setCYODModalOpen={setCYODModalOpen}
            setShowAwgPage={setShowAwgPage}
            awgRef={awgRef}
          />
        ))}
        {CYODModalOpen && (
          <CYODModal
            CYODModalOpen={CYODModalOpen}
            setCYODModalOpen={setCYODModalOpen}
            setConfirmedGoal={setConfirmedGoal}
            cyodRef={cyodRef}
          />
        )}
      </Card.Group>
    </div>
  );
};

export default NewAdvancedGoalSection;
