import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Icon, Image } from 'buildingBlocks';
import { REVENUE_TYPES_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import { RevenueTypeOutcomes } from 'containers/StrategyWizard/types';
import { getRevenueOutcomesToDisplay, getCollapsedRevTypeInfo } from 'containers/StrategyWizard/steps/GoalSelection/utils';
import { RevTypeOption, ARROW_DOWN, ARROW_RIGHT } from 'containers/StrategyWizard/steps/GoalSelection/constants';
import { useGoalSectionContext } from 'containers/StrategyWizard/steps/GoalSelection/contexts/GoalSectionProvider';
import { RevenueType } from 'constantsBase';
import { Flight } from 'utils/types';
import RevenueOutcomes from './RevenueOutcomes';

const {
  revenueTableContainer, multipleOutcomesHeaderDiv, multipleOutcomesHeader, collapsedText, dotIcon,
} = REVENUE_TYPES_STYLES;

type OutcomeValidationResult = {
  outcomeValue: string | RevenueType
  finalValueRange: number | string
};

type MultipleRevenueOutcomesProps = {
  revenueTypeOptions: Array<RevTypeOption>
  attachedFlights: Array<Flight>
};

const MultipleRevenueOutcomes = (props: MultipleRevenueOutcomesProps) => {
  const { revenueTypeOptions, attachedFlights } = props;
  const { revTypeSearchStr } = useGoalSectionContext();
  const [containerCollapsed, setContainerCollapsed] = useState<boolean>(false);
  const [outcomeVal, setOutcomeVal] = useState<string>('');
  const [valueRange, setValueRange] = useState<string | number>(null);
  const [outcomesToDisplay, setOutcomesToDisplay] = useState<RevenueTypeOutcomes>({});
  const { control } = useFormContext();
  const budgetConfig = useWatch({ name: 'budget', control });

  useEffect(() => {
    const outcomes = getRevenueOutcomesToDisplay(revTypeSearchStr, attachedFlights);
    setOutcomesToDisplay(outcomes);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [revTypeSearchStr]);

  useEffect(() => {
    if (budgetConfig) {
      const resultObj: OutcomeValidationResult = getCollapsedRevTypeInfo(budgetConfig);
      const { outcomeValue, finalValueRange } = resultObj;
      setOutcomeVal(outcomeValue);
      setValueRange(finalValueRange);
    }
  }, [budgetConfig]);

  return (
    <div className="revenueMultipleOutcomes" style={revenueTableContainer}>
      <div
        style={multipleOutcomesHeaderDiv}
        onClick={() => setContainerCollapsed(!containerCollapsed)}
        role="presentation"
      >
        <Image src={containerCollapsed ? ARROW_RIGHT : ARROW_DOWN} style={{ paddingRight: '8px' }} />
        <h5 style={multipleOutcomesHeader}>Customize Revenue Calculation</h5>
        {containerCollapsed && (outcomeVal || valueRange)
          && (
            <div style={collapsedText}>
              Outcome:&nbsp; {outcomeVal}
              <Icon className="circle" size="small" style={dotIcon} />
              Value {!_.isNumber(valueRange) && 'Range'}:&nbsp; {valueRange}
            </div>
          )}
      </div>
      {!containerCollapsed && (
        <RevenueOutcomes
          outcomesToDisplay={outcomesToDisplay}
          setOutcomesToDisplay={setOutcomesToDisplay}
          revenueTypeOptions={revenueTypeOptions}
          flightCount={_.size(attachedFlights)}
        />
      )}
    </div>
  );
};

export default MultipleRevenueOutcomes;
