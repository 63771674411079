import _ from 'lodash';
import React, { CSSProperties, Dispatch, SetStateAction } from 'react';
import { Header } from 'buildingBlocks';
import { WizardFormGoalSelection } from 'containers/StrategyWizard/types';
import { getChildDisplayName } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/utils';
import { SupportedExternalFlightTypes } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/types';
import BudgetIntervals from './BudgetIntervals';
import { BUDGET_SECTION_STYLES } from '../styles';
import { CrossPlatformBudgetSettingsValidationType } from '../utils';
import RevenueTypeSection from './RevenueTypeSection/RevenueTypeSection';
import { useGoalSectionContext } from '../contexts/GoalSectionProvider';

type Props = {
  crossPlatformBudgetSettingsValidation: CrossPlatformBudgetSettingsValidationType
  setCrossPlatformBudgetSettingsValidation: Dispatch<SetStateAction<CrossPlatformBudgetSettingsValidationType>>
  initialFormValues: WizardFormGoalSelection
  resetConfirmedGoal: () => void
};

const BudgetSection = (props: Props) => {
  const {
    crossPlatformBudgetSettingsValidation,
    setCrossPlatformBudgetSettingsValidation,
    initialFormValues,
    resetConfirmedGoal,
  } = props;

  const {
    revenueTypeEnabled, isCrossPlatformOptimization, hasRevTypePermission,
    reduxWizardFormValues: {
      attachFlightsStep: { optimizationLevel },
    },
  } = useGoalSectionContext();
  const flightExtType = _.head(optimizationLevel.externalTypeIds) as number;

  const childObjectTypeDisplayName = getChildDisplayName(flightExtType as SupportedExternalFlightTypes);
  const headerPendoClass = isCrossPlatformOptimization ? 'multiple-flight-budget-header' : 'single-flight-budget-header';

  return (
    <div style={BUDGET_SECTION_STYLES.container as CSSProperties}>
      <Header as="h4" style={BUDGET_SECTION_STYLES.header} className={headerPendoClass}>Budget</Header>
      <p style={BUDGET_SECTION_STYLES.body}>Control budget and revenue types.</p>
      <BudgetIntervals
        flightExtType={flightExtType}
        childObjectTypeDisplayName={childObjectTypeDisplayName}
        crossPlatformBudgetSettingsValidation={crossPlatformBudgetSettingsValidation}
        setCrossPlatformBudgetSettingsValidation={setCrossPlatformBudgetSettingsValidation}
        initialFormValues={initialFormValues}
        resetConfirmedGoal={resetConfirmedGoal}
      />
      {(revenueTypeEnabled && hasRevTypePermission) && (<RevenueTypeSection />)}
    </div>
  );
};

export default BudgetSection;
