/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Map as ImmutableMap, fromJS } from 'immutable';
import { useSelector } from 'react-redux';
import { useAsyncEffect } from 'utils/functionHelpers';
import { Dimmer, Grid, Loader } from 'buildingBlocks';
import { AWG_WEIGHTS_MODAL_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import { GlobalState } from 'reducers';
import { AWGDimensions } from 'constantsBase';
import { WizardFormValues, WeightConfig, WeightObj, MetricConfig, PixelsConfig } from 'containers/StrategyWizard/types';
import { getFlightStates } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/components/GroupSettings/utils';
import { getDimensionText, getModalOptions, isWeighted } from 'containers/StrategyWizard/steps/GoalSelection/utils';
import ErrorMessage from 'components/ErrorComponent/ErrorMessage';
import { COPILOT_LAYOUT } from 'globalStyles';
import DspAccordion from './DspAccordion';
import { useWeightsSectionContext } from '../contexts/WeightsSectionProvider';

const { SPACING } = COPILOT_LAYOUT;

const DSP_HEADER_TOP = 30;
const ERROR_MSG = 'Error loading pixels. Please try again later.';

const DimensionWeights = () => {
  const {
    attachFlightsStep,
    budgetAllocationState,
  } = useSelector<GlobalState>((state) => state.strategyWizard) as WizardFormValues;
  const { sessionMetrics, setSessionMetrics, selectedMetric, selectedDimension } = useWeightsSectionContext();

  const budgetData = _.get(budgetAllocationState, 'data');
  const flightStates = getFlightStates(attachFlightsStep);
  const { validFlights, invalidFlights } = flightStates;
  const flightsToPopulate = [...validFlights, ...invalidFlights];
  const [flightsToDisplay, setFlightsToDisplay] = useState<ImmutableMap<string, WeightObj>>(ImmutableMap());
  const [loading, setLoading] = useState<boolean>(false);
  const [availablePixelsData, setAvailablePixelsData] = useState<PixelsConfig>({});
  const [hasPixelError, setHasPixelError] = useState<boolean>(false);

  useAsyncEffect(async () => {
    if (selectedDimension && !_.isEmpty(budgetData) && _.size(flightsToPopulate)) {
      const flights = await getModalOptions(
        selectedDimension,
        budgetData,
        flightsToPopulate,
        sessionMetrics.getIn([selectedMetric, 'weighting'], {}).toJS(),
        availablePixelsData,
        setAvailablePixelsData,
        setLoading,
        setHasPixelError,
      );
      setFlightsToDisplay(fromJS(flights));
    }
  }, [selectedDimension, budgetData, selectedMetric, sessionMetrics.getIn([selectedMetric, 'weighting'])]);

  useEffect(() => {
    if (flightsToDisplay.size) {
      setSessionMetrics(sessionMetrics.mergeDeep({
        [selectedMetric]: {
          isWeighted: isWeighted(flightsToDisplay.toJS()),
        },
      } as MetricConfig));
    }
  }, [flightsToDisplay]);

  const getDspHeaderStyles = (key) => {
    const index = Object.keys(flightsToDisplay).indexOf(key);
    const top = DSP_HEADER_TOP * index;
    return { top: `${top}px` };
  };

  return (
    <Grid style={{ margin: 0 }}>
      <Grid.Row columns={2} style={{ marginTop: SPACING[8] }}>
        <Grid.Column width={10} style={AWG_WEIGHTS_MODAL_STYLES.headerStyle}><h5>{getDimensionText(selectedDimension)}s</h5></Grid.Column>
        <Grid.Column width={2} style={{ padding: 0 }}><h5>Weights</h5></Grid.Column>
      </Grid.Row>
      <div style={AWG_WEIGHTS_MODAL_STYLES.listScrollingStyle} className="weightsList">
        {(selectedDimension === AWGDimensions.pixel && hasPixelError) && (
          <Grid style={AWG_WEIGHTS_MODAL_STYLES.errorContainer}>
            <ErrorMessage
              errorText={ERROR_MSG}
              style={AWG_WEIGHTS_MODAL_STYLES.errorMessage}
            />
          </Grid>
        )}
        {!hasPixelError && (loading
          ? (
            <Dimmer active inverted>
              <Loader active inverted inline="centered" />
            </Dimmer>
          ) : _.map(flightsToDisplay.toJS() as WeightConfig, (flights: WeightObj, dspId: string) => (
            <DspAccordion
              key={dspId}
              dspId={dspId}
              flights={flights}
              headerStyles={getDspHeaderStyles(dspId)}
              flightsToDisplay={flightsToDisplay}
              setFlightsToDisplay={setFlightsToDisplay}
            />
          ))
        )}
      </div>
    </Grid>
  );
};

export default DimensionWeights;
