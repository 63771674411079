import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDrag } from 'react-dnd';
import { Image } from 'buildingBlocks';
import { ALL_OPERATORS, OPERATOR_SVGS } from 'containers/StrategyWizard/constants';
import { DragDropTypes } from 'containers/StrategyWizard/steps/GoalSelection/constants';
import { FORMULA_SECTION_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import { checkIsOperator } from 'containers/StrategyWizard/utils';
import { COPILOT_COLORS } from 'globalStyles';
import { generateRandomUUID } from 'utils/formattingUtils';
import { useFormulaContext } from '../contexts/FormulaProvider';
import { DraggableItem } from '../contexts/types';

const { operator, metric } = FORMULA_SECTION_STYLES;
const { TEALS, BLUES, METTLES } = COPILOT_COLORS.NEW_DESIGN_SYSTEM;

type DraggableComponentProps = {
  dragContent: string
};

const DraggableComponent = (props: DraggableComponentProps) => {
  const { dragContent } = props;
  const [pressed, setPressed] = useState<boolean>(false);
  const { dropItems, dropItemIds, handleRemoveItem, setDropzoneActive } = useFormulaContext();
  const isOperator = checkIsOperator(dragContent);

  const [{ isDragging }, drag] = useDrag(() => ({
    type: _.includes(ALL_OPERATORS, dragContent) ? DragDropTypes.operator : DragDropTypes.metric,
    item: {
      id: generateRandomUUID(),
      content: dragContent,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item: DraggableItem, monitor) => {
      // Remove dropItem when dropped outside of dropzone
      // getDropResult will return { message: 'dropzone' } if item was dropped in dropzone
      if (!monitor.getDropResult() && dropItemIds.has(item.id)) {
        handleRemoveItem(item.id);
      }
    },
  }), [dropItems]);

  const getStyle = () => {
    const borderColor = isOperator ? TEALS.T700_LOCHINVAR : BLUES.B500_WAVE;
    const style = { ...(isDragging && { outline: `2px solid ${borderColor}` }) };
    if (isOperator) {
      return { ...style, ...operator, ...(pressed && { backgroundColor: TEALS.T300_DATA }) };
    }
    return { ...style, ...metric, ...(pressed && { backgroundColor: METTLES.M300_FOG }) };
  };

  useEffect(() => {
    if (isDragging && !pressed) {
      setPressed(true);
      setDropzoneActive(isDragging);
    }
    // after finishing a drag this gets hit once more - dragging into dropzone should keep dropzone active
    if (!isDragging && pressed) {
      setDropzoneActive(isDragging);
      setPressed(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDragging]);

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      style={getStyle()}
      ref={drag}
    >
      {_.has(OPERATOR_SVGS, dragContent) ? <Image src={OPERATOR_SVGS[dragContent]} alt={dragContent} /> : dragContent}
    </div>
  );
};

export default DraggableComponent;
