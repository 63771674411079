import _ from 'lodash';
import React, { Dispatch, SetStateAction, MutableRefObject, useEffect } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Grid, Header, Image, Divider } from 'buildingBlocks';
import { getFormulaMetrics } from 'containers/StrategyWizard/steps/GoalSelection/utils';
import { OPERATOR_SVGS } from 'containers/StrategyWizard/constants';
import { BUDGET_SECTION_STYLES, AWG_GOAL_PAGE_STYLES, FORMULA_SECTION_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import FormulaMetric from './FormulaMetric';
import ConfirmationModal from './ConfirmationModal';
import WeightsSection from './WeightsSection/WeightsSection';
import WeightsSectionProvider from './contexts/WeightsSectionProvider';
import { useAWGContext } from './contexts/AWGProvider';
import FormulaBuilder from './FormulaSection/FormulaBuilder';
import GoalSettings from './CustomGoalSection/GoalSettings';
import BackButtonConfirmModal from './BackButtonConfirmModal';

const { formulaTextGridStyle, formStyle, formulFieldStyle, gridBorderStyle } = AWG_GOAL_PAGE_STYLES;
const { operatorStyle, operatorImageStyle, styleFormula } = FORMULA_SECTION_STYLES;

type ImpactFormulaSectionProps = {
  formulaText: Array<JSX.Element>
};

const ImpactFormulaSection = ({ formulaText }: ImpactFormulaSectionProps) => (
  <Grid style={gridBorderStyle}>
    <Grid.Row style={formulaTextGridStyle}>
      <div style={formulFieldStyle}>
        {formulaText}
      </div>
    </Grid.Row>
  </Grid>
);

type AWGPageProps = {
  setShowAwgPage: Dispatch<SetStateAction<boolean>>
  awgRef: MutableRefObject<boolean>
};

const AWGPage = ({ setShowAwgPage, awgRef }: AWGPageProps) => {
  const { control } = useFormContext();
  const { selectedFormula, formulaMetrics, isImpactGoal, isCampaignOptType, setFormulaMetrics } = useAWGContext();
  const metricsConfig = useWatch({ name: 'metricsConfig' });

  // eslint-disable-next-line no-param-reassign
  awgRef.current = true;

  useEffect(() => {
    // determine if we populate formulaMetrics from metricConfig or from scratch
    const getFromConfig = !_.isEmpty(metricsConfig);
    const metricInit = getFormulaMetrics(selectedFormula?.metrics, isCampaignOptType, metricsConfig, getFromConfig);
    setFormulaMetrics(metricInit);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFormula, isCampaignOptType, metricsConfig]);

  const formulaText = _.map(selectedFormula?.formula, (item: string, i: number) => {
    if (formulaMetrics && _.includes(selectedFormula.metrics, item)) {
      return <FormulaMetric key={i} metric={formulaMetrics[item]} />;
    }
    if (_.has(OPERATOR_SVGS, item)) {
      return <span key={i} style={operatorStyle}><Image style={operatorImageStyle} src={OPERATOR_SVGS[item]} /></span>;
    }
    return <span key={i} style={styleFormula}>{item}</span>;
  });

  return (
    <>
      <div style={formStyle}>
        <Header as="h4" style={BUDGET_SECTION_STYLES.header} className="AWGHeader">{!isImpactGoal ? 'Create Any Goal' : 'Cost Per Impact'}</Header>
        {!isImpactGoal
          ? (<FormulaBuilder />)
          : (<ImpactFormulaSection formulaText={formulaText} />)}
        <Controller
          name="metricsConfig"
          control={control}
          render={() => (
            <WeightsSectionProvider isCampaignOptType={isCampaignOptType}>
              <WeightsSection />
            </WeightsSectionProvider>
          )}
        />
        {!isImpactGoal && (
        <>
          <Divider style={{ marginTop: 0 }} />
          <GoalSettings />
        </>
        )}
        <div style={{ display: 'flex', justifyContent: 'right' }}>
          <BackButtonConfirmModal setShowAwgPage={setShowAwgPage} />
          <ConfirmationModal setShowAwgPage={setShowAwgPage} />
        </div>
      </div>
    </>
  );
};
export default AWGPage;
