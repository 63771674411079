import _ from 'lodash';
import React from 'react';
import { Grid, Header, Image } from 'buildingBlocks';
import { CURSOR_POINTER } from 'containers/StrategyWizard/steps/GoalSelection/constants';
import { FORMULA_SECTION_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import { ALL_METRICS } from 'containers/StrategyWizard/constants';
import { getDropzoneContent } from 'containers/StrategyWizard/utils';
import DraggableComponent from './DraggableComponent';

const { metricsColumn, sectionHeader, metricsContainer, metricGuide } = FORMULA_SECTION_STYLES;

const GUIDE_TEXT = 'Drag and drop Metrics into the Formula Builder.';

const MetricGuide = () => (
  <div style={metricGuide}>
    <Image alt="pointer" src={CURSOR_POINTER} />
    {GUIDE_TEXT}
  </div>
);

const MetricsSection = () => (
  <Grid.Column width={10} style={metricsColumn}>
    <Header as="h6" content="Metrics" style={sectionHeader} />
    <div style={metricsContainer}>
      {_.map(ALL_METRICS, (metric) => (
        <DraggableComponent key={metric} dragContent={getDropzoneContent(metric)} />
      ))}
    </div>
    <MetricGuide />
  </Grid.Column>
);

export default MetricsSection;
