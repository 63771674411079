import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import React, { MutableRefObject } from 'react';
import { Grid, Header, Icon, Loader } from 'buildingBlocks';
import { GoalSuccessEvent, MetricsFormattingConstants } from 'constantsBase';
import Tooltip from 'containers/Tooltip';
import { BudgetTypes } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/constants';
import { displayDate } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/utils';
import { WizardFormGoalSelection } from 'containers/StrategyWizard/types';
import { Flight } from 'utils/types';
import { BUDGET_INTERVALS, BUDGET_SECTION_STYLES } from '../styles';
import { useGoalSectionContext } from '../contexts/GoalSectionProvider';
import RevenueTypeConfiguration from './RevenueTypeSection/RevenueTypeConfiguration';
import { getRevTypeToolTipContent } from '../utils';

const { budgetIntervalLabel, budgetIntervals, tooltipIcon, revenueCalculationHeader } = BUDGET_SECTION_STYLES;
const {
  activeBullet, commonIntervalStyles, intervalHeaderDiv, intervalsDiv, secondColumnStyles, futureIntervalTextStyle,
  intervalIconStyle, intervalValuesStyle, alignRightStyle, headerWidth, singleRevenueCalcStyling,
} = BUDGET_INTERVALS;

type BudgetIntervalProps = {
  startDate: string
  endDate: string
  budgetAmount: number
  showLabels: boolean
  initialFormValues: WizardFormGoalSelection
  resetConfirmedGoal: Function
  finishCalculations: MutableRefObject<boolean>
  delivery?: number
  budgetImps?: number
};

const formatBudget = (budgetAmount: number, budgetType: string, budgetImps: number) => {
  if (budgetType === BudgetTypes.imps) {
    return numeral(budgetImps).format(MetricsFormattingConstants.NO_DECIMALS);
  }
  return numeral(budgetAmount).format(MetricsFormattingConstants.TWO_DECIMALS);
};

const formatBudgetUnit = (currency: string, budgetType: string) => {
  if (budgetType === BudgetTypes.imps) {
    return _.upperCase(GoalSuccessEvent.impressions);
  }
  return currency;
};

const BudgetIntervalHeader = ({ toolTipContent, hasRevTypePermission }: { toolTipContent: string, hasRevTypePermission: boolean }) => (
  <Grid.Row style={{ ...commonIntervalStyles, ...intervalHeaderDiv }}>
    <Grid.Column width={1}><Header style={budgetIntervalLabel} content="Start Date" /></Grid.Column>
    <Grid.Column width={1}><Header style={budgetIntervalLabel} content="End Date" /></Grid.Column>
    <Grid.Column style={headerWidth}><Header style={budgetIntervalLabel} content="Budget" /></Grid.Column>
    <Grid.Column style={headerWidth}><Header style={budgetIntervalLabel} content="Delivered" /></Grid.Column>
    {hasRevTypePermission && (
      <Grid.Column style={{ ...revenueCalculationHeader, width: '200px' }}>
        <Header style={budgetIntervalLabel} content="Revenue Calculation" />
        <Tooltip content={toolTipContent} tooltipstyling={tooltipIcon} />
      </Grid.Column>
    )}
  </Grid.Row>
);

const BudgetInterval = ({
  endDate, startDate, budgetAmount, showLabels, delivery,
  budgetImps, initialFormValues, resetConfirmedGoal, finishCalculations,
}: BudgetIntervalProps) => {
  const {
    currencyCode: currency, isImpsBudgetType, hasAmznFlights, budgetType, strategyId, hasRevTypePermission, revenueTypeEnabled,
    reduxWizardFormValues: {
      attachFlightsStep: { attachedFlights },
      budgetAllocationState,
    },
  } = useGoalSectionContext();
  const flight: Flight = _.head(attachedFlights);
  const { timezone, externalType: flightExtType, externalId: flightExtId } = flight;
  const budgetData = _.get(budgetAllocationState, 'data');
  const cumDataLatest = _.last(_.get(budgetData, `${flightExtId}.parentData.cumData`));
  const deliveryInImps = _.get(cumDataLatest, 'imps');
  const intervalActive = moment(startDate).isBefore(moment(), 'day');
  const formattedDelivery = _.isNumber(delivery) && numeral(delivery).format(MetricsFormattingConstants.NO_DECIMALS);
  const toolTipContent = getRevTypeToolTipContent(isImpsBudgetType, hasAmznFlights);
  const startDateTodayOrPast = moment(startDate).isSameOrBefore(moment(), 'day');
  const revenueCalculationText = revenueTypeEnabled ? 'Customize' : 'Platform Reported';

  const getDelivery = () => {
    if (!startDateTodayOrPast) return 'Scheduled';
    if (!_.isNumber(delivery) || !intervalActive) return '0';
    if (!finishCalculations.current && strategyId) return <Loader size="mini" inline active />;
    if (budgetType === BudgetTypes.imps) {
      return `${numeral(deliveryInImps).format(MetricsFormattingConstants.NO_DECIMALS)} ${_.upperCase(GoalSuccessEvent.impressions)}`;
    }
    return `${formattedDelivery} ${currency}`;
  };

  return (
    <Grid style={budgetIntervals}>
      {showLabels && <BudgetIntervalHeader toolTipContent={toolTipContent} hasRevTypePermission={hasRevTypePermission} />}
      <Grid.Row style={{ ...commonIntervalStyles, ...intervalsDiv }}>
        <Grid.Column style={intervalIconStyle}>
          {startDateTodayOrPast && <Icon name="circle" size="small" style={activeBullet} />}
        </Grid.Column>
        <Grid.Column style={secondColumnStyles}>
          <p style={intervalValuesStyle}>{displayDate(startDate, flightExtType, timezone)}</p>
        </Grid.Column>
        <Grid.Column style={secondColumnStyles}>
          <p style={intervalValuesStyle}>{displayDate(endDate, flightExtType, timezone)}</p>
        </Grid.Column>
        <Grid.Column style={{ ...alignRightStyle, ...headerWidth }}>
          <p style={intervalValuesStyle}>{formatBudget(budgetAmount, budgetType, budgetImps)} {formatBudgetUnit(currency, budgetType)}</p>
        </Grid.Column>
        <Grid.Column style={{ ...alignRightStyle, ...headerWidth }}>
          <div style={intervalValuesStyle}>{getDelivery()}</div>
        </Grid.Column>
        {hasRevTypePermission && (
          <Grid.Column style={singleRevenueCalcStyling}>
            {!showLabels
              ? <div style={futureIntervalTextStyle}>{revenueCalculationText}</div>
              : (
                <RevenueTypeConfiguration
                  initialFormValues={initialFormValues}
                  resetConfirmedGoal={resetConfirmedGoal}
                />
              )}
          </Grid.Column>
        )}
      </Grid.Row>
    </Grid>
  );
};

export default BudgetInterval;
