import React from 'react';
import { Button, Header, Icon, Modal } from 'buildingBlocks';
import { DSP } from 'constantsBase';
import { EXTERNAL_TYPE_TO_DSP_AND_DEFAULT_OPT_LEVEL } from 'containers/StrategyWizard/steps/AttachFlights/constants';
import { FLIGHT_DETAIL_STYLES } from 'containers/StrategyWizard/steps/AttachFlights/styles';
import { Flight } from 'utils/types';

const {
  iconStyle,
  activationFlightModal,
  activationFlightModalContainer,
  activationFlightModalTextDiv,
  modalWarning,
  activationModalHeader,
  activationModalText,
  activationModalFlightName,
  activationModalBtns,
  activationModalCancelBtn,
  activationModalDeactivateBtn,
  activationModalActivateBtn,
} = FLIGHT_DETAIL_STYLES;

type ActivationFlightModalProps = {
  activationModalOpen: boolean
  setActivationModalOpen: (x:any) => void
  flight: Flight
  removeFlight: Function
  isDeactivation?: boolean
};

const ActivationFlightModal = (props: ActivationFlightModalProps) => {
  const { activationModalOpen, setActivationModalOpen, flight, removeFlight, isDeactivation } = props;
  const dspDisplayName = DSP.getById(flight.dsp).displayName;
  const headerContent = isDeactivation
    ? 'You cannot detach an object with spend from a Cross-Platform strategy'
    : 'Activating an object attached to a Cross-Platform strategy';
  const flightDisplayName = EXTERNAL_TYPE_TO_DSP_AND_DEFAULT_OPT_LEVEL[flight.externalType].optimizationLevel.displayName.single;

  const onRemoveFlight = () => {
    removeFlight();
    setActivationModalOpen(false);
  };

  return (
    <Modal
      size="tiny"
      open={activationModalOpen}
      onOpen={() => setActivationModalOpen(true)}
      trigger={<Icon name={isDeactivation ? 'times' : 'plus'} style={iconStyle} />}
      style={activationFlightModal}
    >
      <div style={activationFlightModalContainer}>
        <div style={{ display: 'flex' }}>
          <img src="/img/components/StrategyWizard/yellow-exclamation-triangle-outline.svg" alt="triangle-warning" style={modalWarning} />
          <div style={activationFlightModalTextDiv}>
            <Header
              as="h4"
              style={activationModalHeader}
              content={headerContent}
            />
            {isDeactivation
              ? (
                <>
                  <p style={activationModalText}>{`This ${flightDisplayName} cannot be detached because it has spent budget set and managed by this strategy.`}</p>
                  <p style={activationModalText}>Proceeding means
                    <span style={activationModalFlightName}> {flight.name}</span>
                    {` will be deactivated in ${dspDisplayName}.`}
                  </p>
                  <p style={activationModalText}>All delivery will continue to be counted toward the relevant budget interval in Copilot as part of this Cross-Platform campaign.</p>
                </>
              ) : (
                <>
                  <p style={activationModalText}>{`This ${flightDisplayName}, `}
                    <span style={activationModalFlightName}> {flight.name}</span>
                    {`, will be activated in ${dspDisplayName} after this strategy is saved.`}
                  </p>
                  <p style={activationModalText}>{`Please note upon activation Copilot will consider this ${flightDisplayName} for budget allocation.`}</p>
                </>
              )}
          </div>
        </div>
        <div style={activationModalBtns}>
          <Button onClick={() => setActivationModalOpen(false)} style={activationModalCancelBtn}>
            Cancel
          </Button>
          <Button onClick={onRemoveFlight} style={isDeactivation ? activationModalDeactivateBtn : activationModalActivateBtn}>
            {isDeactivation && <Icon name="trash alternate outline" />}
            {isDeactivation ? 'Deactivate' : 'Activate'}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ActivationFlightModal;
