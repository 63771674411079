import _ from 'lodash';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { Modal, Icon, Button, Card, Radio } from 'buildingBlocks';
import { MODAL_STYLES } from 'containers/StrategyWizard/steps/AttachFlights/styles';
import { RESET_GOALS_AND_STRATEGY_TYPE } from 'containers/StrategyWizard/constants';
import { OptLevelType, WizardFormAttachFlights } from 'containers/StrategyWizard/types';
import { COPILOT_COLORS } from 'globalStyles';
import { OPTIMIZATION_TYPE_OPTIONS, attachFlightsInfoInitialState, OptimizationType, ATTACH_FLIGHTS_STEP_INITIAL_VALUES } from '../constants';

const wave = COPILOT_COLORS.NEW_DESIGN_SYSTEM.BLUES.B500_WAVE;

type OptTypeCardProps = {
  optType: OptLevelType
  itemSelected: boolean
  handleOnClick: (x: any) => void
};

const OptTypeCard = ({ optType, itemSelected, handleOnClick }: OptTypeCardProps) => (
  <Card
    className={itemSelected ? 'opt-type-card-selected' : ''}
    onClick={!itemSelected ? () => handleOnClick(optType) : undefined}
    style={{ ...MODAL_STYLES.optTypeCard, ...(itemSelected && { border: `4px solid ${wave}` }) }}
  >
    <Card.Content style={MODAL_STYLES.optLvlRadioCard}>
      <div style={MODAL_STYLES.optTypeTextContainer}>
        <div style={{ ...MODAL_STYLES.optLvlRadioText, ...(itemSelected && { color: wave }) }}>
          <Radio
            className="opt-lvl"
            style={MODAL_STYLES.optLvlRadioButton}
            checked={itemSelected}
          />
          <div>
            {optType.displayName}
            <p style={MODAL_STYLES.optTypeHeader}>{optType.header}</p>
            <p style={MODAL_STYLES.optTypeDescription}>{optType.description}</p>
          </div>
        </div>
      </div>
      <img src={optType.imgSrc} alt={optType.displayName} style={MODAL_STYLES.optTypeImg} />
    </Card.Content>
  </Card>
);

type OptimizationTypeSelectionProps = {
  canAccessCrossPlatform: boolean
  optTypeModalOpen: boolean
  setOptTypeModalOpen: (x: any) => void
  disableOptTypeModal: boolean
  selectedOptType: OptimizationType
  setSelectedOptType: (x: any) => void
  setAttachFlightsInfo: (x: any) => void
};

const OptimizationTypeSelection = (props: OptimizationTypeSelectionProps) => {
  const { canAccessCrossPlatform, optTypeModalOpen, setOptTypeModalOpen, disableOptTypeModal, selectedOptType, setSelectedOptType, setAttachFlightsInfo } = props;
  const optTypeOptions = canAccessCrossPlatform ? OPTIMIZATION_TYPE_OPTIONS : _.omit(OPTIMIZATION_TYPE_OPTIONS, [OptimizationType.crossPlatform]);
  const defaultOptType = selectedOptType ?? _.head(_.keys(optTypeOptions)) as OptimizationType;
  const [modalSelectedOptType, setModalSelectedOptType] = useState<OptimizationType>(defaultOptType);
  const { reset } = useFormContext<WizardFormAttachFlights>();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleCancelClick = () => {
    navigate('/strategies');
  };

  const handleConfirmClick = () => {
    if (!_.isEqual(selectedOptType, modalSelectedOptType)) {
      setSelectedOptType(modalSelectedOptType);
      reset(ATTACH_FLIGHTS_STEP_INITIAL_VALUES);
      setAttachFlightsInfo(attachFlightsInfoInitialState);
      dispatch({ type: RESET_GOALS_AND_STRATEGY_TYPE });
    }
    setOptTypeModalOpen(false);
  };

  return (
    <Modal
      size="large"
      closeOnEscape={false}
      closeOnDimmerClick={false}
      open={optTypeModalOpen}
      onClose={() => setOptTypeModalOpen(false)}
      onOpen={() => setOptTypeModalOpen(true)}
      trigger={(
        <Button disabled={disableOptTypeModal} style={MODAL_STYLES.optTypeButton}>Change Optimization Type</Button>
      )}
      dimmer="blurring"
      style={{ maxWidth: 1050 }}
    >
      {selectedOptType && (
        <Icon
          name="times"
          style={{ ...MODAL_STYLES.closeIcon, ...(!selectedOptType && { cursor: 'default' }) }}
          disabled={!selectedOptType}
          onClick={() => setOptTypeModalOpen(false)}
        />
      )}
      <Modal.Header style={MODAL_STYLES.header}>Optimization Type</Modal.Header>
      <Card.Group itemsPerRow={1} style={MODAL_STYLES.optTypeCardGroup}>
        {_.map(optTypeOptions, (oT: OptLevelType) => {
          const optTypeKey = _.findKey(optTypeOptions, oT) as OptimizationType;
          return (
            <OptTypeCard
              key={oT.displayName}
              optType={oT}
              itemSelected={_.isEqual(modalSelectedOptType, optTypeKey)}
              handleOnClick={() => setModalSelectedOptType(optTypeKey)}
            />
          );
        })}
      </Card.Group>
      <div style={MODAL_STYLES.optTypeBtnContainer}>
        {!selectedOptType && (
          <Button
            onClick={handleCancelClick}
            style={{ ...MODAL_STYLES.button, ...MODAL_STYLES.optTypeModalBtn, marginRight: 0 }}
          >
            Cancel
          </Button>
        )}
        <Button
          type="submit"
          onClick={handleConfirmClick}
          style={{ ...MODAL_STYLES.button, ...MODAL_STYLES.optTypeModalBtn }}
          disabled={!modalSelectedOptType}
          primary
        >
          Confirm
        </Button>
      </div>
    </Modal>
  );
};

export default OptimizationTypeSelection;
