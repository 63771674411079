import _ from 'lodash';
import React, { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Table, Accordion, Icon } from 'buildingBlocks';
import { APP_NAME } from 'constantsBase';
import { flightKey } from 'containers/StrategyWizard/utils';
import { WizardFormAttachFlights, FlightsStatusType, ModalSessionFlightsInfoType } from 'containers/StrategyWizard/types';
import style from 'containers/StrategyWizard/styles';
import { changeFlightStatusUtilHelper } from 'containers/StrategyWizard/steps/AttachFlights/components/AttachFlightsModal/utils';
import { COPILOT_COLORS, COPILOT_LAYOUT } from 'globalStyles';
import { Flight, FlightDisplayName } from 'utils/types';
import { FlightRow } from './FlightRow';

const { NEW_DESIGN_SYSTEM: { YELLOWS, GREENS, NEUTRALS } } = COPILOT_COLORS;

type AttachFlightsModalTableProps = {
  flightDisplayName: FlightDisplayName
  modalSessionFlightsInfo: ModalSessionFlightsInfoType
  setModalSessionFlightsInfo: (x: any) => void
  sessionAttachFlights: Array<Flight>
  setSessionAttachFlights: (x: any) => void
};

type ChangeFlightStatus = (s: string, b: boolean) => void;

const LoadingFlight = () => (
  <Table.Row>
    <Table.Cell
      width={12}
      style={{ display: 'table', padding: 'initial', margin: 'initial', paddingLeft: '8px', borderSpacing: '8px' }}
    >
      <div
        style={{
          display: 'table-cell',
          padding: COPILOT_LAYOUT.SPACING[8],
          borderLeft: `4px solid ${NEUTRALS.N400_GRANITE}`,
          borderRadius: 4,
          position: 'relative',
          left: -COPILOT_LAYOUT.SPACING[8],
        }}
      >
        Loading...
      </div>
    </Table.Cell>
  </Table.Row>
);

type FlightRowTableLoadingProps = {
  flights: Array<Flight>
  tableStyle: {}
};

const FlightRowTableLoading = ({
  flights,
  tableStyle,
}: FlightRowTableLoadingProps) => (flights.length > 0 && (
  <Table padded style={tableStyle}>
    <Table.Body>
      {_.map(flights, (_flight, index: number) => (<LoadingFlight key={index} />))}
    </Table.Body>
  </Table>
)
);

type FlightRowTableProps = {
  flights: Array<Flight>
  detachable: boolean
  removeFlight?: ChangeFlightStatus
  flightsStatus: FlightsStatusType
  flightOverrideStrategy?: Function
  tableStyle: {}
  color: string
  alreadyAttached?: boolean
  notEligible?: boolean
  advertiserExtId?: string
  memberExtId?: string
};

const FlightRowTable = ({
  flights,
  detachable,
  removeFlight,
  flightsStatus,
  flightOverrideStrategy,
  tableStyle,
  color,
  alreadyAttached,
  notEligible,
  advertiserExtId,
  memberExtId,
}: FlightRowTableProps) => (flights.length > 0 && (
<Table padded style={tableStyle}>
  <Table.Body>
    {_.map(flights, (flight) => {
      const key = flightKey(flight);
      const flightStatus = _.get(flightsStatus, key, {});

      return (
        <FlightRow
          key={`${key} - ${flight.id}`}
          detachable={detachable}
          // @ts-ignore see above
          flightStatus={flightStatus}
          strategy={flight.strategy}
          flightOverrideStrategy={() => flightOverrideStrategy(key)}
          color={color}
          alreadyAttached={alreadyAttached}
          notEligible={notEligible}
          flight={flight}
          advertiserExtId={advertiserExtId}
          memberExtId={memberExtId}
          // @ts-ignore
          removeFlight={detachable ? () => removeFlight(key) : undefined}
        />
      );
    })}
  </Table.Body>
</Table>
));

export type FlightListName =
  | 'eligibleFlights'
  | 'ineligibleFlights'
  | 'attachedToThisStrategy'
  | 'attachedToAnotherStrategy'
  | 'toBeDetached';

export const ALL_FLIGHT_LIST_NAMES = [
  'eligibleFlights',
  'ineligibleFlights',
  'attachedToThisStrategy',
  'attachedToAnotherStrategy',
  'toBeDetached',
];

export type FlightLists = {
  eligibleFlights: Array<Flight>
  ineligibleFlights: Array<Flight>
  attachedToThisStrategy: Array<Flight>
  toBeDetached: Array<Flight>
  attachedToAnotherStrategy?: Array<Flight>
  reactivatedFlights?: Array<Flight>
  toBeDeactivated?: Array<Flight>
  deactivatedFlights?: Array<Flight>
  eligCPFlightsWithoutAmountBudgetType?: Array<Flight>
  eligCPFlightsWithSpend?: Array<Flight>
};

export const matchesFlightKey = (key: string) => (flight: Flight) => flightKey(flight) === key;

type ModifyFlightLists = (flightList: FlightLists, flightKey: string, s: string, ss: string | null) => FlightLists;

/**
 * modifyFlightLists moves a flight from one list to a different one, or if only from is provided it will removed
 * the flight from that list
 * @param flightLists FlightLists
 * @param key flightKey
 * @param from list to remove flight from
 * @param ?to list to add flight to. If not provided flight will not be added to any list
 */
export const modifyFlightLists: ModifyFlightLists = (flightLists, key, from, to) => {
  const matchesFlight = matchesFlightKey(key);
  const updatedFlightLists = { ...flightLists };
  const flight = _.find(updatedFlightLists[from], matchesFlight);
  if (flight) {
    updatedFlightLists[from] = _.filter(updatedFlightLists[from], _.negate(matchesFlight));
    if (to) {
      updatedFlightLists[to] = [flight, ...updatedFlightLists[to]];
    }
  }
  return updatedFlightLists;
};

export const AttachFlightsModalTable = (props: AttachFlightsModalTableProps) => {
  const {
    flightDisplayName,
    modalSessionFlightsInfo,
    setModalSessionFlightsInfo,
    sessionAttachFlights,
    setSessionAttachFlights,
  } = props;

  const [accordionOpen, setAccordionOpen] = useState<boolean>(false);
  const { flightsStatus, ineligibleFlights, eligibleFlights, pendingFlights, attachedToAnotherStrategy, attachedToThisStrategy } = modalSessionFlightsInfo;
  const member = useWatch({ name: 'member' });
  const advertiser = useWatch({ name: 'advertiser' });
  const { setValue } = useFormContext<WizardFormAttachFlights>();

  const getCurrentFlightLists = () => (_.pick(modalSessionFlightsInfo, ALL_FLIGHT_LIST_NAMES)) as FlightLists;

  const changeFlightStatus = (from: FlightListName, to?: FlightListName) => (key: string) => {
    const matchesFlight = matchesFlightKey(key);
    const flight = _.find(_.concat(eligibleFlights, attachedToAnotherStrategy), matchesFlight);
    if ((from !== 'attachedToAnotherStrategy' && to !== 'eligibleFlights') || _.isNil(to)) {
      setSessionAttachFlights(_.filter([...sessionAttachFlights], (f) => f.externalId !== flight.externalId));
    }
    const currentFlightLists = getCurrentFlightLists();
    changeFlightStatusUtilHelper(
      currentFlightLists,
      setValue,
      flightsStatus,
      key,
      from,
      modalSessionFlightsInfo,
      setModalSessionFlightsInfo,
      to,
      flight,
    );
  };

  const defaultFlightRowTableProps = {
    flightsStatus,
    tableStyle: style.flight.table,
    detachable: true,
  };

  return (
    <>
      <FlightRowTableLoading
        flights={pendingFlights}
        tableStyle={style.flight.table}
      />
      <FlightRowTable
        {...defaultFlightRowTableProps}
        alreadyAttached
        color={YELLOWS.Y500_GOLD}
        flights={attachedToAnotherStrategy}
        removeFlight={changeFlightStatus('attachedToAnotherStrategy')}
        flightOverrideStrategy={changeFlightStatus('attachedToAnotherStrategy', 'eligibleFlights')}
        advertiserExtId={_.get(advertiser, 'externalId')}
        memberExtId={_.get(member, 'externalId')}
      />
      <FlightRowTable
        {...defaultFlightRowTableProps}
        color={GREENS.G500_LIMA}
        flights={_.concat(eligibleFlights, attachedToThisStrategy)}
        removeFlight={(fKey) => {
          const matchesFlight = matchesFlightKey(fKey);
          const flight = _.find(sessionAttachFlights, matchesFlight);
          const previouslyAttached = _.includes(attachedToThisStrategy, flight);
          if (previouslyAttached) {
            changeFlightStatus('attachedToThisStrategy')(fKey);
          } else {
            changeFlightStatus('eligibleFlights')(fKey);
          }
        }}
        advertiserExtId={_.get(advertiser, 'externalId')}
        memberExtId={_.get(member, 'externalId')}
      />
      { ineligibleFlights.length > 0 && (
      <Accordion style={style.flight.accordion}>
        <Accordion.Title
          onClick={() => setAccordionOpen(!accordionOpen)}
          active={accordionOpen}
        >
          <Icon name="dropdown" />
          These {flightDisplayName.multiple} have finished or are ineligible. They will not be updated by {APP_NAME}.
        </Accordion.Title>
        <Accordion.Content active={accordionOpen}>
          <FlightRowTable
            {...defaultFlightRowTableProps}
            notEligible
            detachable={false}
            flights={ineligibleFlights}
            tableStyle={style.flight.accordionTable}
            color={NEUTRALS.N400_GRANITE}
          />
        </Accordion.Content>
      </Accordion>
      )}
    </>
  );
};
