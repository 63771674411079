import _ from 'lodash';
import React, { Dispatch, SetStateAction } from 'react';
import { Button, Modal, Header, Image } from 'buildingBlocks';
import { AWG_WEIGHTS_MODAL_STYLES, BUDGET_SECTION_STYLES, CONFIRMATION_MODAL } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import { WARNING_IMG_PATH } from 'containers/StrategyWizard/constants';

const { cancelBtnStyle, submitBtnStyle, continueBtnStyle } = AWG_WEIGHTS_MODAL_STYLES;
const { header } = BUDGET_SECTION_STYLES;
const { modal, contentContainer, subheaderStyle, confirmModalHeader, weightsSubHeader,
  backButtonModalHeight, backButtonContentGap, modalButtonStyle, confirmModalDiv,
} = CONFIRMATION_MODAL;

type WeightsDeleteModalProps = {
  metricToDelete: string
  setShowModal: Dispatch<SetStateAction<boolean>>
  showModal: boolean
  handleWeightDelete: () => void
};

const WeightsDeleteConfirmModal = ({ metricToDelete, setShowModal, showModal, handleWeightDelete }: WeightsDeleteModalProps) => {
  const handleDelete = () => {
    handleWeightDelete();
    setShowModal(false);
  };

  return (
    <Modal
      open={showModal}
      style={{ ...modal, ...backButtonModalHeight }}
    >
      <Modal.Content style={{ ...contentContainer, ...backButtonContentGap }}>
        <div style={confirmModalDiv}>
          <Image src={WARNING_IMG_PATH} />
          <Header as="h4" content={`Are you sure you want to remove weights from ${_.startCase(metricToDelete)}?`} style={{ ...confirmModalHeader, ...header }} />
        </div>
        <div style={{ ...subheaderStyle, ...weightsSubHeader }}> This action cannot be undone. </div>
        <div style={modalButtonStyle}>
          <Button
            type="button"
            content="Cancel"
            style={cancelBtnStyle}
            onClick={() => setShowModal(false)}
          />
          <Button
            type="button"
            primary
            content="Continue"
            style={{ ...submitBtnStyle, ...continueBtnStyle }}
            onClick={handleDelete}
          />
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default WeightsDeleteConfirmModal;
