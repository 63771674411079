import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import React, { forwardRef, ComponentType, CSSProperties, MutableRefObject } from 'react';
import { useFormContext, Controller, useWatch } from 'react-hook-form';
import InputMask from 'react-input-mask';
import ReactDatePicker from 'react-datepicker';
import { Button, Grid, Icon, Loader } from 'buildingBlocks';
import { MetricsFormattingConstants } from 'constantsBase';
import { WizardFormGoalSelection } from 'containers/StrategyWizard/types';
import { DATE_FORMAT_JS_DATE, ISO_DATE } from 'utils/dateTime';
import { BudgetStatus } from '../constants';
import { BUDGET_INTERVALS } from '../styles';
import { BudgetSettingsType } from '../types';
import { getBudgetCellStyleClass, numberValidate, removeAdditionalDots } from '../utils';
import { useGoalSectionContext } from '../contexts/GoalSectionProvider';
import RevenueTypeConfiguration from './RevenueTypeSection/RevenueTypeConfiguration';

const {
  flex, iconStyle, icon, dateActiveStyle, dateCellStyle, budgetCellStyle, currencyCellStyle, revenueCalculationCellStyle,
  dateInactiveStyle, errorSpan, budgetIntervalListStyle, activeBullet, inactiveBullet, budgetIntervalRow, futureIntervalTextStyle,
} = BUDGET_INTERVALS;

type CalendarProps = {
  active: boolean
  onClick?: () => void
  onChange?: (e) => void
  value?: string
  placeholder?: string
  disabled?: boolean
};

const CustomInput = forwardRef<any, CalendarProps>((props, ref) => {
  const { active, onClick, onChange, disabled, value, placeholder, ...otherProps } = props;
  const colorSettings: CSSProperties = active ? dateActiveStyle : dateInactiveStyle;
  return (
    <div style={{ ...flex, ...colorSettings }}>
      <InputMask
        type="text"
        mask="aaa 99, 9999"
        onClick={onClick}
        onChange={onChange}
        ref={ref}
        value={value || placeholder}
        style={{ backgroundColor: 'transparent', border: 0 }}
        disabled={disabled}
        {...otherProps}
      />
      <Button style={{ ...iconStyle, ...colorSettings }} icon="calendar alternate outline" onClick={onClick} />
    </div>
  );
});

type CrossPlatformBudgetIntervalProps = {
  index: number
  currency: string
  budgetSettings: BudgetSettingsType
  delivery: { [key: string]: number }
  editedBudgetIndex: React.MutableRefObject<Set<any>>
  selectedDate: (index: number) => Date
  goalTargetProps: {
    component: ComponentType<any>
    labelPosition?: string
    label?: string
  }
  remove: (index?: number | number[]) => void
  initialFormValues: WizardFormGoalSelection
  resetConfirmedGoal: Function
  finishCalculations: MutableRefObject<boolean>
};

const CrossPlatformBudgetInterval = (props: CrossPlatformBudgetIntervalProps) => {
  const { control, getValues, setValue } = useFormContext();
  const {
    revenueTypeEnabled, strategyId, hasRevTypePermission,
    reduxWizardFormValues: { goalSelectionStep },
  } = useGoalSectionContext();

  const {
    index,
    currency,
    budgetSettings,
    delivery,
    editedBudgetIndex,
    selectedDate,
    goalTargetProps,
    remove,
    initialFormValues,
    resetConfirmedGoal,
    finishCalculations,
  } = props;

  const revTypeConfig = useWatch({ name: 'budget' });
  const budgetSettingsObj = _.get(budgetSettings, `[${index}]`);
  const budgetSettingsStartDate = _.get(budgetSettingsObj, 'startDate') as Date;
  const budgetSettingsBudget = _.get(budgetSettingsObj, 'budget');
  const budgetSettingBudgetAsNum = _.toNumber(budgetSettingsBudget);
  const previouslySavedStartDate = _.get(goalSelectionStep.budgetSettings, `[${index}].startDate`);
  const startDateTodayOrFuture = (strategyId && previouslySavedStartDate && !_.get(budgetSettingsObj, 'newlyAdded'))
    ? moment(previouslySavedStartDate).isAfter(moment(), 'day')
    : moment(budgetSettingsStartDate).isSameOrAfter(moment(), 'day');
  const endDateActive = moment(_.get(budgetSettingsObj, 'endDate')).isSameOrAfter(moment(), 'day');

  let errorMessage: string;
  let budgetDeliveryStatus: BudgetStatus;
  let intervalDelivery: string;
  const deliveryValue = _.get(delivery, moment(budgetSettingsStartDate).format(ISO_DATE));
  const revenueCalculationText = revenueTypeEnabled ? 'Customize' : 'Platform Reported';

  if (strategyId && budgetSettingsObj) {
    if (startDateTodayOrFuture) {
      intervalDelivery = 'Scheduled';
    } else {
      // Delivery is provided in events for revenue type enabled strategies - always display in currency
      intervalDelivery = numeral(deliveryValue).format(MetricsFormattingConstants.NO_DECIMALS);
      budgetSettings[index].delivery = deliveryValue;
      budgetSettings[index].active = endDateActive;
    }

    const currencyBudget = _.isNaN(budgetSettingBudgetAsNum) ? 0 : +budgetSettings[index].budget;
    const budget = (_.size(revTypeConfig)) ? currencyBudget : budgetSettingsBudget;
    // Touched and edited
    if ((editedBudgetIndex.current.has(index) || (budget && deliveryValue)) && intervalDelivery !== 'N/A') {
      if (budget < deliveryValue && endDateActive) {
        errorMessage = 'Budget should be higher than delivery.';
        budgetDeliveryStatus = BudgetStatus.LESS_THAN_DELIVERY;
      } else if (budget >= deliveryValue && budgetSettings[index].originalValue !== +budgetSettings[index].budget) {
        errorMessage = 'Please ensure objects have enough scale for the budget change.';
        budgetDeliveryStatus = BudgetStatus.GREATER_THAN_DELIVERY;
      }
    }
  }

  if (!_.isNil(budgetSettingsBudget) && editedBudgetIndex.current.has(index) && (budgetSettingBudgetAsNum < 1 || _.toString(budgetSettingsBudget) === '.')) {
    errorMessage = 'Budgets must be greater than or equal to 1.';
    budgetDeliveryStatus = BudgetStatus.LESS_THAN_DELIVERY;
  }

  const onStartDateChange = (date: Date, onChange: Function) => {
    if (!date) {
      return;
    }

    onChange(date);
    if (date > getValues(`budgetSettings[${index}].endDate`)) {
      setValue(`budgetSettings[${index}].endDate`, date);
    }
  };

  const onEndDateChange = (date: Date, onChange: Function) => {
    if (!date) {
      return;
    }

    onChange(date);
  };

  const onBudgetChange = (e: React.ChangeEvent<HTMLInputElement>, data: { value: string }, onChange: Function) => {
    // Allow for just 2 decimal places and just one 'dot'
    const newValue = removeAdditionalDots(data.value).split('.').map((el, i) => (i ? el.split('').slice(0, 2).join('') : el)).join('.');
    // When decimal values are removed via backspace, cursor should not go to the beginning
    const prevIncludesDecimal = _.includes(_.toString(budgetSettingsBudget), '.');
    const currentIncludesDecimal = _.includes(newValue, '.');
    if (prevIncludesDecimal && !currentIncludesDecimal) {
      e.target.selectionStart = 100;
      e.target.selectionEnd = 100;
    }

    onChange(newValue);
    // Capture edited index
    editedBudgetIndex.current.add(index);
  };

  const onRemoveInterval = () => {
    remove(index);
  };

  const deliveryToDisplay = `${intervalDelivery} ${!startDateTodayOrFuture ? currency : ''}`;

  return (
    <>
      <Grid.Row style={budgetIntervalRow}>
        <Grid.Column style={{ ...budgetIntervalListStyle, padding: '0px 10px 0px 0px' }}>
          {!startDateTodayOrFuture ? <Icon name="circle" size="small" style={endDateActive ? activeBullet : inactiveBullet} /> : null}
        </Grid.Column>
        <Grid.Column style={dateCellStyle}>
          <Controller
            control={control}
            name={`budgetSettings[${index}].startDate`}
            render={({ field: { value, onChange } }) => (
              <ReactDatePicker
                className="form-control"
                readOnly={!startDateTodayOrFuture}
                placeholderText=""
                onChange={(date: Date) => onStartDateChange(date, onChange)}
                selected={value || new Date()}
                dateFormat={DATE_FORMAT_JS_DATE}
                minDate={new Date()}
                maxDate={moment(new Date()).add(5, 'y').toDate()}
                shouldCloseOnSelect
                customInput={<CustomInput active={startDateTodayOrFuture} />}
              />
            )}
            rules={{ required: true }}
          />
        </Grid.Column>
        <Grid.Column style={dateCellStyle}>
          <Controller
            control={control}
            name={`budgetSettings[${index}].endDate`}
            render={({ field: { onChange } }) => (
              <ReactDatePicker
                className="form-control"
                readOnly={!endDateActive}
                placeholderText=""
                onChange={(date: Date) => onEndDateChange(date, onChange)}
                selected={selectedDate(index)}
                dateFormat={DATE_FORMAT_JS_DATE}
                minDate={budgetSettings[index]?.startDate >= new Date() ? budgetSettings[index]?.startDate : new Date()}
                maxDate={moment(new Date()).add(5, 'y').toDate()}
                shouldCloseOnSelect
                customInput={<CustomInput active={endDateActive} />}
              />
            )}
            rules={{ required: true }}
          />
        </Grid.Column>
        <Grid.Column style={budgetCellStyle}>
          <Controller
            name={`budgetSettings[${index}].budget`}
            control={control}
            render={(budgetProps) => (
              <goalTargetProps.component
                disabled={!endDateActive}
                className={`${getBudgetCellStyleClass(endDateActive, budgetDeliveryStatus)} budgetIntervalInputTextAlign`}
                type="text"
                onChange={(e: React.ChangeEvent<HTMLInputElement>, data: { value: string }) => onBudgetChange(e, data, budgetProps.field.onChange)}
                onKeyDown={(event) => numberValidate(event)}
                fluid
                label={currency}
                labelPosition="right"
                title=""
                {..._.omit(budgetProps, 'formState')}
              />
            )}
          />
        </Grid.Column>
        {strategyId && (
          <Grid.Column style={currencyCellStyle}>
            <span>{finishCalculations.current ? deliveryToDisplay : <Loader size="mini" inline active />}</span>
          </Grid.Column>
        )}
        {hasRevTypePermission && (
          <Grid.Column style={revenueCalculationCellStyle}>
            {!_.isEqual(index, 0)
              ? <div style={futureIntervalTextStyle}>{revenueCalculationText}</div>
              : (
                <RevenueTypeConfiguration
                  initialFormValues={initialFormValues}
                  resetConfirmedGoal={resetConfirmedGoal}
                />
              )}
          </Grid.Column>
        )}
        <Grid.Column>
          {startDateTodayOrFuture && endDateActive && (
            <Icon
              name="trash alternate outline"
              size="large"
              onClick={onRemoveInterval}
              style={icon}
            />
          )}
        </Grid.Column>
      </Grid.Row>
      {errorMessage && (
        <Grid.Row style={{ padding: 0 }}>
          <Grid.Column style={budgetIntervalListStyle} />
          <Grid.Column style={dateCellStyle} />
          <Grid.Column style={dateCellStyle} />
          <Grid.Column style={errorSpan}>{errorMessage}</Grid.Column>
        </Grid.Row>
      )}
    </>

  );
};

export default CrossPlatformBudgetInterval;
