import _ from 'lodash';
import React, { ReactNode } from 'react';
import { Button } from 'buildingBlocks';
import style from 'containers/StrategyWizard/styles';
import { ATTACH_DSP_OBJECT_STYLES } from 'containers/StrategyWizard/steps/AttachFlights/styles';
import { Flight, ButtonClickHandler } from 'utils/types';

type AdditionalInfoProps = {
  level: string
  msg: ReactNode
};

type FlightStatusProps = {
  alreadyAttached: boolean
  notEligible: boolean
  status: Flight
  strategy: number
  flightOverrideStrategy: ButtonClickHandler
  removeFlight?: ButtonClickHandler
};

const AdditionalInfo = ({ level, msg }: AdditionalInfoProps) => (
  <div
    style={{
      ...style.flight.default,
      ...style.flight[level],
    }}
  >
    {msg}
  </div>
);

export const FlightStatus = ({
  alreadyAttached,
  status,
  strategy,
  flightOverrideStrategy,
  removeFlight,
  notEligible,
}: FlightStatusProps) => {
  if (alreadyAttached) {
    return (
      <AdditionalInfo
        level="WARNING"
        msg={(
          <div style={ATTACH_DSP_OBJECT_STYLES.alreadyAttachedMsg}>
            This is already attached to strategy {strategy}.
            Do you want to instead attach it to this strategy?{'\n'}
            <Button
              className="link"
              style={ATTACH_DSP_OBJECT_STYLES.yesNoButton}
              onClick={flightOverrideStrategy}
            >
              Yes
            </Button>
            <Button
              className="link"
              style={ATTACH_DSP_OBJECT_STYLES.yesNoButton}
              onClick={removeFlight}
            >
              No
            </Button>
          </div>
)}
      />
    );
  } if (notEligible && _.get(status, 'ineligibilityReasons', []).length > 0) {
    return (
      <AdditionalInfo
        level="WARNING"
        msg={Array.isArray(status.ineligibilityReasons) && _.join(status.ineligibilityReasons, '\n')}
      />
    );
  }

  return <div />;
};

export default FlightStatus;
