import React, { useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { Menu } from 'buildingBlocks';
import { SelectedTab } from 'containers/StrategyWizard/steps/AttachFlights/constants';
import { ATTACH_DSP_OBJECT_STYLES } from 'containers/StrategyWizard/steps/AttachFlights/styles';
import { WizardFormAttachFlights } from 'containers/StrategyWizard/types';
import { COPILOT_COLORS } from 'globalStyles';

const matter = COPILOT_COLORS.NEW_DESIGN_SYSTEM.NEUTRALS.N800_MATTER;

type Props = {
  menuItemOneName: string
  menuItemTwoName: string
  setSelectedTab: (arg: any) => void
};

export const FlightMenuSelector = (props: Props) => {
  const {
    menuItemOneName,
    menuItemTwoName,
    setSelectedTab,
  } = props;

  const [activeItem, setActiveItem] = useState<string>(menuItemOneName);
  const advertiser = useWatch<WizardFormAttachFlights>({ name: 'advertiser' });

  useEffect(() => {
    setActiveItem(menuItemOneName);
  }, [menuItemOneName]);

  const handleItemClick = (_e, { name }) => {
    if (name === SelectedTab.bulkUploader) {
      setSelectedTab(SelectedTab.bulkUploader);
    } else {
      setSelectedTab(SelectedTab.selectLineItemCampaign);
    }
    setActiveItem(name);
  };

  const itemOneSelected = activeItem === menuItemOneName;
  const itemTwoSelected = activeItem === menuItemTwoName;
  const activeStyles = { ...ATTACH_DSP_OBJECT_STYLES.flightMenuSelectorText, ...ATTACH_DSP_OBJECT_STYLES.flightMenuSelectorTextActive, ...(advertiser && { color: matter }) };
  const inactiveStyles = { ...ATTACH_DSP_OBJECT_STYLES.flightMenuSelectorText, ...ATTACH_DSP_OBJECT_STYLES.flightMenuSelectorTextInactive };

  return (
    <Menu text style={{ marginBottom: '-1px', fontWeight: 'bold', marginTop: '21px' }}>
      <Menu.Item
        name={menuItemOneName}
        active={activeItem === menuItemOneName}
        onClick={handleItemClick}
        style={itemOneSelected ? activeStyles : inactiveStyles}
      />
      <Menu.Item
        name={menuItemTwoName}
        active={activeItem === menuItemTwoName}
        disabled={!advertiser}
        onClick={handleItemClick}
        style={itemTwoSelected ? activeStyles : inactiveStyles}
      />
    </Menu>
  );
};

export default FlightMenuSelector;
