import _ from 'lodash';
import React from 'react';
import { Button, Header } from 'buildingBlocks';
import { AWG_GOAL_PAGE_STYLES, FORMULA_SECTION_STYLES, AWG_WEIGHTS_MODAL_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import { COPILOT_LAYOUT } from 'globalStyles';
import WeightedMetrics from './WeightedMetrics';
import WeightsModal from './WeightsModal';
import { useWeightsSectionContext } from '../contexts/WeightsSectionProvider';
import { useAWGContext } from '../contexts/AWGProvider';

const { SPACING } = COPILOT_LAYOUT;

const WeightsSection = () => {
  const { formulaMetrics, selectedFormula } = useAWGContext();
  const { handleOpenModal } = useWeightsSectionContext();
  const weightedMetrics = _.pickBy(formulaMetrics, (metric) => _.size(metric.weighting));

  return (
    <div style={AWG_WEIGHTS_MODAL_STYLES.formulaButtons}>
      <Header as="h4" style={FORMULA_SECTION_STYLES.header} className="AWGWeightsHeader">Weights (Optional)</Header>
      <div style={{ float: 'left' }}>
        {
          !_.size(weightedMetrics)
            ? (<span style={{ marginTop: SPACING[4] }}>Assign weights to objects in the buying platform for each metric.</span>)
            : (<WeightedMetrics weightedMetrics={weightedMetrics} />)
          }
        <WeightsModal
          metrics={_.sortBy(selectedFormula?.metrics)}
          trigger={(
            <Button
              secondary
              style={AWG_GOAL_PAGE_STYLES.addWeightsButtonStyle}
              onClick={handleOpenModal}
              disabled={_.isEmpty(formulaMetrics)}
              fluid
            >
              Assign Weights
            </Button>
          )}
        />
      </div>
    </div>
  );
};

export default WeightsSection;
