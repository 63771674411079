// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#solid-cursor {
  width: 1px;
  background-color: black;
}

#formula-builder-input-field {
  padding: 0px 4px 0px 4px !important;
  border: none;
  background-color: #E9EEF5;
}

.hide-icon i.dropdown.icon {
  display: none;
}

div.ui.active.visible.floating.labeled.search.dropdown.hide-icon {
  position: absolute;
  border-radius: 4px;
  height: 32px;
  width: var(--dropdown-width);
}

div.ui.active.visible.floating.labeled.search.dropdown.hide-icon > input.search {
  height: 100%;
  text-align: center;
  line-height: 22px;
  font-weight:  600;
  border-radius: 4px;
  color: var(--custom-input-search-color);
  padding: 0 4px;
}

.no-item-found {
  max-width: 200px;
  word-wrap: break-word;
  white-space: normal !important;
}

.dropdown-cancel-btn {
  border-bottom: 1px solid #EBEAEB !important;
}`, "",{"version":3,"sources":["webpack://./src/containers/StrategyWizard/steps/GoalSelection/GoalSection/AWG/FormulaSection/customInputElement.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,uBAAuB;AACzB;;AAEA;EACE,mCAAmC;EACnC,YAAY;EACZ,yBAAyB;AAC3B;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,YAAY;EACZ,4BAA4B;AAC9B;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,iBAAiB;EACjB,iBAAiB;EACjB,kBAAkB;EAClB,uCAAuC;EACvC,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,qBAAqB;EACrB,8BAA8B;AAChC;;AAEA;EACE,2CAA2C;AAC7C","sourcesContent":["#solid-cursor {\n  width: 1px;\n  background-color: black;\n}\n\n#formula-builder-input-field {\n  padding: 0px 4px 0px 4px !important;\n  border: none;\n  background-color: #E9EEF5;\n}\n\n.hide-icon i.dropdown.icon {\n  display: none;\n}\n\ndiv.ui.active.visible.floating.labeled.search.dropdown.hide-icon {\n  position: absolute;\n  border-radius: 4px;\n  height: 32px;\n  width: var(--dropdown-width);\n}\n\ndiv.ui.active.visible.floating.labeled.search.dropdown.hide-icon > input.search {\n  height: 100%;\n  text-align: center;\n  line-height: 22px;\n  font-weight:  600;\n  border-radius: 4px;\n  color: var(--custom-input-search-color);\n  padding: 0 4px;\n}\n\n.no-item-found {\n  max-width: 200px;\n  word-wrap: break-word;\n  white-space: normal !important;\n}\n\n.dropdown-cancel-btn {\n  border-bottom: 1px solid #EBEAEB !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
