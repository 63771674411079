import _ from 'lodash';
import React from 'react';
import { Map as ImmutableMap } from 'immutable';
import { Grid } from 'buildingBlocks';
import { AWG_WEIGHTS_MODAL_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import { METRIC_SEARCH_OBJS } from 'containers/StrategyWizard/constants';
import { WeightObj } from 'containers/StrategyWizard/types';
import { AWGDimensions } from 'constantsBase';
import { COPILOT_COLORS } from 'globalStyles';
import { useWeightsModalContentContext } from '../contexts/WeightsModalContentProvider';
import { useWeightsSectionContext } from '../contexts/WeightsSectionProvider';

const { NEW_DESIGN_SYSTEM: { NEUTRALS } } = COPILOT_COLORS;

type MetricTabsProps = {
  metrics: Array<string>
};

const MetricTabs = ({ metrics }: MetricTabsProps) => {
  const { setFormErrors } = useWeightsModalContentContext();
  const { sessionMetrics, selectedMetric, defaultOptTypeDimension, setSelectedMetric, setSelectedDimension } = useWeightsSectionContext();
  const isMetricWeighted = (metric: string) => sessionMetrics.getIn([metric, 'isWeighted']);

  const handleOnClick = (metric: string) => {
    setSelectedMetric(metric);
    // reset form errors when user switches metrics
    setFormErrors({});
    // set dimension to default if not weighted
    const metricWeighting = sessionMetrics.getIn([metric, 'weighting']) as ImmutableMap<string, WeightObj>;
    if (!metricWeighting.size) {
      setSelectedDimension(defaultOptTypeDimension);
    } else {
      setSelectedDimension(sessionMetrics.getIn([metric, 'dimension']) as AWGDimensions);
    }
  };

  return (
    <div style={AWG_WEIGHTS_MODAL_STYLES.modalLeftCard} className="weightsList">
      <Grid.Row>
        {_.map(metrics, (metric: string) => (
          <div
            key={metric}
            role="button"
            tabIndex={0}
            style={metric === selectedMetric ? AWG_WEIGHTS_MODAL_STYLES.activeCards : AWG_WEIGHTS_MODAL_STYLES.cards}
            onClick={() => handleOnClick(metric)}
          >
            <Grid.Row>
              <div style={{ ...AWG_WEIGHTS_MODAL_STYLES.weightStatusCard, ...(!isMetricWeighted(metric) && { backgroundColor: NEUTRALS.N300_ALTO }) }}>
                {isMetricWeighted(metric) ? 'Weighted' : 'Not Weighted'}
              </div>
            </Grid.Row>
            <Grid.Row>
              <div style={AWG_WEIGHTS_MODAL_STYLES.weightCardDesc}>{_.get(_.find(METRIC_SEARCH_OBJS, ['value', metric]), 'text')}</div>
            </Grid.Row>
          </div>
        ))}
      </Grid.Row>
    </div>
  );
};

export default MetricTabs;
