import _ from 'lodash';
import React, { SyntheticEvent, useRef, useState, useEffect, CSSProperties, MutableRefObject, SetStateAction, Dispatch } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Button, Header, Icon, Loader, Search } from 'buildingBlocks';
import { DSP, GOAL_TYPES, GOAL_TYPES_TO_STRATEGY_TYPES, RevenueType } from 'constantsBase';
import { CLEAR_AWG_CONFIG } from 'containers/StrategyWizard/constants';
import { WizardFormGoalSelection } from 'containers/StrategyWizard/types';
import { useFetchDspSpecificChildren } from 'containers/StrategyWizard/utils';
import { PossibleStates } from 'utils/hooks/useFetcher';
import { GoalType } from 'utils/types';
import GoalSuccessEventFilterButton from './GoalSuccessEventFilterButton';
import GoalTypeFilterButton from './GoalTypeFilterButton';
import MultiGoalView from './MultiGoalView';
import { GOAL_TYPES_FOR_STRATEGY_WIZARD, GoalTypeSearchCriteria, CONVERSION_BASED_GOALS } from '../constants';
import { GOAL_SECTION_STYLES } from '../styles';
import { filterGoals, isAWGGoalType, populateBaseGoalTypes } from '../utils';
import { useGoalSectionContext } from '../contexts/GoalSectionProvider';

type GoalSelectionProps = {
  initialValues: WizardFormGoalSelection
  isHigherLevelOptimizationLevel: boolean
  setShowAwgPage: (x: any) => void
  awgRef: MutableRefObject<boolean>
  confirmedGoal: string
  setConfirmedGoal: Dispatch<SetStateAction<string>>
};

const GoalSelection = (props: GoalSelectionProps) => {
  const {
    setShowAwgPage, confirmedGoal, setConfirmedGoal,
    initialValues, isHigherLevelOptimizationLevel, awgRef,
  } = props;
  const {
    successEventFilter, setGoalSuccessEventFilter, revenueTypeEnabled, isCrossPlatformOptimization,
    strategyId, dsp, user, firstFlightExtId, hasAmznFlights,
    reduxWizardFormValues: {
      attachFlightsStep: { optimizationLevel, member, attachedFlights },
      goalSelectionStep: { budget: reduxBudget },
      budgetAllocationState,
      strategyTypeSelectionStep,
    },
    isCampaignOptimization,
  } = useGoalSectionContext();
  const selectedStratTypeId = _.get(strategyTypeSelectionStep, 'strategyType.id') as number;
  const dispatch = useDispatch();

  const { reset, setValue } = useFormContext<WizardFormGoalSelection>();
  const goalType = useWatch({ name: 'goal.type' });
  const goalTarget = useWatch({ name: 'goal.target' });
  const customGoal = useWatch({ name: 'customGoal' });
  const budgetSettings = useWatch({ name: 'budgetSettings' });
  const budgetConfig = useWatch({ name: 'budget' });

  const [searchValue, setSearchValue] = useState<string>('');
  const [showFilters, setShowFilters] = useState<boolean>(true);
  const [focusSearchInput, setFocusSearchInput] = useState<boolean>(false);
  const [baseGoalTypes, setBaseGoalTypes] = useState<{ [key: string]: GoalType }>({});
  const [visibleGoals, setVisibleGoals] = useState<{ [key: string]: GoalType }>({});
  const [goalTypeFilter, setGoalTypeFilter] = useState<GoalTypeSearchCriteria>(GoalTypeSearchCriteria.standard);
  const flightExtType = _.head(_.get(optimizationLevel, 'externalTypeIds')) as number;

  // cannot call hooks in callbacks or else I would put this call in the filter fn for only YouTube goal types
  const budgetConfigFlightExtId = _.findKey(budgetConfig, 'outcome');
  const revenueType = useWatch({ name: `budget[${budgetConfigFlightExtId}].outcome` }) as RevenueType;
  const revenueOutcomeType = useWatch({ name: 'revenueOutcomeType' });
  const dspSpecificChildrenState = useFetchDspSpecificChildren(dsp, budgetAllocationState, firstFlightExtId);
  const showRevMarginGoal = revenueTypeEnabled && (isCrossPlatformOptimization || isCampaignOptimization);
  const allTTDFlights = _.every(attachedFlights, (f) => _.isEqual(f.dsp, DSP.TTD.id));
  const isImpactGoal = _.isEqual(goalType, GOAL_TYPES.impactOutcome.value);
  const isAwgOrImpact = isAWGGoalType(goalType) || isImpactGoal;

  useEffect(() => {
    const getGT = () => {
      let baseGT = populateBaseGoalTypes(
        user,
        flightExtType,
        // @ts-ignore
        _.get(dspSpecificChildrenState, 'data', []),
        optimizationLevel,
        _.pick(member, ['externalId', 'businessModel']),
        isCrossPlatformOptimization,
        hasAmznFlights,
        allTTDFlights,
      );
      // in Edit mode, show only goals tied to the strategyTypeId
      if (strategyId) {
        baseGT = _.pickBy(baseGT, (goal: GoalType) => _.includes(GOAL_TYPES_TO_STRATEGY_TYPES[goal.value], selectedStratTypeId));
      }
      // filter out pixel supported goals for cross-platform if an AMZN flight is attached
      if (isCrossPlatformOptimization && _.some(attachedFlights, ['dsp', DSP.AMZN.id])) {
        baseGT = _.omitBy(baseGT, (goal: GoalType) => _.includes(CONVERSION_BASED_GOALS, goal.value));
      }
      setBaseGoalTypes(baseGT);
      const filteredGoals = filterGoals(baseGT, {
        goalType: goalTypeFilter,
        successEvent: successEventFilter,
        revenueTypeEnabled,
        isCrossPlatformOptimization,
        isCampaignOptimization,
        flightExtType,
        user,
      });
      setVisibleGoals(filteredGoals);
    };
    getGT();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dspSpecificChildrenState.kind]);

  useEffect(() => {
    if (_.size(customGoal?.formula) || isAwgOrImpact) {
      setValue('goal.target', goalTarget);
      if (!isImpactGoal) {
        // repopulate goal.type with awgGoalType that was selected
        setValue('goal.type', GOAL_TYPES.awgCreateYourOwn.value);
      }
      setConfirmedGoal(!isImpactGoal ? GOAL_TYPES.awgCreateYourOwn.value : GOAL_TYPES.impactOutcome.value);
    } else {
      reset({ ...initialValues, budgetSettings });
      setConfirmedGoal(initialValues.goal.type);
    }
    if (_.get(GOAL_TYPES_FOR_STRATEGY_WIZARD, [initialValues.goal.type, 'isCustom']) || isAwgOrImpact) {
      setGoalTypeFilter(GoalTypeSearchCriteria.advanced);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues.goal.type]);

  useEffect(() => {
    // Hide filters when user starts typing search
    if (_.size(searchValue) && showFilters) {
      setShowFilters(false);
    }
    // Show filters when user resets filters
    if (!_.size(searchValue) && !showFilters) {
      setShowFilters(true);
    }
  }, [searchValue, showFilters]);

  useEffect(() => {
    // in edit case, want confirmedGoal to be what goalType is initially
    if (_.size(reduxBudget) && _.size(customGoal)) {
      setConfirmedGoal(goalType);
    }
    const scrollPos = window.scrollY;
    const filteredGoals = filterGoals(baseGoalTypes, { goalType: goalTypeFilter, successEvent: successEventFilter, revenueTypeEnabled, flightExtType, user, isCrossPlatformOptimization, isCampaignOptimization });
    setVisibleGoals(filteredGoals);
    window.scrollTo({ top: scrollPos });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goalTypeFilter, successEventFilter, revenueType, revenueTypeEnabled, revenueOutcomeType]);

  useEffect(() => {
    if (revenueTypeEnabled && !isAwgOrImpact) {
      // setting GoalTypeSearchCriteria to standard when selected goal is not Awg or impact when revenue toggle on
      setGoalTypeFilter(GoalTypeSearchCriteria.standard);
    }
    if (!revenueTypeEnabled && _.get(GOAL_TYPES, `${confirmedGoal}.isCustom`, false)) {
      setValue('goal.type', confirmedGoal);
      // setting GoalTypeSearchCriteria to advanced when selected goal an advanced goal when revnue toggle off
      setGoalTypeFilter(GoalTypeSearchCriteria.advanced);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [revenueTypeEnabled]);

  useEffect(() => {
    setGoalSuccessEventFilter('all');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [revenueOutcomeType]);

  useEffect(() => {
    setSearchValue('');
  }, [successEventFilter]);

  useEffect(() => {
    const filteredGoals = filterGoals(baseGoalTypes, { goalType: goalTypeFilter, successEvent: 'all', revenueTypeEnabled, flightExtType, user, isCrossPlatformOptimization, isCampaignOptimization });
    setVisibleGoals(filteredGoals);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goalTypeFilter]);

  const onSearchChange = (_evt: SyntheticEvent, { value }) => {
    setSearchValue(value);
    setVisibleGoals(filterGoals(baseGoalTypes, { goalType: goalTypeFilter, textSearch: value, revenueTypeEnabled, flightExtType, user, isCrossPlatformOptimization, isCampaignOptimization }));
  };

  const searchRef = useRef(null);
  useEffect(() => {
    onSearchChange(null, { value: '' });
    if (searchRef.current) {
      searchRef.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusSearchInput]);

  const resetSearch = () => {
    setConfirmedGoal(null);
    setFocusSearchInput(!focusSearchInput);
  };

  const changeGoal = () => {
    if (_.size(customGoal)) {
      dispatch({ type: CLEAR_AWG_CONFIG });
      setValue('goal.target', null);
      setValue('metricsConfig', null);
      // setting customGoal to null directly doesn't work as expected
      setValue('customGoal.formula', null);
      setValue('customGoal.metrics', null);
      setValue('customGoal.name', null);
      setValue('customGoal.value', null);
      setValue('customGoal.optimizationDirection', null);
    }
    setConfirmedGoal(null);
    setVisibleGoals(filterGoals(baseGoalTypes, { goalType: goalTypeFilter, successEvent: 'all', revenueTypeEnabled, flightExtType, user, isCrossPlatformOptimization, isCampaignOptimization }));
  };

  const singleGoalView = !_.isNil(confirmedGoal) && _.has(visibleGoals, confirmedGoal);

  const visibleGoalSuccessEvents = _.chain(showRevMarginGoal ? visibleGoals : baseGoalTypes)
    .filter((gT) => (goalTypeFilter === GoalTypeSearchCriteria.advanced ? gT.isCustom : !gT.isCustom))
    .map('successEvent')
    .uniq()
    .sortBy(_.identity)
    .value();

  const showAdvancedGoals = _.some(baseGoalTypes, 'isCustom');

  const renderGoalCards = () => {
    // for Tactic Optimization Level, there is no call to budgetAllocationState
    if (!isHigherLevelOptimizationLevel || dspSpecificChildrenState.kind === PossibleStates.hasData || isCrossPlatformOptimization) {
      return (
        <MultiGoalView
          visibleGoals={singleGoalView ? _.pick(visibleGoals, confirmedGoal) : visibleGoals}
          confirmedGoal={confirmedGoal}
          setConfirmedGoal={setConfirmedGoal}
          resetSearch={resetSearch}
          initialValues={initialValues}
          goalTypeFilter={goalTypeFilter}
          setShowAwgPage={setShowAwgPage}
          awgRef={awgRef}
        />
      );
    }
    switch (dspSpecificChildrenState.kind) {
      case PossibleStates.initial:
      case PossibleStates.loading:
        return (<Loader size="large" style={GOAL_SECTION_STYLES.loader} inline />);
      case PossibleStates.error:
      default:
        return (
          <div style={GOAL_SECTION_STYLES.noVisibleGoals}>
            <p>Error fetching goals. Please try again later.</p>
          </div>
        );
    }
  };

  return (
    <div style={GOAL_SECTION_STYLES.container as CSSProperties}>
      <Header as="h4" style={GOAL_SECTION_STYLES.header}>Goals</Header>
      <p style={GOAL_SECTION_STYLES.subHeader}>Select the goal for Copilot to optimize towards.</p>
      {singleGoalView ? (
        <div
          style={GOAL_SECTION_STYLES.editGoal as CSSProperties}
          onClick={changeGoal}
          role="button"
          tabIndex={0}
        >
          <Icon name="edit" />
          Change Goal
        </div>
      ) : (
        <>
          {showFilters && (
            <>
              <Button.Group style={GOAL_SECTION_STYLES.buttonGroup}>
                <GoalTypeFilterButton
                  goalTypeFilter={GoalTypeSearchCriteria.standard}
                  currentGoalTypeFilter={goalTypeFilter}
                  setGoalTypeFilter={setGoalTypeFilter}
                />
                {showAdvancedGoals && (
                  <GoalTypeFilterButton
                    goalTypeFilter={GoalTypeSearchCriteria.advanced}
                    currentGoalTypeFilter={goalTypeFilter}
                    setGoalTypeFilter={setGoalTypeFilter}
                  />
                )}
              </Button.Group>
              <Button.Group>
                <GoalSuccessEventFilterButton
                  successEventFilter="all"
                  key="all"
                  currentGoalSuccessEventFilter={successEventFilter}
                  setSearchValue={setSearchValue}
                />
                {_.map(visibleGoalSuccessEvents, (sE) => (
                  <GoalSuccessEventFilterButton
                    key={sE}
                    successEventFilter={sE}
                    currentGoalSuccessEventFilter={successEventFilter}
                    setSearchValue={setSearchValue}
                  />
                ))}
              </Button.Group>
            </>
          )}
          <div style={GOAL_SECTION_STYLES.searchContainer}>
            <Search
              className="searchBarGoalsStrategyWizard"
              onSearchChange={onSearchChange}
              value={searchValue}
              placeholder="Search goals"
              showNoResults={false}
              aligned="right"
              input={{ ref: searchRef }}
            />
            {!!_.size(searchValue) && (
              <div style={GOAL_SECTION_STYLES.clearSearch} onClick={resetSearch} role="button" tabIndex={0}>
                Clear search
              </div>
            )}
          </div>
        </>
      )}
      {renderGoalCards()}
    </div>
  );
};

export default GoalSelection;
