import _ from 'lodash';
import React, { Dispatch, MouseEventHandler, MutableRefObject, SetStateAction, useEffect, useRef } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Card } from 'buildingBlocks';
import { WizardFormGoalSelection } from 'containers/StrategyWizard/types';
import { OptimizationType } from 'containers/StrategyWizard/steps/AttachFlights/constants';
import { configuringLineItemStratCheck } from 'containers/StrategyWizard/steps/AttachFlights/utils';
import { GlobalState } from 'reducers';
import { GoalType } from 'utils/types';
import { isAWGOrImpact } from '../utils';
import GoalCard from './GoalCard';
import NewAdvancedGoalSection from './NewAdvancedGoalSection';
import { GoalTypeSearchCriteria } from '../constants';
import { GOAL_SECTION_STYLES } from '../styles';

type GoalContentProps = {
  visibleGoals: { [key: string]: GoalType }
  confirmedGoal: string
  setConfirmedGoal: Dispatch<SetStateAction<string>>
  resetSearch: MouseEventHandler
  initialValues: WizardFormGoalSelection
  goalTypeFilter: string
  setShowAwgPage:(x: any) => void
  awgRef: MutableRefObject<boolean>
};

const MultiGoalView = ({
  visibleGoals,
  confirmedGoal,
  setConfirmedGoal,
  resetSearch,
  initialValues,
  goalTypeFilter,
  setShowAwgPage,
  awgRef,
}: GoalContentProps) => {
  const { setValue, reset } = useFormContext();
  const goalType = useWatch({ name: 'goal.type' });
  const impValueFilters = useWatch({ name: 'goal.impValueFilters' });
  const metricsConfig = useWatch({ name: 'metricsConfig' });
  const budgetSettings = useWatch({ name: 'budgetSettings' });
  const customGoals = _.filter(visibleGoals, (item) => item.isCustom && !item.isNewAdvanced);
  const newAdvancedGoals = _.filter(visibleGoals, (item) => item.isNewAdvanced);
  const selectedOptType = useSelector<GlobalState>((state) => _.get(state.strategyWizard.attachFlightsStep, 'selectedOptType')) as OptimizationType;
  const isCrossPlatformOrCampaignOptType = !configuringLineItemStratCheck(selectedOptType);
  const showNewAdvancedGoalsSection = !!_.size(newAdvancedGoals) && isCrossPlatformOrCampaignOptType;

  // ref is used to determine if goal type is changed via the CYOD modal
  // goal is changed via CYOD modal then the resetting of the target in the useEffect should not happen
  // cyodRef is set to null everytime the confirm button on a goal card is clicked
  const cyodRef = useRef<boolean | null>(null);
  const goalsToDisplay = (goalTypeFilter !== GoalTypeSearchCriteria.advanced) ? _.filter(visibleGoals, (item) => !item.isNewAdvanced) : customGoals;
  const isAwgOrImpact = isAWGOrImpact(goalType);

  useEffect(() => {
    // if the goal type is not AWG or impact and is changed back to what was previously saved reset the initial values
    if (goalType && goalType === initialValues.goal.type && !isAwgOrImpact) {
      reset({ ...initialValues, budgetSettings });
    }
    // if goal type is changed via the goal cards reset the other goal fields
    if (!cyodRef.current && goalType !== initialValues.goal.type) {
      if (!_.size(metricsConfig) && !awgRef.current) {
        // only reset goal.target when goalType isnt awg
        // toggling in and out of awg formula section should retain goal.target
        setValue('goal.target', null);
      }
      setValue('goal.impValueFilters', null);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goalType]);

  return (_.size(visibleGoals)
    ? (
      <>
        <Card.Group itemsPerRow="four" style={GOAL_SECTION_STYLES.cardGroup}>
          {_.map(_.sortBy(goalsToDisplay, 'strategyWizardAbbreviation'), (gT: GoalType) => (
            <GoalCard
              key={gT.value}
              {...gT}
              selected={goalType === gT.value}
              confirmed={confirmedGoal === gT.value}
              setConfirmedGoal={setConfirmedGoal}
              impValueFilters={impValueFilters}
              cyodRef={cyodRef}
              setShowAwgPage={setShowAwgPage}
              awgRef={awgRef}
            />
          ))}
        </Card.Group>
        {showNewAdvancedGoalsSection && (
          <NewAdvancedGoalSection
            setConfirmedGoal={setConfirmedGoal}
            impValueFilters={impValueFilters}
            confirmedGoal={confirmedGoal}
            advancedGoals={newAdvancedGoals}
            cyodRef={cyodRef}
            customGoals={customGoals}
            setShowAwgPage={setShowAwgPage}
            awgRef={awgRef}
          />
        )}
      </>
    ) : (
      <div style={GOAL_SECTION_STYLES.noVisibleGoals}>
        <p>No goals available.</p>
        <div style={GOAL_SECTION_STYLES.searchAgain} onClick={resetSearch} role="button" tabIndex={0}>Search again.</div>
      </div>
    )
  );
};

export default MultiGoalView;
