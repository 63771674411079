/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import _ from 'lodash';
import React, { Dispatch, MutableRefObject, SetStateAction, useEffect, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useFormContext, useWatch } from 'react-hook-form';
import { Image } from 'buildingBlocks';
import { ALL_OPERATORS, OPERATOR_SVGS, TRUE_VIEWS } from 'containers/StrategyWizard/constants';
import { ALLOWED_DRAG_DROP_TYPES, BLACK_CIRCLE_X, DragDropTypes, EXTERNAL_MEASUREMENT } from 'containers/StrategyWizard/steps/GoalSelection/constants';
import { FORMULA_SECTION_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import { checkIsMetric, checkIsOperator, isTrueView } from 'containers/StrategyWizard/utils';
import { COPILOT_COLORS, COPILOT_LAYOUT } from 'globalStyles';
import { generateRandomUUID } from 'utils/formattingUtils';
import { usePrevious } from 'utils/hooks/usePrevious';
import { useFormulaContext } from '../contexts/FormulaProvider';
import { ActionTypes, DraggableItem } from '../contexts/types';
import { useAWGContext } from '../contexts/AWGProvider';

const {
  operator, number, metric, dropzoneComponent,
  hoverDeleteBtn, weightedMetricWrapper, weighted,
} = FORMULA_SECTION_STYLES;
const { BLUES, TEALS, YELLOWS, METTLES } = COPILOT_COLORS.NEW_DESIGN_SYSTEM;
const { SPACING } = COPILOT_LAYOUT;

type DropzoneComponentProps = {
  dragContent: string
  contentIdx: number
  contentId: string
  selected: boolean
  setSelected: Dispatch<SetStateAction<boolean>>
  draggingSomething: boolean
  setDraggingSomething: Dispatch<SetStateAction<boolean>>
  lastDroppedItemBottom: MutableRefObject<number>
};

const DropzoneComponent = (props: DropzoneComponentProps) => {
  const { contentId, dragContent, contentIdx, selected, setSelected, draggingSomething, setDraggingSomething, lastDroppedItemBottom } = props;
  const ref = useRef<HTMLDivElement>(null);
  const indexToInsertAt = useRef<number>(null);
  const [hovered, setHovered] = useState<boolean>(false);

  const { formulaMetrics } = useAWGContext();
  const {
    dropItems, dropItemIds, cursorIdx, handleRemoveItem,
    handleReorder, setDropzoneActive, insertItemAt, setCursorIdx,
    addUndoAction,
  } = useFormulaContext();
  const prevCursorIdx = useRef<number>(contentIdx);
  const metricsConfig = useWatch({ name: 'metricsConfig' });
  const { setValue } = useFormContext();
  const isOperator = checkIsOperator(dragContent);
  const isTrueViewMetric = isTrueView(dragContent);

  const getComponentContent = () => {
    if (isTrueViewMetric) return _.toLower(dragContent);
    if (_.isEqual(dragContent, EXTERNAL_MEASUREMENT)) return 'externalCustomValue';
    return _.camelCase(dragContent);
  };

  const componentContent = getComponentContent();
  // DB saves metric TrueViews as trueviews but we should display it as TrueViews
  const isNumber = !isOperator && !checkIsMetric(componentContent) && !isTrueViewMetric;
  const metricIsWeighted = _.get(formulaMetrics, `${componentContent}.isWeighted`);

  if (_.size(dropItems) - 1 === contentIdx) {
    lastDroppedItemBottom.current = ref.current?.getBoundingClientRect()?.bottom;
  }

  const [{ isOver }, drop] = useDrop(() => ({
    accept: ALLOWED_DRAG_DROP_TYPES,
    hover: (item: DraggableItem, monitor) => {
      const dragIdx = item.index;
      const hoverIdx = contentIdx;
      if (!ref.current) return;

      const hoverRect = ref.current?.getBoundingClientRect();
      const hoverMiddleX = (hoverRect.right - hoverRect.left) / 2;
      const clientOffset = monitor.getClientOffset();
      const targetX = clientOffset.x - hoverRect.left;
      // handles logic for dragging a DraggableComponent inbetween DropzoneComponents
      if (!dropItemIds.has(item.id)) {
        const idx = targetX > hoverMiddleX ? hoverIdx + 1 : hoverIdx;
        setCursorIdx(idx);
        indexToInsertAt.current = idx;
        // eslint-disable-next-line no-param-reassign
        item.index = idx;
        return;
      }
      // Only perform the move when the mouse has crossed half of the adjacent items width
      const draggingLeftCondition = dragIdx < hoverIdx && targetX < hoverMiddleX;
      const draggingRightCondition = dragIdx > hoverIdx && targetX > hoverMiddleX;
      if (draggingLeftCondition || draggingRightCondition) return;

      handleReorder(dragIdx, hoverIdx);
      setCursorIdx(hoverIdx);
      // Note: we're mutating the monitor item here - generally it's better to avoid mutations,
      // but it's good here for the sake of performance to avoid expensive index searches.
      // eslint-disable-next-line no-param-reassign
      item.index = hoverIdx;
    },
    drop: (item: DraggableItem) => {
      // handles dropping a DraggableComponent directly between DropzoneComponent
      // Note: slight difference between this and the drop fn of Dropzone. Dropzone drop handles drop when cursor is
      // perfectly inbetween DropzoneComponents, whereas this handles when you drop while cursor is on a DropzoneComponent
      if (!dropItemIds.has(item.id)) {
        // handles syncing at the correct index. cursorIdx at this time is out of sync
        insertItemAt(item, indexToInsertAt.current);
        setCursorIdx(_.size(dropItemIds));
        const actionObj = {
          action: ActionTypes.add,
          contentId: item.id,
          content: item.content,
          contentIdx: indexToInsertAt.current,
          cursorIdx: indexToInsertAt.current,
        };
        addUndoAction(actionObj);
      }
      return { message: 'dropzonecomponent' };
    },
    collect: (monitor) => ({ isOver: monitor.isOver() }),
  }), [dropItems]);

  const [{ isDragging }, drag] = useDrag(() => ({
    // treat numbers like a metric for drag drop purposes
    type: `dropped-${_.includes(ALL_OPERATORS, dragContent) ? DragDropTypes.operator : DragDropTypes.metric}`,
    item: {
      id: contentId ?? generateRandomUUID(),
      content: dragContent,
      index: contentIdx,
    },
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
    end: (item: DraggableItem, monitor) => {
      const dropRes = monitor.getDropResult();
      const actionObj = {
        action: ActionTypes.swap,
        contentId: item.id,
        content: item.content,
        contentIdx: prevCursorIdx.current,
        cursorIdx,
      };
      // handles edgecase of dragging any DropzoneComponent inside of Dropzone when it hasnt hovered over a DropzoneComponent
      // dropRes will have move key but no message key when dragging a DropzoneComponent around adjacent elements
      if (dropRes && _.isNil(_.get(dropRes, 'message')) && dropItemIds.has(item.id)) {
        const targetIdx = _.size(dropItemIds) - 1;
        handleReorder(item.index, targetIdx);
        addUndoAction({ ...actionObj, cursorIdx: targetIdx });
      }
      // Remove dropItem when dropped outside of dropzone
      // getDropResult will return { message: 'dropzone' } if item was dropped in dropzone
      if (!dropRes && dropItemIds.has(item.id)) {
        addUndoAction({
          ...actionObj,
          action: ActionTypes.delete,
          contentIdx,
          cursorIdx: contentIdx,
        });
        handleRemoveItem(item.id);
      }
      if ((_.isEqual(_.get(dropRes, 'message'), 'dropzonecomponent')) && dropItemIds.has(item.id)) {
        addUndoAction(actionObj);
      }
    },
  }), [dropItems]);

  const getStyle = () => {
    const style = { ...(metricIsWeighted && { height: SPACING[24] }) };
    if (isOperator) {
      return { ...operator, ...style };
    }
    if (isNumber) {
      return { ...number, ...style };
    }
    return { ...metric, ...style };
  };

  const dropzoneComponentWeightedStyle = metricIsWeighted
    ? { ...dropzoneComponent, border: `1px solid ${BLUES.B100_FISH}`, padding: SPACING[4] }
    : dropzoneComponent;

  const getSelectedStyle = () => {
    const style = dropzoneComponentWeightedStyle;
    if (isOperator) {
      return { ...style, border: `2px solid ${TEALS.T700_LOCHINVAR}` };
    }
    if (isNumber) {
      return { ...style, border: `2px solid ${YELLOWS.Y700_METAL}` };
    }
    return { ...style, border: `2px solid ${BLUES.B500_WAVE}` };
  };

  const getHoverStyle = () => {
    const hoverBackgroundColor = isNumber
      ? `linear-gradient(90deg, rgba(255, 214, 102, 0) 0%, ${YELLOWS.Y200_MUSTARD} 46.53%)`
      : `linear-gradient(90deg, rgba(226, 232, 241, 0) 0%, ${METTLES.M200_CATSKILL} 46.53%)`;
    return { ...hoverDeleteBtn, background: hoverBackgroundColor };
  };

  const handleHoverDelete = () => {
    const componentToDelete = dropItems[contentIdx];
    const actionObj = {
      action: ActionTypes.delete,
      contentId: componentToDelete.id,
      content: componentToDelete.content,
      contentIdx,
      cursorIdx,
    };
    const metricToDelete = _.isEqual(componentToDelete.content, EXTERNAL_MEASUREMENT) ? 'externalCustomValue' : _.camelCase(componentToDelete.content);
    setValue('metricsConfig', _.omit(metricsConfig, metricToDelete));
    handleRemoveItem(componentToDelete.id);
    setSelected(false);
    setCursorIdx(contentIdx);
    addUndoAction(actionObj);
  };

  // track if we were previously dragging something
  const draggedPrev = usePrevious(isDragging);

  useEffect(() => {
    // after finishing a drag this gets hit once more - dragging into dropzone should keep dropzone active
    if (!_.isNil(draggedPrev) && !draggedPrev) {
      setDropzoneActive(isDragging);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDragging]);

  drag(drop(ref));
  const showHoveredDeleteBtn = (hovered && (!isOperator && !isNumber) && !draggingSomething);

  const getDragContent = () => {
    if (_.has(OPERATOR_SVGS, dragContent)) return <Image src={OPERATOR_SVGS[dragContent]} alt={dragContent} />;
    if (isTrueViewMetric) return TRUE_VIEWS;
    return dragContent;
  };

  const dropzoneComponentStyle = (selected && cursorIdx === contentIdx) ? getSelectedStyle() : dropzoneComponentWeightedStyle;

  return (
    <div
      style={{ ...dropzoneComponentStyle, opacity: isDragging ? 0 : 1 }}
      onDragStart={() => setDraggingSomething(true)}
      onDragEnd={() => setDraggingSomething(false)}
      onMouseOver={() => {
        // only set hovered to true when no DropzoneComponents are being dragged
        if (!isDragging && !draggingSomething && !isOver) {
          setHovered(true);
        }
      }}
      onMouseLeave={() => setHovered(false)}
      ref={ref}
    >
      <div style={weightedMetricWrapper}>
        {metricIsWeighted && <div style={weighted}>Weighted</div>}
        <div style={getStyle()}>
          {getDragContent()}
        </div>
      </div>
      {showHoveredDeleteBtn && (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div style={getHoverStyle()} onClick={() => handleHoverDelete()}>
        <Image alt="delete" src={BLACK_CIRCLE_X} />
      </div>
      )}
    </div>
  );
};

export default DropzoneComponent;
