/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash';
import React, { createContext, useContext, useReducer, useEffect } from 'react';
import { Map as ImmutableMap, fromJS } from 'immutable';
import { MetricConfigObj } from 'containers/StrategyWizard/types';
import { AWGDimensions } from 'constantsBase';
import { WeightsSectionState, WeightsSectionProviderProps } from './types';
import {
  updateSelectedMetric,
  updateSelectedDimension,
  updateSessionMetrics,
  updateAwgWeightsModalOpen,
  updateWeightedMetricSelected,
  updateWeightedDimensionSelected,
  openModal,
  closeModal,
  editWeightedMetric,
} from './actions';
import { weightsSectionReducer } from './reducers';
import { useAWGContext } from './AWGProvider';

const WeightsSectionContext = createContext(null);

const WeightsSectionProvider = ({ children, isCampaignOptType }: WeightsSectionProviderProps) => {
  const { formulaMetrics, selectedFormula } = useAWGContext();
  const firstMetric = _.head(_.sortBy(selectedFormula?.metrics));
  const defaultOptTypeDimension = isCampaignOptType ? AWGDimensions.lineItem : AWGDimensions.insertionOrder;
  const defaultDimension = _.get(formulaMetrics, `${firstMetric}.dimension`, defaultOptTypeDimension) as AWGDimensions;

  const INITIAL_STATE = {
    awgWeightsModalOpen: false,
    weightedMetricSelected: null,
    weightedDimensionSelected: null,
    selectedMetric: firstMetric,
    selectedDimension: defaultDimension,
    // used immutable.js to handle nested nature of MetricConfig type
    sessionMetrics: fromJS(formulaMetrics) as unknown as ImmutableMap<string, MetricConfigObj>,
  };
  const [state, dispatch] = useReducer(weightsSectionReducer, INITIAL_STATE);

  const setToDefaultMetric = () => dispatch(updateSelectedMetric(firstMetric));

  const handleOpenModal = () => {
    if (!_.isEmpty(selectedFormula.metrics)) {
      dispatch(openModal());
    }
  };

  const handleModalClose = () => {
    dispatch(closeModal(fromJS(formulaMetrics) as unknown as ImmutableMap<string, MetricConfigObj>, defaultDimension as AWGDimensions));
    setToDefaultMetric();
  };

  const handleEditWeightedMetric = (metric: MetricConfigObj) => dispatch(editWeightedMetric(metric));

  useEffect(() => {
    if (!(state.sessionMetrics as WeightsSectionState['sessionMetrics']).size) {
      updateSelectedDimension(defaultOptTypeDimension);
    }
  }, [state.sessionMetrics]);

  useEffect(() => {
    dispatch(updateSessionMetrics(fromJS(formulaMetrics) as unknown as ImmutableMap<string, MetricConfigObj>));
    // after changing formulas, always sets selectedMetric to be first lexographically
    // sync sessionMetrics after deleting Weighted Metric
    setToDefaultMetric();
    dispatch(updateSelectedDimension(formulaMetrics[firstMetric]?.dimension));
  }, [formulaMetrics]);

  useEffect(() => {
    // opening modal via edit icon click for Weighted Metrics
    const { weightedMetricSelected, weightedDimensionSelected } = state;
    if (weightedMetricSelected && weightedDimensionSelected) {
      dispatch(updateSelectedMetric(weightedMetricSelected as string));
      dispatch(updateSelectedDimension(weightedDimensionSelected as AWGDimensions));
    }
  }, [state.weightedMetricSelected, state.weightedDimensionSelected]);

  const actions = {
    setSelectedMetric: (selectedMetric: string) => dispatch(updateSelectedMetric(selectedMetric)),
    setSelectedDimension: (selectedDimension: AWGDimensions) => dispatch(updateSelectedDimension(selectedDimension)),
    setSessionMetrics: (sessionMetrics: ImmutableMap<string, MetricConfigObj>) => dispatch(updateSessionMetrics(sessionMetrics)),
    setAwgWeightsModalOpen: (open: boolean) => dispatch(updateAwgWeightsModalOpen(open)),
    setWeightedMetricSelected: (metric: string) => dispatch(updateWeightedMetricSelected(metric)),
    setWeightedDimensionSelected: (dimension: AWGDimensions) => dispatch(updateWeightedDimensionSelected(dimension)),
    // custom fns
    setToDefaultMetric: () => setToDefaultMetric(),
    handleOpenModal: () => handleOpenModal(),
    handleModalClose: () => handleModalClose(),
    handleEditWeightedMetric: (metric: MetricConfigObj) => handleEditWeightedMetric(metric),
  };

  // allow destructuring to get state, actions, and custom constants from custom context hook
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const values = {
    ...state,
    ...actions,
    defaultOptTypeDimension,
  };

  return (
    <WeightsSectionContext.Provider value={values}>
      {children}
    </WeightsSectionContext.Provider>
  );
};

export const useWeightsSectionContext = () => {
  const context = useContext(WeightsSectionContext);
  if (!context) {
    throw new Error('useWeightsSectionContext must be used within a WeightsSectionProvider');
  }
  return context;
};

export default WeightsSectionProvider;
