import _ from 'lodash';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Input } from 'buildingBlocks';
import { REVENUE_TYPES_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import { RevenueTypeOutcomes, RevenueEditForm, RevenueBulkUpdateTypes } from 'containers/StrategyWizard/types';
import { getBudgetKeyWithPrefix, revenueObjBulkUpdate, revenueObjUpdate } from 'containers/StrategyWizard/steps/GoalSelection/utils';
import { RevTypeOption, REVENUE_SEARCH_ICON } from 'containers/StrategyWizard/steps/GoalSelection/constants';
import { useGoalSectionContext } from 'containers/StrategyWizard/steps/GoalSelection/contexts/GoalSectionProvider';
import OutcomesTable from './RevenueOutcomesTable';
import BulkUpdate from './BulkUpdate';
import BulkEdit from './BulkEdit';

type RevenueOutcomesProps = {
  outcomesToDisplay: RevenueTypeOutcomes
  setOutcomesToDisplay: Dispatch<SetStateAction<RevenueTypeOutcomes>>
  revenueTypeOptions: Array<RevTypeOption>
  flightCount: number
};

const RevenueOutcomes = (props: RevenueOutcomesProps) => {
  const { outcomesToDisplay, setOutcomesToDisplay, revenueTypeOptions, flightCount } = props;
  const { revTypeSearchStr, setRevTypeSearchStr } = useGoalSectionContext();
  const [showBulkUpdate, setShowBulkUpdate] = useState<boolean>(false);
  const [showBulkEdit, setShowBulkEdit] = useState<boolean>(false);
  const [selectAllChecked, setSelectAllChecked] = useState<boolean>(false);
  const [selectedObjects, setSelectedObjs] = useState<Array<string>>([]);
  const { setValue, clearErrors } = useFormContext();
  const initStatebulkEditValues = { outcome: null, value: null };
  const [bulkEditValues, setBulkEditValues] = useState<RevenueEditForm>(initStatebulkEditValues);

  const resetRevOptions = () => {
    setSelectAllChecked(false);
    setShowBulkUpdate(false);
    setShowBulkEdit(false);
    setBulkEditValues(initStatebulkEditValues);
    setSelectedObjs([]);
  };

  useEffect(() => {
    if (!showBulkUpdate && !showBulkEdit && !selectAllChecked) {
      resetRevOptions();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showBulkUpdate, showBulkEdit, selectAllChecked]);

  useEffect(() => {
    setSelectAllChecked(_.isEqual(_.size(selectedObjects), flightCount));
    if (!_.size(selectedObjects)) setShowBulkEdit(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedObjects]);

  const onSearchChange = (searchVal: string) => {
    setRevTypeSearchStr(searchVal);
  };

  const updateOutcomeObjects = (updateType: string) => {
    clearErrors('budget');
    if (!selectedObjects || !bulkEditValues) {
      return;
    }
    _.forEach(selectedObjects, (extId) => {
      const budgetKey = getBudgetKeyWithPrefix(extId);
      if (updateType === RevenueBulkUpdateTypes.reset) {
        setValue(`budget[${budgetKey}].outcome`, null);
        setValue(`budget[${budgetKey}].revenueValue`, null);
      } else {
        const { outcome, value } = bulkEditValues;
        if (outcome) {
          setValue(`budget[${budgetKey}].outcome`, outcome);
        }
        if (value) {
          setValue(`budget[${budgetKey}].revenueValue`, value);
        }
      }
    });
    const resultOutcomes = revenueObjBulkUpdate(updateType, selectedObjects, outcomesToDisplay, bulkEditValues);
    setOutcomesToDisplay(resultOutcomes);
  };

  const updateSingleOutcome = (extId, value) => {
    const resultOutcomes = revenueObjUpdate(extId, value, outcomesToDisplay, clearErrors);
    setOutcomesToDisplay(resultOutcomes);
  };

  return (
    <>
      {showBulkUpdate && !showBulkEdit
        && (
          <BulkUpdate
            setShowBulkUpdate={setShowBulkUpdate}
            setShowBulkEdit={setShowBulkEdit}
            updateOutcomeObjects={updateOutcomeObjects}
            resetRevOptions={resetRevOptions}
          />
        )}
      {showBulkEdit && !showBulkUpdate
        && (
          <BulkEdit
            setShowBulkUpdate={setShowBulkUpdate}
            setShowBulkEdit={setShowBulkEdit}
            bulkEditValues={bulkEditValues}
            setBulkEditValues={setBulkEditValues}
            updateOutcomeObjects={updateOutcomeObjects}
            revenueTypeOptions={revenueTypeOptions}
            resetRevOptions={resetRevOptions}
          />
        )}
      {!showBulkUpdate && !showBulkEdit
        && (
          <Input
            icon={<img src={REVENUE_SEARCH_ICON} alt="search-icon" />}
            placeholder="Search by IO Name / ID"
            onChange={(event) => onSearchChange(event.target.value)}
            value={revTypeSearchStr}
            style={REVENUE_TYPES_STYLES.searchBarStyle}
            className="revenueTypeSearch"
          />
        )}
      <OutcomesTable
        selectAllChecked={selectAllChecked}
        setSelectAllChecked={setSelectAllChecked}
        revOutcomes={outcomesToDisplay}
        setShowBulkUpdate={setShowBulkUpdate}
        selectedObjects={selectedObjects}
        setSelectedObjs={setSelectedObjs}
        updateSingleOutcome={updateSingleOutcome}
        revenueTypeOptions={revenueTypeOptions}
        showBulkEdit={showBulkEdit}
      />
    </>
  );
};

export default RevenueOutcomes;
