import { CSSProperties } from 'react';
import { FONT_FAMILY } from 'constantsBase';
import BAStyle from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/style';
import { COPILOT_COLORS, COPILOT_LAYOUT, COPILOT_TYPOGRAPHY } from 'globalStyles';

const {
  NEW_DESIGN_SYSTEM: { BLUES, YELLOWS, NEUTRALS, METTLES, REDS, GREENS, TEALS, DSP },
} = COPILOT_COLORS;

const { HEADING, BODY, DESCRIPTION } = COPILOT_TYPOGRAPHY;

const { SPACING } = COPILOT_LAYOUT;

export const GOAL_SECTION_STYLES: { [key: string]: CSSProperties } = {
  container: {
    padding: SPACING[24],
    position: 'relative',
  },
  editGoal: {
    ...HEADING.H6,
    color: BLUES.B500_WAVE,
    cursor: 'pointer',
    position: 'absolute',
    top: SPACING[8],
    right: SPACING[24],
  },
  header: {
    ...HEADING.H4,
    color: NEUTRALS.N800_MATTER,
    marginBottom: SPACING[4],
  },
  subHeader: {
    ...BODY.SMALL,
    color: NEUTRALS.N600_ABBEY,
    marginBottom: 0,
  },
  cardGroup: {
    marginTop: SPACING[16],
  },
  card: {
    padding: SPACING[16],
    minHeight: 2 * SPACING[64] + SPACING[24],
    transition: 'background-color 200ms, box-shadow 200ms',
  },
  selectedCard: {
    padding: SPACING[16],
    border: `${BLUES.B500_WAVE} 2px solid`,
    borderRadius: SPACING[4],
    boxShadow: 'none',
    backgroundColor: METTLES.M50_FLASH,
    height: 'auto',
    transform: 'none',
  },
  hoveredCard: {
    border: `${NEUTRALS.N200_LINEN} 1 px solid`,
    borderRadius: SPACING[4],
    boxShadow: 'none',
    backgroundColor: NEUTRALS.N100_CLOUD,
    transition: 'none',
    transform: 'none',
  },
  cardContent: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    color: NEUTRALS.N800_MATTER,
    fontSize: 16,
    fontWeight: 400,
    position: 'relative',
    width: '100%',
    height: '48px',
  },
  icon: {
    marginRight: SPACING[8],
  },
  newText: {
    ...HEADING.H6,
    color: BLUES.B300_SKY,
  },
  cardDescription: {
    color: NEUTRALS.N600_ABBEY,
    fontSize: 12,
    marginTop: SPACING[8],
  },
  cardGoalSuccessEvent: {
    borderStyle: 'solid',
    borderRadius: SPACING[4],
    borderWidth: '2px',
    padding: `0px ${SPACING[4]}px`,
    fontSize: 12,
    lineHeight: '20px',
    fontWeight: 500,
    height: '24px',
  },
  searchContainer: {
    position: 'absolute',
    right: SPACING[24],
    top: SPACING[24],
    display: 'flex',
    alignItems: 'center',
  },
  clearSearch: {
    color: BLUES.B500_WAVE,
    cursor: 'pointer',
    textAlign: 'right',
    marginTop: SPACING[4],
    marginLeft: SPACING[4],
  },
  noVisibleGoals: {
    margin: `${SPACING[48]}px 0px ${SPACING[64]}px 0px`,
    textAlign: 'center',
    color: NEUTRALS.N800_MATTER,
  },
  searchAgain: {
    color: BLUES.B500_WAVE,
    cursor: 'pointer',
  },
  buttonGroup: {
    backgroundColor: NEUTRALS.N200_LINEN,
    padding: SPACING[4],
    borderRadius: 40,
    marginTop: SPACING[24],
    marginRight: SPACING[32],
  },
  button: {
    borderRadius: 32,
    textTransform: 'initial',
    backgroundColor: 'transparent',
    border: 'none',
    boxShadow: 'none',
    fontWeight: 500,
    paddingRight: SPACING[32],
    paddingLeft: SPACING[32],
  },
  activeButton: {
    backgroundColor: BLUES.B500_WAVE,
    color: NEUTRALS.N0_WHITE,
  },
  successEventButton: {
    fontSize: 12,
    lineHeight: '20px',
    color: NEUTRALS.N400_GRANITE,
    border: `2px solid ${NEUTRALS.N400_GRANITE}`,
    backgroundColor: 'white',
    textTransform: 'initial',
    borderRadius: SPACING[4],
    marginRight: SPACING[16],
    fontWeight: 500,
    padding: '0px 10.5px',
  },
  activeGoalSuccessEventButton: {
    color: 'white',
    border: 'none',
    fontSize: 12,
    backgroundColor: NEUTRALS.N400_GRANITE,
  },
  confirmButton: {
    ...COPILOT_TYPOGRAPHY.HEADING.H5,
    backgroundColor: 'white',
    color: BLUES.B500_WAVE,
    border: `1px solid ${BLUES.B500_WAVE}`,
    borderRadius: SPACING[4],
    textTransform: 'capitalize',
    margin: '16px 0px 0px 0px',
    padding: '8px 16px',
    width: '100%',
  },
  goalTarget: {
    marginTop: SPACING[16],
    color: NEUTRALS.N600_ABBEY,
    fontSize: 14,
    fontWeight: 500,
    width: '100%',
  },
  loader: {
    display: 'block',
    margin: 'auto',
    marginTop: SPACING[24],
  },
  newAdvancedGoalsContainer: {
    position: 'relative',
    borderTop: `1px solid ${NEUTRALS.N200_LINEN}`,
    marginTop: SPACING[24],
  },
  descHeader: {
    ...HEADING.H6,
    color: NEUTRALS.N600_ABBEY,
    paddingBottom: SPACING[4],
  },
  errorText: {
    marginTop: SPACING[8],
    color: REDS.R500_WATERMELON,
  },
  pixelPickerSegment: {
    padding: 0,
    alignSelf: 'center',
    textAlign: 'center',
    marginTop: SPACING[16],
    width: '100%',
  },
  pixelPickerContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  pixelPickerText: {
    ...COPILOT_TYPOGRAPHY.BODY.SMALL,
    color: TEALS.T500_KEPPEL,
    marginLeft: SPACING[8],
  },
  errorMessage: {
    color: REDS.R700_VALENTINE,
    fontSize: '14px',
    fontWeight: 400,
    display: 'flex',
    border: `1px solid ${REDS.R700_VALENTINE}`,
    borderRadius: SPACING[4],
    padding: '6px 4px',
    width: '30%',
    background: REDS.R50_VISAGE,
  },
  errorIcon: {
    height: SPACING[16],
    margin: '0px 8px 0px 4px',
  },
};

export const BUDGET_SECTION_STYLES: { [key: string]: CSSProperties } = {
  container: {
    fontFamily: FONT_FAMILY,
    padding: SPACING[24],
    position: 'relative',
  },
  header: {
    ...HEADING.H4,
    color: NEUTRALS.N800_MATTER,
    marginBottom: SPACING[4],
  },
  body: {
    ...BODY.SMALL,
    color: NEUTRALS.N800_MATTER,
  },
  revenueTypeHeader: {
    ...HEADING.H5,
    color: NEUTRALS.N600_ABBEY,
    marginBottom: 0,
  },
  revenueTypeToggle: {
    position: 'absolute',
    top: SPACING[32] + SPACING[64],
    right: SPACING[24],
  },
  revenueTypeForm: {
    marginLeft: SPACING[2],
  },
  revenueTypeFieldOutcome: {
    padding: 0,
    width: 200,
    marginRight: SPACING[24],
  },
  revenueTypeDropdown: {
    border: `1px solid ${NEUTRALS.N200_LINEN}`,
    borderRadius: SPACING[4],
    height: 32,
  },
  revenueTypeFieldValue: {
    padding: 0,
    width: 200,
  },
  revenueTypeInput: {
    borderRadius: 4,
    height: 36,
  },
  revenueTypeLabel: {
    ...HEADING.H6,
    color: NEUTRALS.N600_ABBEY,
    fontWeight: 500,
  },
  tooltipIcon: {
    fontSize: 16,
    color: NEUTRALS.N400_GRANITE,
    marginLeft: SPACING[8],
    marginRight: SPACING[2],
  },
  revenueTypeDisabledTooltipIcon: {
    color: YELLOWS.Y300_ORANGE,
  },
  revenueTypeDisabledPopup: {
    backgroundColor: YELLOWS.Y300_ORANGE,
    color: YELLOWS.Y900_BRONZE,
  },
  budgetIntervalHeader: {
    ...HEADING.H5,
    marginBottom: 0,
    color: NEUTRALS.N600_ABBEY,
  },
  budgetIntervalSubheader: {
    ...BODY.SMALL,
    marginBottom: SPACING[8],
    color: NEUTRALS.N600_ABBEY,
  },
  noBudgetIntervalsMessage: {
    marginTop: SPACING[8],
    color: YELLOWS.Y700_METAL,
  },
  budgetIntervals: {
    marginTop: SPACING[8],
  },
  budgetInterval: {
    marginBottom: SPACING[4],
    marginTop: SPACING[4],
    marginLeft: 0,
  },
  startDate: {
    padding: 0,
  },
  endDate: {
    padding: 0,
  },
  budget: {
    padding: 0,
    marginLeft: SPACING[24],
  },
  budgetIntervalLabel: {
    ...HEADING.H6,
    color: NEUTRALS.N600_ABBEY,
  },
  revenueCalculationHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  showAllBudgetIntervals: {
    ...DESCRIPTION.REGULAR,
    color: BLUES.B500_WAVE,
    cursor: 'pointer',
    marginTop: SPACING[8],
  },
  loader: {
    display: 'block',
    margin: 'auto',
    marginTop: SPACING[12],
  },
  delivery: {
    ...COPILOT_TYPOGRAPHY.BODY.SMALL,
    color: NEUTRALS.N600_ABBEY,
    marginTop: '9px',
  },
};

export const BUDGET_INTERVALS: { [key: string]: CSSProperties } = {
  gridStyle: {
    margin: '16px 0px',
  },
  commonIntervalStyles: {
    display: 'flex',
    fontSize: '14px',
    lineHeight: '22px',
    padding: '8px 0px',
  },
  secondColumnStyles: {
    width: '120px',
  },
  intervalHeaderDiv: {
    display: 'flex',
    marginLeft: SPACING[24],
    gridColumnGap: SPACING[20],
  },
  intervalsDiv: {
    display: 'flex',
    alignItems: 'center',
  },
  intervalIconStyle: {
    marginBottom: '0',
    width: SPACING[24],
  },
  intervalValuesStyle: {
    fontWeight: '400',
    lineHeight: '20px',
  },
  alignRightStyle: {
    textAlign: 'end',
  },
  singleRevenueCalcStyling: {
    width: '200px',
    marginLeft: SPACING[40],
    paddingLeft: SPACING[8],
  },
  baseTable: {
    fontFamily: FONT_FAMILY,
    width: '100%',
    fontSize: 14,
    margin: 0,
    cursor: 'default',
  },
  row: {
    height: '18px',
    minHeight: '18px',
    width: '100%',
    minWidth: '100%',
  },
  flex: {
    fontWeight: 400,
    paddingLeft: SPACING[8],
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: '1px',
    borderStyle: 'solid',
    borderColor: NEUTRALS.N200_LINEN,
  },
  iconStyle: {
    cursor: 'pointer',
    backgroundColor: 'white',
    border: '0px',
    boxShadow: '0px 0px 0px',
  },
  deliveryCurrency: {
    textAlign: 'left',
    ...BODY.SMALL,
    color: NEUTRALS.N600_ABBEY,
    minWidth: '77px',
    maxWidth: '77px',
    width: '166px',
    paddingRight: '25px',
  },
  childFlightsError: {
    width: '97%',
    color: 'red',
  },
  addBudgetIntervalButton: {
    cursor: 'pointer',
    width: '191px',
    height: '32px',
    backgroundColor: NEUTRALS.N0_WHITE,
    border: '1px',
    borderStyle: 'solid',
    borderColor: NEUTRALS.N300_ALTO,
    borderRadius: SPACING[4],
  },
  icon: {
    color: NEUTRALS.N400_GRANITE,
    margin: '0px 12px',
    cursor: 'Pointer',
    fontSize: 18,
  },
  errorMessageCell: {
    width: '460px',
  },
  errorMessageStyle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: 14,
    fontWeight: 400,
    textAlign: 'left',
    height: '37px',
    lineHeight: '12px',
    width: '460px',
  },
  dateActiveStyle: {
    color: NEUTRALS.N800_MATTER,
    backgroundColor: NEUTRALS.N0_WHITE,
  },
  dateInactiveStyle: {
    color: NEUTRALS.N300_ALTO,
    backgroundColor: NEUTRALS.N150_GALLERY,
    cursor: 'default',
  },
  greaterThanDeliveryError: {
    color: YELLOWS.Y700_METAL,
    backgroundColor: YELLOWS.Y50_DAWN,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: SPACING[4],
  },
  lessThanDeliveryError: {
    color: REDS.R700_VALENTINE,
    backgroundColor: REDS.R50_VISAGE,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: SPACING[4],
  },
  budgetIntervalOverlapError: {
    ...BODY.SMALL,
    color: REDS.R500_WATERMELON,
  },
  noIntervalsMessage: {
    ...BODY.MEDIUM,
    fontWeight: 300,
    color: YELLOWS.Y700_METAL,
    lineHeight: '24px',
    margin: '8px 0px 8px 0px',
    display: 'flex',
    alignItems: 'center',
  },
  noIntervalsIcon: {
    margin: '4px 8px 0px 0px',
    height: SPACING[16],
  },
  addIntervalPlus: {
    ...BODY.MEDIUM,
    color: NEUTRALS.N800_MATTER,
    fontWeight: 300,
    fontSize: 24,
    position: 'relative',
    left: '-4px',
    top: '-1px',
  },
  addIntervalText: {
    ...HEADING.H6,
    color: NEUTRALS.N800_MATTER,
    fontFamily: FONT_FAMILY,
    fontWeight: 500,
    position: 'relative',
    left: SPACING[2],
    top: '-2px',
  },
  showIntervalsContainer: {
    marginTop: SPACING[8],
    cursor: 'pointer',
  },
  showIntervalsText: {
    ...DESCRIPTION.REGULAR,
    color: BLUES.B500_WAVE,
  },
  showIntervalsIcon: {
    ...DESCRIPTION.REGULAR,
    color: BLUES.B500_WAVE,
    marginLeft: SPACING[4],
  },
  exclamationIcon: {
    width: SPACING[16],
    height: SPACING[16],
    margin: '0px 8px',
  },
  errorSpan: {
    color: REDS.R500_WATERMELON,
    fontWeight: 400,
    fontSize: 12,
    padding: 0,
    width: '200px',
  },
  disabledStyle: {
    color: NEUTRALS.N400_GRANITE,
    borderColor: NEUTRALS.N400_GRANITE,
    cursor: 'default',
  },
  budgetIntervalListStyle: {
    width: '28px',
  },
  headerRow: {
    padding: '0px 0px 8px 0px',
  },
  budgetIntervalRow: {
    padding: '8px 0px',
    display: 'flex',
    alignItems: 'center',
  },
  dateHeader: {
    width: '150px',
  },
  budgetHeader: {
    marginLeft: SPACING[16],
    width: '170px',
  },
  deliveryHeader: {
    marginLeft: SPACING[16],
    width: '135px',
  },
  revenueCalculationHeader: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: SPACING[16],
    width: '200px',
  },
  budgetCellStyle: {
    marginLeft: SPACING[16],
    width: '170px',
    padding: 0,
  },
  dateCellStyle: {
    width: '154px',
    padding: 0,
  },
  currencyCellStyle: {
    marginLeft: SPACING[16],
    width: '135px',
    textAlign: 'end',
  },
  revenueCalculationCellStyle: {
    marginLeft: '26px',
    width: '200px',
    padding: 0,
  },
  headerWidth: {
    width: '170px',
  },
  activeBullet: {
    fontSize: '9px',
    color: GREENS.G500_LIMA,
  },
  inactiveBullet: {
    fontSize: '9px',
    color: NEUTRALS.N300_ALTO,
  },
  futureIntervalTextStyle: {
    textAlign: 'start',
    paddingLeft: SPACING[2],
  },
};

export const CYOD_MODAL_STYLES: { [key: string]: CSSProperties } = {
  description: {
    color: NEUTRALS.N600_ABBEY,
    marginBottom: SPACING[16],
  },
  noMargin: {
    margin: 0,
  },
  noPadding: {
    padding: 0,
  },
  btnRow: {
    marginTop: SPACING[32],
    padding: 0,
  },
  goalTypes: {
    paddingTop: 0,
    paddingBottom: SPACING[16],
  },
  optLvlLabel: {
    ...HEADING.H5,
    marginBottom: SPACING[4],
  },
  baseCardStyles: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 132,
    border: `1px solid ${NEUTRALS.N200_LINEN}`,
    borderRadius: SPACING[4],
  },
  selectedCardStyles: {
    backgroundColor: METTLES.M50_FLASH,
    border: `2px ${BLUES.B500_WAVE}`,
  },
  radio: {
    marginRight: SPACING[8],
    paddingTop: '1.5px',
  },
  boldedText: {
    fontWeight: 700,
  },
  radioText: {
    ...HEADING.H5,
    display: 'flex',
    marginBottom: SPACING[8],
    color: NEUTRALS.N800_MATTER,
  },
  selectedRadioText: {
    color: BLUES.B500_WAVE,
  },
  goalDescription: {
    ...BODY.SMALL,
    color: NEUTRALS.N400_GRANITE,
  },
  goalDescriptionText: {
    margin: '8px 0px',
  },
  goalShortText: {
    fontWeight: 500,
    marginTop: SPACING[8],
  },
  targetRow: {
    padding: '0px 0px 16px',
    display: 'table-column',
  },
  targetLabel: {
    ...HEADING.H6,
    color: NEUTRALS.N600_ABBEY,
    marginBottom: SPACING[4],
  },
  targetInput: {
    width: 300,
    fontSize: 14,
  },
  messageContainer: {
    display: 'flex',
    padding: SPACING[16],
    backgroundColor: NEUTRALS.N100_CLOUD,
    border: `1px solid ${NEUTRALS.N200_LINEN}`,
    borderRadius: SPACING[4],
    fontSize: 14,
  },
  infoIcon: {
    marginTop: '1.5px',
    paddingRight: SPACING[8],
  },
  submitBtn: {
    ...HEADING.H6,
    textTransform: 'capitalize',
    padding: '5px 27px',
    borderRadius: SPACING[4],
    fontWeight: 500,
    float: 'right',
  },
  errorLabel: {
    backgroundColor: '#fff6f6',
    borderColor: '#e0b4b4',
    color: '#9f3a38',
  },
  errorMessage: {
    background: 'transparent',
    padding: '4px 0px 0px',
    color: '#9f3a38',
    border: 'none',
  },
};

export const AWG_WEIGHTS_MODAL_STYLES: { [key: string]: CSSProperties } = {
  errorContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  errorMessage: {
    height: '40px',
    border: 'none',
    backgroundColor: 'transparent',
    justifyContent: 'center',
  },
  modalLeftCard: {
    borderRight: `1px solid ${NEUTRALS.N200_LINEN}`,
    width: '20%',
    overflow: 'auto',
    height: '100%',
  },
  content: {
    padding: '12px 12px 0 12px',
    position: 'relative',
    margin: '12px 0 0 0',
    height: '680px',
  },
  weightInput: {
    width: '100%',
    padding: 0,
  },
  weightText: {
    ...BODY.SMALL,
    color: NEUTRALS.N800_MATTER,
    textOverflow: 'ellipsis',
    wordBreak: 'break-word',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    overflow: 'hidden',
  },
  weightId: {
    borderRight: `1px solid ${NEUTRALS.N200_LINEN}`,
    padding: 0,
  },
  modalRightCard: {
    width: '80%',
  },
  weightRowStyle: {
    display: 'flex',
    alignItems: 'center',
    margin: '4px 0px',
    padding: 0,
  },
  columnStyle: {
    borderRadius: 'none',
    background: 'none',
    padding: 0,
    border: 'none',
    borderRight: `1px solid ${NEUTRALS.N200_LINEN}`,
  },
  cards: {
    padding: SPACING[12],
    borderRadius: SPACING[4],
    display: 'block',
    cursor: 'pointer',
  },
  activeCards: {
    cursor: 'pointer',
    padding: SPACING[12],
    borderRadius: SPACING[4],
    display: 'block',
    backgroundColor: BLUES.B50_SEA,
  },
  weightStatusCard: {
    padding: '4px 8px',
    backgroundColor: BLUES.B500_WAVE,
    borderRadius: SPACING[4],
    marginBottom: SPACING[4],
    color: NEUTRALS.N0_WHITE,
    fontWeight: 600,
    fontSize: 12,
    width: 'fit-content',
  },
  weightCardDesc: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '22px',
  },
  filterCard: {
    padding: '8px 16px',
    backgroundColor: NEUTRALS.N200_LINEN,
    borderRadius: SPACING[4],
    alignItems: 'center',
  },
  filterDesc: {
    ...HEADING.H6,
    color: NEUTRALS.N600_ABBEY,
  },
  weightTableHeader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  listScrollingStyle: {
    overflow: 'auto',
    padding: 0,
    width: '100%',
    height: '526px',
  },
  resetBtnStyle: {
    ...HEADING.H6,
    cursor: 'pointer',
    color: NEUTRALS.N800_MATTER,
    width: 'fit-content',
    padding: '4px 8px',
    textTransform: 'capitalize',
    backgroundColor: NEUTRALS.N0_WHITE,
    border: 'none',
    boxShadow: 'none',
  },
  cancelBtnStyle: {
    ...HEADING.H6,
    padding: '5px 16px',
    borderRadius: SPACING[4],
    background: 'none',
    color: NEUTRALS.N800_MATTER,
    border: `1px solid ${NEUTRALS.N300_ALTO}`,
    height: '32px',
    marginRight: SPACING[24],
    minWidth: '100px',
    textTransform: 'capitalize',
  },
  submitBtnStyle: {
    ...HEADING.H6,
    padding: '5px 16px',
    borderRadius: SPACING[4],
    color: NEUTRALS.N0_WHITE,
    height: '32px',
    minWidth: '100px',
    textTransform: 'capitalize',
  },
  continueBtnStyle: {
    background: REDS.R500_WATERMELON,
  },
  footerStyles: {
    borderTop: `1px solid ${NEUTRALS.N200_LINEN}`,
  },
  weightRow: {
    marginTop: '0px !important',
    height: SPACING[56],
  },
  stickyHeader: {
    position: 'sticky',
    fontWeight: '600',
    fontSize: 12,
    lineHeight: '22px',
    padding: '4px 8px',
    zIndex: 1,
    borderRadius: SPACING[4],
    display: 'flex',
    alignItems: 'center',
    margin: 0,
  },
  amznStyles: {
    background: DSP[4].light,
    color: DSP[4].dark,
  },
  dbmStyles: {
    background: DSP[3].light,
    color: DSP[3].dark,
  },
  ttdStyles: {
    background: DSP[2].light,
    color: DSP[2].dark,
  },
  apnStyles: {
    background: DSP[1].light,
    color: DSP[1].dark,
  },
  alignCenterStyle: {
    alignItems: 'center',
  },
  weightsListMargin: {
    margin: '10 0px !important',
  },
  headerStyle: {
    textTransform: 'capitalize',
    padding: '0 20px 0 0',
  },
  formulaButtons: {
    display: 'grid',
    margin: '2rem 0 0 0',
  },
  undoHovered: {
    backgroundColor: NEUTRALS.N200_LINEN,
    borderRadius: SPACING[4],
  },
  input: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  inputFieldError: {
    border: `1px solid ${REDS.R500_WATERMELON}`,
    borderRadius: SPACING[4],
  },
  weightsList: {
    height: '572px',
    display: 'flex',
    flexDirection: 'column',
  },
  errorMsg: {
    display: 'flex',
    justifyContent: 'flex-end',
    color: REDS.R500_WATERMELON,
  },
};

export const AWG_GOAL_PAGE_STYLES: { [key: string]: CSSProperties } = {
  formulaTextGridStyle: {
    padding: SPACING[24],
    backgroundColor: NEUTRALS.N50_ALABASTER,
    display: 'flex',
    justifyContent: 'center',
  },
  formulFieldStyle: {
    fontWeight: 600,
    padding: '10px',
    display: 'flex',
    alignItems: 'center',
    overflowX: 'auto',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  formStyle: {
    padding: SPACING[24],
  },
  addWeightsButtonStyle: {
    ...HEADING.H6,
    width: 'fit-content',
    height: SPACING[32],
    borderRadius: SPACING[4],
    background: NEUTRALS.N0_WHITE,
    border: `1px solid ${NEUTRALS.N300_ALTO}`,
    color: NEUTRALS.N800_MATTER,
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px',
    marginBottom: SPACING[24],
    textTransform: 'capitalize',
  },
  awgPageButtons: {
    padding: '8px 30px',
    borderRadius: SPACING[4],
  },
  subHeaderStyle: {
    ...BUDGET_SECTION_STYLES.body,
    fontSize: 16,
  },
  gridBorderStyle: {
    border: `1px solid ${NEUTRALS.N200_LINEN}`,
    borderRadius: SPACING[4],
    margin: '0px',
  },
};

export const FORMULA_SECTION_STYLES: { [key: string]: CSSProperties } = {
  grid: {
    border: `1px solid ${NEUTRALS.N200_LINEN}`,
    borderRadius: SPACING[4],
    margin: 0,
    backgroundColor: NEUTRALS.N50_ALABASTER,
  },
  dropArea: {
    display: 'flex',
    flexDirection: 'row',
    padding: SPACING[16],
  },
  sectionHeaderContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: SPACING[4],
    gap: SPACING[4],
  },
  sectionHeader: {
    ...HEADING.H6,
    color: NEUTRALS.N600_ABBEY,
  },
  tooltip: {
    ...BAStyle.toolTipStyle,
    fontSize: SPACING[16],
  },
  operatorContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gap: SPACING[8],
    width: '120px',
    marginTop: SPACING[8],
  },
  operator: {
    height: SPACING[32],
    width: SPACING[24],
    borderRadius: SPACING[4],
    padding: SPACING[8],
    backgroundColor: TEALS.T100_CHARLOTTE,
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: TEALS.T900_DIANNE,
    cursor: 'pointer',
  },
  number: {
    ...HEADING.H6,
    height: SPACING[32],
    minWidth: SPACING[24],
    borderRadius: SPACING[4],
    padding: SPACING[8],
    backgroundColor: METTLES.M100_HAZE,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: METTLES.M900_TROUT,
    cursor: 'pointer',
  },
  verticalDivider: {
    borderRight: `3px solid ${NEUTRALS.N200_LINEN}`,
    padding: 0,
    maxWidth: '136px',
  },
  metricsColumn: {
    padding: 0,
    marginLeft: SPACING[16],
  },
  metricsContainer: {
    height: '72px',
    display: 'flex',
    flexWrap: 'wrap',
    gap: SPACING[8],
    marginTop: SPACING[8],
  },
  customInput: {
    ...HEADING.H6,
    textAlign: 'center',
    height: SPACING[32],
    backgroundColor: METTLES.M100_HAZE,
    borderRadius: SPACING[4],
    paddingBottom: '1px !important',
    color: METTLES.M900_TROUT,
    display: 'flex',
  },
  metric: {
    ...HEADING.H6,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '46px',
    maxWidth: '250px',
    height: SPACING[32],
    backgroundColor: METTLES.M100_HAZE,
    borderRadius: SPACING[4],
    padding: SPACING[8],
    color: METTLES.M900_TROUT,
    cursor: 'pointer',
  },
  metricGuide: {
    ...BODY.SMALL,
    display: 'flex',
    color: NEUTRALS.N400_GRANITE,
    gap: SPACING[4],
    marginTop: SPACING[8],
  },
  flexSpaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  dropzone: {
    height: '177px',
    width: '100%',
    borderRadius: SPACING[4],
    border: `1px solid ${NEUTRALS.N200_LINEN}`,
    backgroundColor: NEUTRALS.N0_WHITE,
    padding: SPACING[16],
    position: 'relative',
    outline: 'none',
  },
  dropzoneComponent: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    borderRadius: SPACING[4],
    backgroundColor: NEUTRALS.N0_WHITE,
    cursor: 'pointer',
  },
  hoverDeleteBtn: {
    position: 'absolute',
    padding: '0 4px 0 16px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
  },
  grabFormulaContainer: {
    color: BLUES.B500_WAVE,
    display: 'flex',
    alignItems: 'center',
    gap: SPACING[4],
    cursor: 'pointer',
  },
  grabFormulaText: {
    ...BODY.SMALL,
  },
  dropzoneAreaContainer: {
    height: '129px',
    width: '100%',
    overflowY: 'auto',
  },
  dropzoneArea: {
    height: 'auto',
    minHeight: SPACING[24],
    gap: SPACING[4],
    display: 'flex',
    flexWrap: 'wrap',
  },
  dropzoneComponentContainer: {
    display: 'flex',
    gap: SPACING[4],
  },
  dropzoneImgContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: SPACING[16],
    height: '129px',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  dropzoneImg: {
    width: '187px',
    height: '48px',
  },
  dropzoneImgText: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '14px',
    color: NEUTRALS.N400_GRANITE,
  },
  dropAreaInfoSection: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: SPACING[4],
    maxHeight: SPACING[20],
  },
  dropzoneErrorStyle: {
    background: 'transparent',
    border: 'none',
    padding: 0,
  },
  characterInfoSection: {
    display: 'flex',
    gap: SPACING[4],
  },
  characterText: {
    ...DESCRIPTION.REGULAR,
    margin: 0,
    color: NEUTRALS.N600_ABBEY,
  },
  characterCountStyle: {
    ...DESCRIPTION.BOLD,
    color: NEUTRALS.N600_ABBEY,
  },
  characterCountErrorStyle: {
    ...DESCRIPTION.BOLD,
    color: REDS.R700_VALENTINE,
  },
  weightStyles: {
    padding: '4px 9px',
    backgroundColor: BLUES.B500_WAVE,
    color: NEUTRALS.N0_WHITE,
    borderRadius: SPACING[4],
    fontSize: 15,
    textTransform: 'capitalize',
    fontWeight: 500,
  },
  weightedMetricWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: SPACING[4],
    backgroundColor: NEUTRALS.N0_WHITE,
    borderRadius: SPACING[4],
  },
  weighted: {
    ...HEADING.H6,
    backgroundColor: BLUES.B500_WAVE,
    padding: '0 8px',
    borderRadius: SPACING[4],
    width: '80px',
    color: NEUTRALS.N0_WHITE,
  },
  unWeightedStyle: {
    borderRadius: SPACING[4],
    backgroundColor: METTLES.M100_HAZE,
    color: METTLES.M900_TROUT,
    padding: '4px 9px',
    fontSize: 15,
    textTransform: 'capitalize',
    whiteSpace: 'nowrap',
  },
  weightedMetricStyle: {
    borderRadius: SPACING[4],
    backgroundColor: METTLES.M100_HAZE,
    color: METTLES.M900_TROUT,
    padding: '4px 8px',
    fontSize: 15,
    textTransform: 'capitalize',
    width: 'max-content',
  },
  weightedBorderStyle: {
    border: `1px solid ${BLUES.B100_FISH}`,
    padding: SPACING[4],
    borderRadius: SPACING[4],
    display: 'flex',
    gap: SPACING[4],
    whiteSpace: 'nowrap',
  },
  styleFormula: {
    padding: SPACING[4],
    fontWeight: 600,
    fontSize: 16,
    whiteSpace: 'nowrap',
  },
  operatorstyle: {
    fontWeight: 600,
    fontSize: 16,
    whiteSpace: 'nowrap',
  },
  operatorImageStyle: {
    display: 'inline',
    marginBottom: SPACING[2],
    padding: SPACING[2],
  },
  header: {
    ...HEADING.H4,
    color: NEUTRALS.N800_MATTER,
    margin: 0,
  },
  cursorStyle: {
    cursor: 'pointer',
  },
  dropzoneActionsContainer: {
    display: 'flex',
    gap: '18px',
  },
  grabFormulaDiv: {
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  grabFormulaStyle: {
    display: 'flex',
    color: BLUES.B500_WAVE,
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: 'normal',
  },
  buttonGroup: {
    backgroundColor: NEUTRALS.N200_LINEN,
    padding: SPACING[4],
    borderRadius: 40,
    marginLeft: SPACING[8],
    width: '290px',
  },
  filterLabelStyle: {
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '20px',
  },
  button: {
    borderRadius: 32,
    textTransform: 'initial',
    backgroundColor: 'transparent',
    border: 'none',
    boxShadow: 'none',
    fontWeight: 500,
    paddingRight: SPACING[32],
    paddingLeft: SPACING[32],
  },
  activeButton: {
    backgroundColor: NEUTRALS.N0_WHITE,
    color: BLUES.B500_WAVE,
  },
  grabFormulaModalContent: {
    padding: SPACING[24],
    position: 'relative',
    height: '680px',
  },
  subHeaderContainer: {
    display: 'flex',
    gap: SPACING[4],
  },
  goalCardDiv: {
    border: '1px solid #EBEAEB',
    padding: 0,
    margin: '8px 0',
    height: '110px',
  },
  goalDataDiv: {
    padding: SPACING[16],
    background: NEUTRALS.N50_ALABASTER,
    borderRight: '1px solid #EBEAEB',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  goalStratDiv: {
    padding: SPACING[16],
    display: 'flex',
    alignItems: 'center',
  },
  paginationStyle: {
    justifyContent: 'center',
    marginTop: SPACING[8],
  },
  flexStyle: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    gap: SPACING[4],
  },
  strategyGoalStyle: {
    display: 'flex',
    flexDirection: 'column',
  },
  stratGoalDetails: {
    fontSize: SPACING[12],
    lineHeight: '20px',
    fontWeight: 400,
    display: 'flex',
    gap: SPACING[16],
  },
  brandColor: {
    color: TEALS.T500_KEPPEL,
  },
  equationStyle: {
    overflow: 'hidden',
    wordBreak: 'break-word',
  },
  selectButtonStyles: {
    textAlign: 'right',
  },
  formulaCancel: {
    boxShadow: 'none',
    border: 'unset',
  },
  goalCardLongText: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    gap: SPACING[8],
  },
  stratGoalDataDiv: {
    width: '105%',
  },
  dimmerStyle: {
    height: '590px',
    marginTop: '90px',
    opacity: '0.6',
  },
  paginationDivStyle: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    position: 'absolute',
    bottom: 0,
  },
  goalDataDivStyle: {
    position: 'relative',
    height: '570px',
    width: '100%',
  },
  noGoalsStyle: {
    height: '80%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  noPointerEvents: {
    pointerEvents: 'none',
  },
  formulaFiltersDiv: {
    padding: '16px 0',
  },
  textAlignRight: {
    textAlign: 'right',
  },
  confirmationSpan: {
    width: '340px',
  },
  searchBarWidth: {
    width: '70%',
  },
  iconPaddingStyle: {
    paddingRight: SPACING[4],
  },
  strategyNameStyles: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    wordBreak: 'break-all',
    cursor: 'pointer',
  },
  popupStyles: {
    width: '300px',
    wordBreak: 'break-all',
  },
};

export const NAV_BUTTONS_STYLE = { float: 'right', marginTop: SPACING[16] } as CSSProperties;

export const REVENUE_TYPES_STYLES: { [key: string]: CSSProperties } = {
  leftPadding: {
    paddingLeft: SPACING[4],
  },
  revenueTableContainer: {
    paddingTop: SPACING[24],
  },
  revenueOptions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    marginBottom: SPACING[12],
  },
  marginRight: {
    marginRight: SPACING[8],
  },
  optionNameStyles: {
    ...COPILOT_TYPOGRAPHY.HEADING.H5,
    marginLeft: SPACING[8],
  },
  optionDescStyles: {
    marginLeft: SPACING[24],
    marginTop: SPACING[2],
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
    color: NEUTRALS.N400_GRANITE,
  },
  multipleOutcomesHeaderDiv: {
    display: 'flex',
    marginBottom: SPACING[8],
    alignItems: 'center',
    cursor: 'pointer',
  },
  multipleOutcomesHeader: {
    ...COPILOT_TYPOGRAPHY.HEADING.H6,
    color: NEUTRALS.N600_ABBEY,
    marginTop: '0px',
    marginBottom: '0px',
  },
  searchBarStyle: {
    ...COPILOT_TYPOGRAPHY.BODY.SMALL,
    borderRadius: SPACING[40],
  },
  displayFlex: {
    display: 'flex',
  },
  baseColumnStyles: {
    padding: 0,
    width: 'auto',
    display: 'flex',
  },
  outcomesOptionsContainer: {
    padding: 0,
    margin: SPACING[12],
    width: '100%',
    minHeight: '300px',
    maxHeight: '300px',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  outcomesCheckbox: {
    marginRight: SPACING[8],
  },
  dspHeader: {
    position: 'sticky',
    top: 0,
    display: 'flex',
    fontSize: SPACING[12],
    fontWeight: 600,
    borderRadius: SPACING[4],
    padding: '4px 8px',
    height: 20,
    zIndex: 10,
  },
  dspAccordionIcon: {
    lineHeight: '14px',
    fontSize: 18,
  },
  dspDisplayName: {
    lineHeight: '15px',
  },
  outcomesTableContainer: {
    borderRadius: SPACING[4],
    border: `1px solid ${NEUTRALS.N200_LINEN}`,
    margin: 0,
    marginTop: SPACING[8],
  },
  outcomesTableHeader: {
    padding: '8px 12px',
    backgroundColor: NEUTRALS.N50_ALABASTER,
    fontWeight: 600,
    fontSize: SPACING[12],
    color: NEUTRALS.N800_MATTER,
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${NEUTRALS.N200_LINEN}`,
  },
  stickyHeader: {
    position: 'sticky',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '22px',
    padding: '4px 8px',
    zIndex: 9,
    borderRadius: SPACING[4],
    display: 'flex',
    alignItems: 'center',
  },
  weightRowStyle: {
    display: 'flex',
    alignItems: 'center',
    margin: '4px 0px 4px 0px',
    padding: '4px 0px',
  },
  fullWidth: {
    width: '100%',
  },
  outcomeValueWidth: {
    width: '70%',
  },
  borderRight: {
    borderRight: `1px solid ${NEUTRALS.N200_LINEN}`,
  },
  outcomesRowCheckbox: {
    marginRight: SPACING[8],
  },
  buttonStyle: {
    borderRadius: SPACING[4],
    height: SPACING[32],
    background: NEUTRALS.N0_WHITE,
    fontSize: '14px',
    fontWeight: 600,
    color: NEUTRALS.N800_MATTER,
    textTransform: 'capitalize',
    width: '100px',
    marginRight: SPACING[16],
  },
  bulkEditCommon: {
    width: '128px',
    marginRight: SPACING[16],
    minHeight: SPACING[32],
  },
  bulkEditButtonMargin: {
    marginRight: SPACING[16],
  },
  bulkEditContainer: {
    display: 'flex',
    marginBottom: SPACING[8],
  },
  warningMsgContainer: {
    display: 'flex',
    padding: SPACING[16],
    color: REDS.R700_VALENTINE,
    fontSize: '14px',
    fontWeight: 400,
    background: '#FFF1F0',
    height: SPACING[40],
    marginBottom: SPACING[8],
    alignItems: 'center',
  },
  collapsedText: {
    display: 'flex',
    padding: '4px 8px',
    background: NEUTRALS.N100_CLOUD,
    fontSize: SPACING[12],
    fontWeight: 600,
    marginLeft: SPACING[12],
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 'fit-content',
    borderRadius: '4px',
    color: NEUTRALS.N600_ABBEY,
  },
  dotIcon: {
    fontSize: '.5em',
    color: NEUTRALS.N400_GRANITE,
    margin: '0 8px',
  },
  noMarginTop: {
    marginTop: '0px',
  },
  errorMessageStyle: {
    color: REDS.R500_WATERMELON,
    fontSize: SPACING[12],
    fontWeight: 400,
  },
  errorFieldStyle: {
    background: REDS.R50_VISAGE,
    borderColor: '#e0b4b4',
    color: '#9f3a38',
  },
  errorMessagesDiv: {
    paddingTop: 0,
    paddingBottom: SPACING[4],
  },
};

export const AWG_WEIGHTED_CARDS: { [key: string]: CSSProperties } = {
  card: {
    width: '588px',
    height: '58px',
    borderRadius: SPACING[4],
    border: `1px solid ${NEUTRALS.N200_LINEN}`,
    backgroundColor: NEUTRALS.N50_ALABASTER,
    margin: 0,
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    gap: SPACING[16],
    height: '100%',
  },
  contentInfo: {
    display: 'flex',
    flexDirection: 'column',
    width: '90%',
  },
  contentHeader: {
    ...HEADING.H6,
    width: '100%',
    margin: 0,
  },
  contentDesc: {
    ...DESCRIPTION.REGULAR,
    width: '100%',
  },
  icons: {
    display: 'flex',
    flexDirection: 'row',
    gap: SPACING[24],
    justifyContent: 'center',
    width: '10%',
  },
  hoveredStyle: {
    cursor: 'pointer',
  },
  grid: {
    margin: '8px 0',
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    width: '100%',
    gap: '16px 22px',
  },
};

export const CONFIRMATION_MODAL: { [key: string]: CSSProperties } = {
  modal: {
    width: '575px',
    minHeight: '340px',
    display: 'flex',
    justifyContent: 'center',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: SPACING[24],
    padding: '32px',
  },
  content: {
    width: '100%',
    minHeight: '220px',
    display: 'flex',
    flexDirection: 'row',
    gap: SPACING[16],
  },
  infoCircle: {
    width: SPACING[24],
    height: SPACING[24],
  },
  header: {
    ...HEADING.H4,
    margin: 0,
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: SPACING[16],
    width: '100%',
  },
  subHeaderContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: SPACING[4],
  },
  subheader: {
    display: 'flex',
    flexDirection: 'row',
    gap: SPACING[4],
    color: NEUTRALS.N400_GRANITE,
  },
  subheaderLabel: {
    ...BODY.SMALL,
  },
  subheaderContent: {
    ...HEADING.H5,
    color: NEUTRALS.N800_MATTER,
    margin: 0,
  },
  weightedColor: {
    color: BLUES.B500_WAVE,
  },
  unweightedColor: {
    color: NEUTRALS.N800_MATTER,
  },
  subheaderStyle: {
    fontSize: '14px',
    fontWeight: 400,
    color: NEUTRALS.N600_ABBEY,
  },
  backButtonModalHeight: {
    minHeight: 'fit-content',
  },
  backButtonContentGap: {
    gap: SPACING[16],
  },
  modalButtonStyle: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: SPACING[8],
  },
  confirmModalHeader: {
    marginTop: '-4px',
    paddingLeft: SPACING[16],
  },
  weightsSubHeader: {
    paddingLeft: SPACING[40],
  },
  confirmModalDiv: {
    display: 'flex',
    alignItems: 'flex-start',
  },
};

export const AWG_BANNER: { [key: string]: CSSProperties } = {
  banner: {
    width: '100%',
    height: '74px',
    border: `1px solid ${BLUES.B500_WAVE}`,
    background: BLUES.B50_SEA,
    borderRadius: SPACING[4],
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: SPACING[16],
    marginBottom: SPACING[16],
    color: BLUES.B500_WAVE,
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  bannerText: {
    ...HEADING.H6,
    color: BLUES.B500_WAVE,
  },
  link: {
    cursor: 'pointer',
  },
};

export const GOAL_SETTINGS: { [key: string]: CSSProperties } = {
  labelStyle: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '22px',
  },
  divStyle: {
    display: 'flex',
    margin: '0 20px',
    padding: 0,
    marginTop: SPACING[16],
  },
  columnStyle: {
    marginRight: SPACING[16],
    padding: 0,
  },
  optionNameStyles: {
    ...COPILOT_TYPOGRAPHY.HEADING.H5,
  },
  additionalPadding: {
    paddingBottom: SPACING[32],
  },
  optimizationStyle: {
    width: '49%',
    borderRadius: SPACING[4],
    padding: SPACING[16],
    cursor: 'pointer',
  },
  errorMessageStyle: {
    color: REDS.R700_VALENTINE,
    fontSize: '12px',
    fontWeight: 400,
    marginTop: SPACING[4],
  },
  optDirectionStyle: {
    marginBottom: 0,
  },
};
