import React from 'react';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { Grid } from 'buildingBlocks';
import { AWG_GOAL_PAGE_STYLES, FORMULA_SECTION_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import OperatorsSection from './OperatorsSection';
import MetricsSection from './MetricsSection';
import DropArea from './DropArea';
import FormulaProvider from '../contexts/FormulaProvider';

const { grid, dropArea } = FORMULA_SECTION_STYLES;
const { subHeaderStyle } = AWG_GOAL_PAGE_STYLES;

const SUBHEADER_TEXT = 'Drag and drop Operators and Metrics to create a custom formula. You can also type directly in the Formula Builder.';

const FormulaBuilder = () => (
  <>
    <p style={subHeaderStyle}>{SUBHEADER_TEXT}</p>
    <FormulaProvider>
      <Grid style={grid}>
        <DndProvider backend={HTML5Backend}>
          <Grid.Row style={dropArea}>
            <OperatorsSection />
            <MetricsSection />
          </Grid.Row>
          <Grid.Row>
            <DropArea />
          </Grid.Row>
        </DndProvider>
      </Grid>
    </FormulaProvider>
  </>
);

export default FormulaBuilder;
