import { Map as ImmutableMap } from 'immutable';
import { CustomFormError, FormulaType, MetricConfig, MetricConfigObj } from 'containers/StrategyWizard/types';
import { AWGDimensions } from 'constantsBase';
import { WeightsSectionActionTypes, WeightsSectionAction, ModalContentAction, ModalContentActionTypes, AWGActionTypes, AWGAction, FormulaActionTypes, DraggableItem, FormulaBuilderAction, FormulaAction } from './types';

// Actions for AWGProvider
export const updateSelectedFormula = (selectedFormula: Partial<FormulaType>): AWGAction => ({ type: AWGActionTypes.UPDATE_SELECTED_FORMULA, payload: selectedFormula });
export const updateFormulaMetrics = (formulaMetrics: MetricConfig): AWGAction => ({ type: AWGActionTypes.UPDATE_FORMULA_METRICS, payload: formulaMetrics });

// Actions for WeightsSectionProvider
export const updateSelectedMetric = (selectedMetric: string): WeightsSectionAction => ({ type: WeightsSectionActionTypes.UPDATE_SELECTED_METRIC, payload: selectedMetric });
export const updateSelectedDimension = (selectedDimension: AWGDimensions): WeightsSectionAction => ({ type: WeightsSectionActionTypes.UPDATE_SELECTED_DIMENSION, payload: selectedDimension });
export const updateSessionMetrics = (sessionMetrics: ImmutableMap<string, MetricConfigObj>): WeightsSectionAction => ({ type: WeightsSectionActionTypes.UPDATE_SESSION_METRICS, payload: sessionMetrics });
export const updateAwgWeightsModalOpen = (open: boolean): WeightsSectionAction => ({ type: WeightsSectionActionTypes.UPDATE_AWG_WEIGHTS_MODAL_OPEN, payload: open });
export const updateWeightedMetricSelected = (metric: string): WeightsSectionAction => ({ type: WeightsSectionActionTypes.UPDATE_WEIGHTED_METRIC_SELECTED, payload: metric });
export const updateWeightedDimensionSelected = (dimension: AWGDimensions): WeightsSectionAction => ({ type: WeightsSectionActionTypes.UPDATE_WEIGHTED_DIMENSION_SELECTED, payload: dimension });
export const openModal = (): WeightsSectionAction => ({ type: WeightsSectionActionTypes.OPEN_MODAL });
export const closeModal = (sessionMetrics: ImmutableMap<string, MetricConfigObj>, selectedDimension: AWGDimensions): WeightsSectionAction => ({
  type: WeightsSectionActionTypes.CLOSE_MODAL,
  payload: { sessionMetrics, selectedDimension },
});
export const editWeightedMetric = ({ name, dimension }: MetricConfigObj): WeightsSectionAction => ({
  type: WeightsSectionActionTypes.EDIT_WEIGHTED_METRIC,
  payload: { name, dimension },
});

// Actions for WeightsModalContentProvider
export const updateAttemptSave = (attemptSave: boolean): ModalContentAction => ({ type: ModalContentActionTypes.UPDATE_ATTEMPT_SAVE, payload: attemptSave });
export const updateFormErrors = (formErrors: CustomFormError): ModalContentAction => ({ type: ModalContentActionTypes.UPDATE_FORM_ERRORS, payload: formErrors });

// Actions for FormulaProvider
export const addNewItem = (newDropItem: DraggableItem): FormulaAction => ({ type: FormulaActionTypes.ADD_NEW_ITEM, payload: newDropItem });
export const updateDropItems = (newItemsList: Array<DraggableItem>): FormulaAction => ({ type: FormulaActionTypes.UPDATE_DROP_ITEMS, payload: newItemsList });
export const updateDropzoneActive = (dropzoneActive: boolean): FormulaAction => ({ type: FormulaActionTypes.UPDATE_DROPZONE_ACTIVE, payload: dropzoneActive });
export const updateCursorIdx = (cursorIdx: number): FormulaAction => ({ type: FormulaActionTypes.UPDATE_CURSOR_IDX, payload: cursorIdx });
export const addUndoAction = (action: FormulaBuilderAction): FormulaAction => ({ type: FormulaActionTypes.ADD_UNDO_ACTION, payload: action });
export const addRedoAction = (action: FormulaBuilderAction): FormulaAction => ({ type: FormulaActionTypes.ADD_REDO_ACTION, payload: action });
export const updateUndoActions = (actions: Array<FormulaBuilderAction>): FormulaAction => ({ type: FormulaActionTypes.UPDATE_UNDO_ACTIONS, payload: actions });
export const updateRedoActions = (actions: Array<FormulaBuilderAction>): FormulaAction => ({ type: FormulaActionTypes.UPDATE_REDO_ACTIONS, payload: actions });
export const updateCharacterCount = (charCount: number): FormulaAction => ({ type: FormulaActionTypes.UPDATE_CHARACTER_COUNT, payload: charCount });
