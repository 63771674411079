import _ from 'lodash';
import React, { Dispatch, SetStateAction } from 'react';
import { Button } from 'buildingBlocks';
import { GoalTypeSearchCriteria } from '../constants';
import { GOAL_SECTION_STYLES } from '../styles';

type Props = {
  goalTypeFilter: GoalTypeSearchCriteria
  currentGoalTypeFilter: GoalTypeSearchCriteria
  setGoalTypeFilter: Dispatch<SetStateAction<GoalTypeSearchCriteria>>
};

const GoalTypeFilterButton = ({ goalTypeFilter, currentGoalTypeFilter, setGoalTypeFilter }: Props) => (
  <Button
    onClick={() => setGoalTypeFilter(goalTypeFilter)}
    style={currentGoalTypeFilter === goalTypeFilter ? { ...GOAL_SECTION_STYLES.button, ...GOAL_SECTION_STYLES.activeButton } : GOAL_SECTION_STYLES.button}
  >
    {_.capitalize(goalTypeFilter)}
  </Button>
);

export default GoalTypeFilterButton;
