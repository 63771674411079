import _ from 'lodash';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Button } from 'buildingBlocks';
import { Status } from 'containers/StrategyWizard/types';
import { MODAL_STYLES } from 'containers/StrategyWizard/steps/AttachFlights/styles';
import { ButtonClickHandler, FlightDisplayName, FetchState } from 'utils/types';

type SyncButtonProps = {
  displayName: string
  loading?: boolean
  onClick?: ButtonClickHandler
};

const SyncButton = ({
  loading,
  onClick,
  displayName,
}: SyncButtonProps) => {
  const { getValues } = useFormContext();
  const { advertiser } = getValues();
  const [hovered, setHovered] = useState<boolean>(false);
  const hoveredStyle = { ...MODAL_STYLES.updateButton, ...MODAL_STYLES.updateBtnHover };
  return (
    <Button
      onClick={onClick}
      disabled={_.isNil(advertiser)}
      style={(!hovered || loading) ? MODAL_STYLES.updateButton : hoveredStyle}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {loading ? 'Loading...' : `Refresh ${displayName}`}
    </Button>
  );
};

type Props = {
  onClick: ButtonClickHandler
  status: Status
  flightDisplayName: FlightDisplayName
};

const FlightSyncComponent = ({
  onClick,
  status,
  flightDisplayName: { multiple },
}: Props) => {
  switch (status.kind) {
    case FetchState.loading:
      return <SyncButton displayName={multiple} loading />;
    case FetchState.initial:
    case FetchState.success:
    case FetchState.error:
    default:
      return <SyncButton onClick={onClick} displayName={multiple} />;
  }
};

export default FlightSyncComponent;
