import React from 'react';
import { useFormContext, Controller, useWatch } from 'react-hook-form';
import { Radio } from 'buildingBlocks';
import { OPTIMIZATION_DIRECTION_TEXT } from 'containers/StrategyWizard/steps/GoalSelection/constants';
import { COPILOT_COLORS } from 'globalStyles';
import { REVENUE_TYPES_STYLES, GOAL_SETTINGS } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import { OptimizationDirection } from 'containers/StrategyWizard/types';

const { marginRight, optionDescStyles } = REVENUE_TYPES_STYLES;
const { optionNameStyles, optimizationStyle } = GOAL_SETTINGS;

type OptimizationOptionProps = {
  optDirection: OptimizationDirection
};

const { NEW_DESIGN_SYSTEM: { BLUES, NEUTRALS } } = COPILOT_COLORS;
const ACTIVE_COLOR = BLUES.B500_WAVE;
const INACTIVE_COLOR = NEUTRALS.N800_MATTER;
const INACTIVE_BORDER_COLOR = NEUTRALS.N200_LINEN;

const OptimizationOption = (props: OptimizationOptionProps) => {
  const { optDirection } = props;
  const { control } = useFormContext();
  const selectedOptDirection = useWatch({ name: 'customGoal.optimizationDirection' });
  const isSelected = selectedOptDirection === optDirection;

  const cardBorderStyle = {
    border: `${isSelected ? 2 : 1}px solid ${isSelected ? ACTIVE_COLOR : INACTIVE_BORDER_COLOR}`,
  };

  const revOutcomeNameStyle = {
    color: isSelected ? ACTIVE_COLOR : INACTIVE_COLOR,
  };

  return (
    <Controller
      name="customGoal.optimizationDirection"
      defaultValue=""
      control={control}
      rules={{
        required: 'Select an option for Copilot to Minimize or Maximize the goal value.',
      }}
      render={({ field: { onChange } }) => (
        <div
          style={{ ...optimizationStyle, ...cardBorderStyle }}
          onClick={() => onChange(optDirection)}
          role="button"
          tabIndex={0}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Radio
              name="optimizationDirection"
              className="revenue-radio-btn"
              checked={isSelected}
              style={marginRight}
              value={optDirection}
            />
            <label style={{ ...optionNameStyles, ...revOutcomeNameStyle }}>
              <span>{OPTIMIZATION_DIRECTION_TEXT[optDirection].displayName}</span>
            </label>
          </div>
          <div style={optionDescStyles}>{OPTIMIZATION_DIRECTION_TEXT[optDirection].desc}</div>
        </div>
      )}
    />
  );
};

export default OptimizationOption;
