import _ from 'lodash';
import React, { Dispatch, ReactNode, SetStateAction, useRef, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Button, Modal, Image, Header } from 'buildingBlocks';
import { GOAL_TYPES } from 'constantsBase';
import { ALL_OPERATORS, OPERATOR_SVGS, SAVE_AWG_CONFIG } from 'containers/StrategyWizard/constants';
import { BLACK_INFO_CIRCLE_SRC, GOAL_ICON_SRC, GREY_FORMULA_ICON_SRC, BULLSEYE_SRC, OPTIMIZATION_DIRECTION_TEXT } from 'containers/StrategyWizard/steps/GoalSelection/constants';
import { AWG_WEIGHTS_MODAL_STYLES, CONFIRMATION_MODAL } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import { checkIsNumber, getDropzoneContent } from 'containers/StrategyWizard/utils';
import { useAWGContext } from './contexts/AWGProvider';
import { validateFormula } from './contexts/utils';

const { cancelBtnStyle, submitBtnStyle } = AWG_WEIGHTS_MODAL_STYLES;
const {
  modal, contentContainer, content, infoCircle,
  infoContainer, subHeaderContainer, subheader,
  header, subheaderLabel, subheaderContent,
  weightedColor, unweightedColor,
} = CONFIRMATION_MODAL;

export const getFormulaText = (formulaList, metricConfig) => _.map(formulaList, (item: string, i: number) => {
  const text = _.includes(ALL_OPERATORS, item) || checkIsNumber(item) ? item : getDropzoneContent(item);
  // pad ends of each operator (except parenthesis)
  const formattedText = (item in OPERATOR_SVGS) ? _.pad(text, 3) : text;
  if (!_.isEmpty(_.get(metricConfig, `[${item}].weighting`))) {
    return <span key={i} style={weightedColor}>Weighted {text}</span>;
  }
  return <span key={i} style={unweightedColor}>{formattedText}</span>;
});

type SubHeaderProps = {
  iconSrc: string
  altText: string
  label: string
  children: ReactNode
};

const SubHeader = ({ iconSrc, altText, label, children }: SubHeaderProps) => (
  <div style={subHeaderContainer}>
    <div style={subheader}>
      <Image src={iconSrc} alt={altText} />
      <span style={subheaderLabel}>{label}</span>
    </div>
    {children}
  </div>
);

type ConfirmationModalProps = {
  setShowAwgPage: Dispatch<SetStateAction<boolean>>
};

const ConfirmationModal = ({ setShowAwgPage }: ConfirmationModalProps) => {
  const { selectedFormula, formulaMetrics, isImpactGoal } = useAWGContext();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const validating = useRef<boolean>(false);
  const { setValue, trigger, formState: { errors }, setError } = useFormContext();
  const dispatch = useDispatch();
  const customGoal = useWatch({ name: 'customGoal', defaultValue: selectedFormula });
  const optDirection = OPTIMIZATION_DIRECTION_TEXT[customGoal?.optimizationDirection]?.displayName;

  const handleConfirm = () => {
    const metricConfigToSave = _.some(formulaMetrics, (metric) => !!_.size(metric.weighting))
      ? _.pickBy(formulaMetrics, (metric) => _.size(metric.weighting))
      : {};
    setValue('customGoal', { ...customGoal, name: _.trim(customGoal.name) });
    setValue('metricsConfig', metricConfigToSave);
    if (!isImpactGoal) {
      setValue('goal.type', GOAL_TYPES.awgCreateYourOwn.value);
    }
    const payload = {
      customGoal,
      metricsConfig: metricConfigToSave,
    };
    dispatch({ type: SAVE_AWG_CONFIG, payload });
    setOpenModal(false);
    setShowAwgPage(false);
  };

  return (
    <Modal
      open={openModal && _.isEmpty(errors)}
      style={modal}
      trigger={(
        <Button
          primary
          type="button"
          content="Save"
          style={AWG_WEIGHTS_MODAL_STYLES.submitBtnStyle}
          onClick={() => {
            validating.current = true;
            trigger(['customGoal.name', 'customGoal.value', 'customGoal.optimizationDirection']);
            validateFormula(selectedFormula?.formula, setError, validating);
            if (!validating.current && !_.isEmpty(customGoal.formula) && _.isEmpty(errors)) {
              setOpenModal(true);
            }
          }}
        />
      )}
    >
      <Modal.Content style={contentContainer}>
        <div style={content}>
          <Image src={BLACK_INFO_CIRCLE_SRC} alt="info circle" style={infoCircle} />
          <div style={infoContainer}>
            <Header as="h4" content="Confirm Formula" style={header} />
            <SubHeader
              iconSrc={GOAL_ICON_SRC}
              altText="Goal Name"
              label="Goal Name"
            >
              <Header as="h5" content={customGoal?.name} style={subheaderContent} />
            </SubHeader>
            <SubHeader
              iconSrc={GREY_FORMULA_ICON_SRC}
              altText="Formula"
              label="Formula"
            >
              <div style={subheaderContent}>
                {getFormulaText(customGoal?.formula, formulaMetrics)}
              </div>
            </SubHeader>
            <SubHeader
              iconSrc={BULLSEYE_SRC}
              altText="Optimization Direction"
              label="Optimization Direction"
            >
              <Header as="h5" content={optDirection} style={subheaderContent} />
            </SubHeader>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            type="button"
            content="Edit"
            style={cancelBtnStyle}
            onClick={() => setOpenModal(false)}
          />
          <Button
            type="button"
            primary
            content="Confirm"
            style={submitBtnStyle}
            onClick={handleConfirm}
          />
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default ConfirmationModal;
