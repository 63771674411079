import _ from 'lodash';
import React, { Dispatch, SetStateAction } from 'react';
import { Grid, Header, Image, Popup } from 'buildingBlocks';
import DspIcon from 'components/DspIcon';
import { DSP } from 'constantsBase';
import { ARROW_DOWN_RIGHT_SRC, ARROW_UP_RIGHT_SRC, GOAL_BUILDER_CONFIRMATION_TEXT, GREY_FORMULA_ICON_SRC, GrabFormulaFilters } from 'containers/StrategyWizard/steps/GoalSelection/constants';
import { useAWGContext } from 'containers/StrategyWizard/steps/GoalSelection/GoalSection/AWG/contexts/AWGProvider';
import { useFormulaContext } from 'containers/StrategyWizard/steps/GoalSelection/GoalSection/AWG/contexts/FormulaProvider';
import { CONFIRMATION_MODAL, FORMULA_SECTION_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import { GoalData } from 'containers/StrategyWizard/steps/GoalSelection/types';
import { getGoalIdByName, isCYODGoalTypeValue } from 'containers/StrategyWizard/steps/GoalSelection/utils';
import { OptimizationDirection } from 'containers/StrategyWizard/types';
import { formatGoalFromDbToUi, getTransformedEquation } from 'containers/StrategyWizard/utils';
import GrabFormulaButtons from './GrabFormulaButtons';

type StrategyGoalCardProps = {
  goal: GoalData
  selectedFilter: GrabFormulaFilters
  setOpenModal: Dispatch<SetStateAction<boolean>>
  setSelectedFormulaFromList: Dispatch<SetStateAction<string>>
  selectedFormulaFromList: string
};

type GetGoalDataDivProps = {
  goalName: string
  equation: string
};

type GetStrategySectionProps = {
  strategyName: string
  brandName: string
  memberName: string
  dsp: number
};

type GetGoalLongTextProps = {
  longText: string
  direction: OptimizationDirection
};

const { subheaderContent, subheader } = CONFIRMATION_MODAL;
const { subHeaderContainer, goalDataDiv, goalCardDiv, goalStratDiv, strategyGoalStyle, confirmationSpan, popupStyles,
  stratGoalDetails, brandColor, equationStyle, goalCardLongText, stratGoalDataDiv, strategyNameStyles } = FORMULA_SECTION_STYLES;

const GoalDataDiv = ({ goalName, equation }: GetGoalDataDivProps) => (
  <Grid.Column style={goalDataDiv} width={6}>
    <div style={subHeaderContainer}>
      <Image src={GREY_FORMULA_ICON_SRC} alt="goalName" />
      <Header as="h4" content={goalName} style={{ ...subheaderContent, ...equationStyle }} />
    </div>
    <span style={{ ...subheader, ...equationStyle }}>{equation}</span>
  </Grid.Column>
);

const StrategySection = ({ strategyName, brandName, memberName, dsp }: GetStrategySectionProps) => (
  <Grid.Column width={8} style={strategyGoalStyle}>
    <Popup
      hoverable
      popper={<div className="popup" />}
      trigger={<p style={strategyNameStyles}>{strategyName}</p>}
      content={<p style={popupStyles}>{strategyName}</p>}
      position="left center"
      wide="very"
    />
    <div style={stratGoalDetails}>
      {_.size(brandName) ? <span style={brandColor}>{brandName}</span> : null}
      {dsp !== DSP.MULTIPLE.id && <span>{_.upperCase(memberName)}</span>}
      <DspIcon dspId={dsp} />
    </div>
  </Grid.Column>
);

const GoalLongText = ({ longText, direction }: GetGoalLongTextProps) => (
  <Grid.Column width={10} style={goalCardLongText}>
    <Image src={direction === OptimizationDirection.down ? ARROW_DOWN_RIGHT_SRC : ARROW_UP_RIGHT_SRC} alt="optDirection" />
    <span>{longText}</span>
  </Grid.Column>
);
const Confirmation = ({ selectedFilter }: { selectedFilter: GrabFormulaFilters }) => (
  <Grid.Column width={GrabFormulaFilters.users === selectedFilter ? 8 : 10} style={goalCardLongText}>
    <span style={confirmationSpan}>{GOAL_BUILDER_CONFIRMATION_TEXT}</span>
  </Grid.Column>
);

const StrategyGoalCard = ({ goal, selectedFilter, setOpenModal, setSelectedFormulaFromList, selectedFormulaFromList }: StrategyGoalCardProps) => {
  const { setSelectedFormula } = useAWGContext();
  const { dropzoneRef } = useFormulaContext();
  const { brandName, equation, memberName, strategyName, dsp, direction, longText, displayName, name } = goal;
  const isCyodGoalType = isCYODGoalTypeValue(name);
  const goalName = isCyodGoalType ? _.startCase(name).replace(/\s/g, ' ') : displayName;
  const transformedEquation = getTransformedEquation(equation);
  // generate a unique key to identify the selected equation
  const strategyEquationStr = `${equation}-${strategyName}`;
  const isFormulaSelected = _.isEqual(selectedFormulaFromList, strategyEquationStr);

  const handleContinueClick = async () => {
    let goalObj = { ...goal };
    if (selectedFilter === GrabFormulaFilters.copilot) {
      const goalId = await getGoalIdByName(name);
      goalObj = { ...goalObj, id: goalId };
    }
    const formattedGoal = formatGoalFromDbToUi([goalObj]);
    // only formula and metrics of customGoal are updated
    setSelectedFormula(formattedGoal);
    dropzoneRef.current.focus();
    setOpenModal(false);
  };

  const updateSelectedFormula = (isUpdateFormula: boolean) => {
    setSelectedFormulaFromList(isUpdateFormula ? strategyEquationStr : '');
  };

  return (
    <Grid style={{ margin: '0' }}>
      <Grid.Row style={goalCardDiv}>
        <GoalDataDiv goalName={goalName} equation={transformedEquation} />
        <Grid.Column style={goalStratDiv} width={6}>
          <Grid style={stratGoalDataDiv}>
            <Grid.Row columns={2}>
              {isFormulaSelected && <Confirmation selectedFilter={selectedFilter} />}
              {GrabFormulaFilters.users === selectedFilter && !isFormulaSelected
                && (
                  <StrategySection
                    memberName={memberName}
                    strategyName={strategyName}
                    brandName={brandName}
                    dsp={_.toNumber(dsp)}
                  />
                )}
              {GrabFormulaFilters.copilot === selectedFilter && !isFormulaSelected
                && (
                  <GoalLongText
                    longText={longText}
                    direction={direction}
                  />
                )}

              <GrabFormulaButtons
                isFormulaSelected={isFormulaSelected}
                updateSelectedFormula={updateSelectedFormula}
                handleContinueClick={handleContinueClick}
                selectedFilter={selectedFilter}
              />
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default StrategyGoalCard;
