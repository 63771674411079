import _ from 'lodash';
import React from 'react';
import { useWatch } from 'react-hook-form';
import { Icon } from 'buildingBlocks';
import { OptimizationLevelType } from 'utils/types';
import OptimizationTypeSelection from './OptimizationTypeSelection';
import { OptimizationType, OPTIMIZATION_TYPE_OPTIONS } from '../constants';
import { ATTACH_DSP_OBJECT_STYLES } from '../styles';
import { configuringCrossPlatformStratCheck } from '../utils';

const getBannerDesc = (selectedOptType: OptimizationType, optLevel: OptimizationLevelType) => {
  switch (selectedOptType) {
    case (OptimizationType.campaign):
      return `Copilot will manage budget according to the budget intervals inherited from the ${optLevel.displayName.single} attached to this strategy. Targeting will not be updated on any attached objects.`;
    case (OptimizationType.lineItem):
      return `Copilot will manage the ${optLevel.displayName.multiple} according to the goals configured in this strategy. Targeting and budgets will not be updated on any attached ${optLevel.displayName.multiple}.`;
    default:
      return _.get(OPTIMIZATION_TYPE_OPTIONS, `[${selectedOptType}].bannerDesc`);
  }
};

type OptimizationTypeBannerProps = {
  isCreateMode: boolean
  canAccessCrossPlatform: boolean
  flightLimitReached: boolean
  optTypeModalOpen: boolean
  setOptTypeModalOpen: (x: any) => void
  selectedOptType: OptimizationType
  setSelectedOptType: (x: any) => void
  setAttachFlightsInfo: (x: any) => void
};

const OptimizationTypeBanner = (props: OptimizationTypeBannerProps) => {
  const { isCreateMode, canAccessCrossPlatform, flightLimitReached, optTypeModalOpen, setOptTypeModalOpen, selectedOptType, setSelectedOptType, setAttachFlightsInfo } = props;
  const optimizationLevel = useWatch({ name: 'optimizationLevel' });
  const selectedOptTypeDetails = OPTIMIZATION_TYPE_OPTIONS[selectedOptType];
  const bannerDesc = (configuringCrossPlatformStratCheck(selectedOptType) || !optimizationLevel) ? selectedOptTypeDetails?.bannerDesc : getBannerDesc(selectedOptType, optimizationLevel);

  return (
    <div style={{ ...ATTACH_DSP_OBJECT_STYLES.optTypeBanner, ...(!selectedOptType && { display: 'none' }), ...(flightLimitReached && { marginBottom: 24 }) }}>
      <div style={{ display: 'flex' }}>
        <Icon name="exclamation" className="opt-type-banner-info-icon" circular style={ATTACH_DSP_OBJECT_STYLES.infoIcon} />
        <div>
          <p style={ATTACH_DSP_OBJECT_STYLES.optTypeBannerTitle}>{selectedOptType && selectedOptTypeDetails.bannerTitle}</p>
          <p style={ATTACH_DSP_OBJECT_STYLES.optTypeBannerDesc}>{selectedOptType && bannerDesc}</p>
        </div>
      </div>
      {isCreateMode && (
        <OptimizationTypeSelection
          canAccessCrossPlatform={canAccessCrossPlatform}
          optTypeModalOpen={optTypeModalOpen}
          setOptTypeModalOpen={setOptTypeModalOpen}
          disableOptTypeModal={!isCreateMode}
          selectedOptType={selectedOptType}
          setSelectedOptType={setSelectedOptType}
          setAttachFlightsInfo={setAttachFlightsInfo}
        />
      )}
    </div>
  );
};

export default OptimizationTypeBanner;
