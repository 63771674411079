import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { REVENUE_TYPES_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import { Dropdown, Button, Input } from 'buildingBlocks';
import { RevenueBulkUpdateTypes, RevenueEditForm } from 'containers/StrategyWizard/types';
import { RevTypeOption } from 'containers/StrategyWizard/steps/GoalSelection/constants';

const { buttonStyle, bulkEditButtonMargin, bulkEditContainer, bulkEditCommon } = REVENUE_TYPES_STYLES;

type BulkEditProps = {
  setShowBulkUpdate: Dispatch<SetStateAction<boolean>>
  setShowBulkEdit: Dispatch<SetStateAction<boolean>>
  setBulkEditValues: Dispatch<SetStateAction<RevenueEditForm>>
  bulkEditValues: RevenueEditForm
  updateOutcomeObjects: Dispatch<SetStateAction<string>>
  revenueTypeOptions: Array<RevTypeOption>
  resetRevOptions: () => void
};

const BulkEdit = (props: BulkEditProps) => {
  const { setShowBulkUpdate, setShowBulkEdit, bulkEditValues, setBulkEditValues, updateOutcomeObjects, resetRevOptions,
    revenueTypeOptions } = props;
  const [disableConfirm, setDisableConfirm] = useState<boolean>(true);

  useEffect(() => {
    setDisableConfirm(!bulkEditValues.outcome && !bulkEditValues.value);
  }, [bulkEditValues]);

  const onChangeHandler = (_e, data) => {
    const tempEditObj = { ...bulkEditValues };
    if (data.type === 'number') {
      tempEditObj.value = data.value;
    } else {
      tempEditObj.outcome = data.value;
    }
    setBulkEditValues(tempEditObj);
  };

  return (
    <div style={bulkEditContainer}>
      <Dropdown
        name="outcomes"
        placeholder=""
        options={revenueTypeOptions}
        fluid
        selection
        defaultValue={bulkEditValues.outcome}
        onChange={(e, data) => { onChangeHandler(e, data); }}
        style={bulkEditCommon}
      />
      <Input
        style={bulkEditCommon}
        value={bulkEditValues.value || ''}
        type="number"
        onChange={(e, data) => {
          // Allow for just 4 decimal places
          const val = data.value.split('.').map((el, i) => (i ? el.split('').slice(0, 4).join('') : el)).join('.');
          /* eslint-disable no-param-reassign */
          data.value = val === '.' ? '0.' : val;
          onChangeHandler(e, data);
        }}
      />
      <Button
        style={{ ...buttonStyle, ...bulkEditButtonMargin }}
        onClick={() => { updateOutcomeObjects(RevenueBulkUpdateTypes.update); resetRevOptions(); }}
        disabled={disableConfirm}
      >
        Confirm
      </Button>
      <Button style={buttonStyle} onClick={() => { setShowBulkUpdate(true); setShowBulkEdit(false); }}>Cancel</Button>
    </div>
  );
};

export default BulkEdit;
