import _ from 'lodash';
import React, { CSSProperties, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { GlobalState } from 'reducers';
import { Table } from 'buildingBlocks';
import { FLIGHT_EXTERNAL_TYPE } from 'constantsBase';
import { BudgetAllocationResponse } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/types';
import { FAILURE_TEXT, Status } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/constants';
import { FLIGHT_DETAIL_STYLES } from 'containers/StrategyWizard/steps/AttachFlights/styles';
import { displayDate } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/utils';

const {
  childLevelHeaderCell, childLevelFlightName, dspIcon, objectType, childLevelFlightDates, childLevelEndCell,
  baseChildLevelTableStyle, childFlightsError, activeChildFlightRow, inactiveChildFlightRow,
} = FLIGHT_DETAIL_STYLES;

type ChildLevelFlightDetailsProps = {
  parentFlightExtId: string
  timezone: string
};

type ChildLevelFlightType = {
  extType: number
  name: string
  startDate: Date
  endDate: Date
  active: boolean
};

type ChildLevelFlightWithExtIdType = ChildLevelFlightType & {
  externalId: string
};

type ChildLevelFlightRowProps = {
  flight: ChildLevelFlightWithExtIdType
  timezone: string
};

const ChildLevelFlightRow = ({ flight, timezone }: ChildLevelFlightRowProps) => {
  const { active, extType, startDate, endDate } = flight;
  const externalTypeDisplayName = _.get(FLIGHT_EXTERNAL_TYPE.getById(extType), 'wizardDisplayName') ?? FLIGHT_EXTERNAL_TYPE.getById(extType).displayName;
  const flightDatesToDisplay = `${displayDate(startDate, extType, timezone)} - ${displayDate(endDate, extType, timezone)}`;
  const rowStyle = active ? activeChildFlightRow : inactiveChildFlightRow;
  const getCellStyles = (style: CSSProperties): CSSProperties => ({
    ...style,
    ...{ borderTop: (active ? 'none' : '2px solid white') },
  });

  return (
    <Table.Row style={rowStyle}>
      <Table.Cell style={getCellStyles(childLevelHeaderCell)} />
      <Table.Cell style={getCellStyles(childLevelFlightName)}>{flight.name}</Table.Cell>
      <Table.Cell style={getCellStyles(dspIcon)} />
      <Table.Cell style={getCellStyles(objectType)}>{externalTypeDisplayName}</Table.Cell>
      <Table.Cell style={getCellStyles(childLevelFlightDates)}>{flightDatesToDisplay}</Table.Cell>
      <Table.Cell style={getCellStyles(childLevelEndCell)} />
    </Table.Row>
  );
};

const ChildLevelFlightDetails = ({ parentFlightExtId, timezone }: ChildLevelFlightDetailsProps) => {
  const budgetAllocationState = useSelector<GlobalState>((state) => state.strategyWizard.budgetAllocationState) as BudgetAllocationResponse;
  const budgetAllocationData = _.get(budgetAllocationState, `data[${parentFlightExtId}]`);
  const budgetAllocationDataError = budgetAllocationData === FAILURE_TEXT;
  const [childLevelFlights, setChildLevelFlights] = useState<Array<ChildLevelFlightWithExtIdType>>([]);
  const { kind } = budgetAllocationState;
  const statusHasData = kind === Status.hasData;

  useEffect(() => {
    // create a new array of all the childFlights and add externalId key
    const allChildFlights: Array<ChildLevelFlightWithExtIdType> = _.map({
      ..._.get(budgetAllocationData, 'hierarchy.childExtIdToSettings', {}), // active & not expired
      ..._.get(budgetAllocationData, 'hierarchy.invalidData', {}), // inactive or expired
    }, (flight: ChildLevelFlightType, extId: string) => ({ ...flight, externalId: extId }));
    // sort by active, then by name
    const sortedChildFlights = _.orderBy(allChildFlights, ['active', (flight: ChildLevelFlightWithExtIdType) => flight.name.toLowerCase()], ['desc', 'asc']);
    setChildLevelFlights(sortedChildFlights);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [budgetAllocationState, parentFlightExtId]);

  return (
    <Table style={baseChildLevelTableStyle} striped>
      <Table.Body>
        {(statusHasData && _.size(childLevelFlights))
          ? _.map(childLevelFlights, (flight: ChildLevelFlightWithExtIdType) => <ChildLevelFlightRow flight={flight} key={`${flight.extType} - ${flight.externalId}`} timezone={timezone} />)
          : null}
        {(statusHasData && _.size(childLevelFlights) === 0 && !budgetAllocationDataError)
          ? (
            <Table.Row>
              <Table.Cell style={childLevelHeaderCell} />
              <Table.Cell style={childFlightsError}>No Active Flights Found</Table.Cell>
            </Table.Row>
          ) : null}
        {(kind === Status.error || budgetAllocationDataError)
          ? (
            <Table.Row>
              <Table.Cell style={childLevelHeaderCell} />
              <Table.Cell style={childFlightsError}>Error Fetching Child Flights</Table.Cell>
            </Table.Row>
          ) : null}
      </Table.Body>
    </Table>
  );
};

export default ChildLevelFlightDetails;
