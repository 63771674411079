import _ from 'lodash';
import React from 'react';
import { Button, Grid } from 'buildingBlocks';
import { AWG_WEIGHTS_MODAL_STYLES, FORMULA_SECTION_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import { GrabFormulaFilters } from 'containers/StrategyWizard/steps/GoalSelection/constants';

const { submitBtnStyle, cancelBtnStyle } = AWG_WEIGHTS_MODAL_STYLES;
const { selectButtonStyles, formulaCancel, flexStyle } = FORMULA_SECTION_STYLES;
const cancelButtonStyle = _.omit(cancelBtnStyle, 'marginRight');

type GrabFormulaButtonsProps = {
  isFormulaSelected: boolean
  updateSelectedFormula: (isUpdateFormula: boolean) => void
  handleContinueClick: () => void
  selectedFilter: GrabFormulaFilters
};

const GrabFormulaButtons = ({ isFormulaSelected, updateSelectedFormula, handleContinueClick, selectedFilter }: GrabFormulaButtonsProps) => (
  <Grid.Column width={selectedFilter === GrabFormulaFilters.users ? 4 : 2} style={flexStyle}>
    {!isFormulaSelected ? (
      <Button
        type="button"
        content="Select"
        style={cancelButtonStyle}
        onClick={() => { updateSelectedFormula(true); }}
      />
    ) : (
      <div style={selectButtonStyles}>
        <Button
          type="button"
          content="Continue"
          primary
          style={submitBtnStyle}
          onClick={handleContinueClick}
        />
        <Button
          type="button"
          content="Cancel"
          style={{ ...cancelButtonStyle, ...formulaCancel }}
          onClick={() => { updateSelectedFormula(false); }}
        />
      </div>
    )}
  </Grid.Column>
);

export default GrabFormulaButtons;
