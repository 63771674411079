import React, { Dispatch, SetStateAction, useState } from 'react';
import { FormulaStateType } from 'containers/StrategyWizard/steps/GoalSelection/types';
import { GRAB_FORMULA_INIT_STATE, GrabFormulaFilters } from 'containers/StrategyWizard/steps/GoalSelection/constants';
import FormulaFilters from '../FomulaFilters';
import GrabFormulaList from './GrabFormulaListContainer';

type ModalContentProps = {
  setOpenModal: Dispatch<SetStateAction<boolean>>
};

const GrabFormulaModalContent = ({ setOpenModal }: ModalContentProps) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [filterValue, setFilterValue] = useState<GrabFormulaFilters>(GrabFormulaFilters.users);
  const [loading, setLoading] = useState<boolean>(true);
  const [formulaState, setFormulaState] = useState<FormulaStateType>(GRAB_FORMULA_INIT_STATE);

  const onSearchChange = (newSearchVal: string) => {
    setSearchValue(newSearchVal);
  };

  const handleFilterChange = (newFilter: GrabFormulaFilters) => {
    setFilterValue(newFilter);
    setFormulaState(GRAB_FORMULA_INIT_STATE);
  };

  return (
    <>
      <FormulaFilters
        onSearchChange={onSearchChange}
        searchValue={searchValue}
        filterValue={filterValue}
        setFilterValue={handleFilterChange}
        loading={loading}
      />
      <GrabFormulaList
        searchValue={searchValue}
        filterValue={filterValue}
        setOpenModal={setOpenModal}
        loading={loading}
        setLoading={setLoading}
        formulaState={formulaState}
        setFormulaState={setFormulaState}
      />
    </>
  );
};

export default GrabFormulaModalContent;
