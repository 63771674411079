import _ from 'lodash';
import React, { useEffect, SetStateAction, Dispatch } from 'react';
import { Checkbox, Grid } from 'buildingBlocks';
import { REVENUE_TYPES_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import { RevTypeOption, DSP_HEADER_TOP } from 'containers/StrategyWizard/steps/GoalSelection/constants';
import { useGoalSectionContext } from 'containers/StrategyWizard/steps/GoalSelection/contexts/GoalSectionProvider';
import { RevenueTypeOutcomes } from 'containers/StrategyWizard/types';
import OutcomesOptions from './OutcomesOptions';

const {
  outcomesTableContainer, outcomesTableHeader, outcomesOptionsContainer, baseColumnStyles,
  noResultRow, noResultColumn,
} = REVENUE_TYPES_STYLES;

type OutcomesProps = {
  revOutcomes: RevenueTypeOutcomes
  setShowBulkUpdate: Dispatch<SetStateAction<boolean>>
  setSelectAllChecked: Dispatch<SetStateAction<boolean>>
  selectAllChecked: boolean
  setSelectedObjs: Dispatch<SetStateAction<Array<string>>>
  selectedObjects: Array<string>
  updateSingleOutcome: (extId: string, value: string | number) => void
  revenueTypeOptions: Array<RevTypeOption>
  showBulkEdit: boolean
};

const OutcomesTable = (props: OutcomesProps) => {
  const {
    revOutcomes, setShowBulkUpdate, setSelectAllChecked, showBulkEdit, selectAllChecked,
    selectedObjects, setSelectedObjs, updateSingleOutcome, revenueTypeOptions,
  } = props;
  const { revTypeSearchStr } = useGoalSectionContext();

  useEffect(() => {
    if (!showBulkEdit) setShowBulkUpdate(!!_.size(selectedObjects));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedObjects]);

  const onSelectAllClick = () => {
    if (!selectAllChecked) {
      const allObjs = Object.keys(revOutcomes);
      const selectedObjIds = allObjs.flatMap((topKey) => Object.keys(revOutcomes[topKey]));
      setSelectedObjs(selectedObjIds);
      setSelectAllChecked(true);
    } else {
      setSelectedObjs([]);
      setSelectAllChecked(false);
    }
  };

  const getDspHeaderStyles = (key) => {
    const index = Object.keys(revOutcomes).indexOf(key);
    const top = DSP_HEADER_TOP * index;
    return { top: `${top}px` };
  };

  return (
    <Grid style={outcomesTableContainer}>
      <Grid.Row columns={3} style={outcomesTableHeader}>
        <Grid.Column width={8} style={baseColumnStyles}>
          <Checkbox
            onClick={onSelectAllClick}
            checked={selectAllChecked && !_.isEmpty(revOutcomes)}
            style={REVENUE_TYPES_STYLES.outcomesRowCheckbox}
          />
          Campaigns
        </Grid.Column>
        <Grid.Column width={2}>
          Billable Outcome
        </Grid.Column>
        <Grid.Column width={2}>
          Billable Value
        </Grid.Column>
      </Grid.Row>
      <div style={outcomesOptionsContainer} className="revenueOutcomesList">
        {!_.isEmpty(revOutcomes) && (
          <>
            {_.map(revOutcomes, (outcomesObj, dsp) => (
              <OutcomesOptions
                key={dsp}
                dsp={_.toNumber(dsp)}
                outcomes={outcomesObj}
                headerStyles={getDspHeaderStyles(dsp)}
                selectedObjects={selectedObjects}
                setSelectedObjs={setSelectedObjs}
                updateSingleOutcome={updateSingleOutcome}
                revenueTypeOptions={revenueTypeOptions}
              />
            ))}
          </>
        )}
        {_.isEmpty(revOutcomes) && (
          <Grid.Row style={noResultRow}>
            <Grid.Column style={noResultColumn}>
              {!_.isEmpty(revTypeSearchStr) ? `No results found for ${revTypeSearchStr}.` : 'No active flights found'}.
            </Grid.Column>
          </Grid.Row>
        )}
      </div>
    </Grid>
  );
};

export default OutcomesTable;
