/* eslint-disable no-param-reassign */
import _ from 'lodash';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Map as ImmutableMap } from 'immutable';
import { Button, Grid } from 'buildingBlocks';
import { AWG_WEIGHTS_MODAL_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import { useWeightsModalContentContext } from './contexts/WeightsModalContentProvider';
import { useWeightsSectionContext } from './contexts/WeightsSectionProvider';
import { useAWGContext } from './contexts/AWGProvider';

const { footerStyles, resetBtnStyle, undoHovered, cancelBtnStyle, submitBtnStyle } = AWG_WEIGHTS_MODAL_STYLES;

const ModalFooter = () => {
  const { setFormulaMetrics } = useAWGContext();
  const {
    handleModalClose, sessionMetrics, setSessionMetrics,
    setToDefaultMetric, defaultOptTypeDimension, setSelectedDimension,
  } = useWeightsSectionContext();
  const { formErrors, setAttemptSave } = useWeightsModalContentContext();
  const { setValue } = useFormContext();
  const [hovered, setHovered] = useState<boolean>(false);

  const handleSave = () => {
    setAttemptSave(true);
    if (_.isEmpty(formErrors)) {
      const cleansedMetrics = sessionMetrics.map((metric) => {
        // handle edit specific metric but doesn't weight
        if (!metric.get('weighting').size) {
          return metric.set('dimension', defaultOptTypeDimension);
        }
        return metric;
      });
      setFormulaMetrics(cleansedMetrics.toJS());
      setValue('metricsConfig', cleansedMetrics.toJS());
      handleModalClose();
    }
  };

  const handleReset = (_e) => {
    setAttemptSave(false);
    const defaultConfig = sessionMetrics.map((config) => config.merge({
      dimension: defaultOptTypeDimension,
      isWeighted: false,
      weighting: ImmutableMap(),
    }));
    setSessionMetrics(defaultConfig);
    setToDefaultMetric();
    setSelectedDimension(defaultOptTypeDimension);
  };

  return (
    <Grid style={{ margin: 0 }}>
      <Grid.Row columns={2} style={footerStyles}>
        <Grid.Column width={8}>
          <Button
            style={{ ...resetBtnStyle, ...(hovered && undoHovered) }}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            onClick={handleReset}
            content="Reset"
            secondary
          />
        </Grid.Column>
        <Grid.Column width={4} style={{ display: 'flex', justifyContent: 'right' }}>
          <Button secondary style={cancelBtnStyle} onClick={handleModalClose}>Cancel</Button>
          <Button primary style={submitBtnStyle} onClick={handleSave}>Submit</Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default ModalFooter;
