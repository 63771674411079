import _ from 'lodash';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Grid, Header } from 'buildingBlocks';
import ErrorMessage from 'components/ErrorComponent/ErrorMessage';
import { CHARACTER_LIMIT } from 'containers/StrategyWizard/steps/GoalSelection/constants';
import { FORMULA_SECTION_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import Tooltip from 'containers/Tooltip';
import Dropzone from './Dropzone';
import { useFormulaContext } from '../contexts/FormulaProvider';

const {
  flexSpaceBetween, sectionHeaderContainer, sectionHeader, dropzoneErrorStyle, characterInfoSection,
  characterText, tooltip, dropAreaInfoSection, characterCountStyle, characterCountErrorStyle,
} = FORMULA_SECTION_STYLES;

const TOOL_TIP_CONTENT = 'You can drag and drop metrics and operators into this space or type them in with your keyboard. If you make a mistake you can use the undo and redo buttons at the bottom left. Happy formula building!';

const DropHeader = () => (
  <div style={flexSpaceBetween}>
    <div style={sectionHeaderContainer}>
      <Header as="h6" content="Formula Builder" style={sectionHeader} />
      <Tooltip content={TOOL_TIP_CONTENT} tooltipstyling={tooltip} />
    </div>
  </div>
);

const DropArea = () => {
  const { control, formState: { errors } } = useFormContext();
  const { characterCount } = useFormulaContext();
  const hasCharacterLimitError = characterCount > CHARACTER_LIMIT;

  return (
    <Grid.Column style={{ padding: '0 16px' }}>
      <DropHeader />
      <Controller
        name="customGoal.formula"
        control={control}
        rules={{
          required: 'You must enter a formula in the Formula Builder window.',
        }}
        render={() => <Dropzone />}
      />
      <div style={dropAreaInfoSection}>
        {!_.isEmpty(_.get(errors, 'customGoal.formula')) && (
        <ErrorMessage
          errorText={_.get(errors, 'customGoal.formula.message') as unknown as string}
          style={dropzoneErrorStyle}
        />
        )}
        <div style={characterInfoSection}>
          <div style={characterText}>Characters:</div>
          <div style={{ ...characterCountStyle, ...(hasCharacterLimitError ? characterCountErrorStyle : {}) }}>{characterCount}/{CHARACTER_LIMIT}</div>
        </div>
      </div>
    </Grid.Column>
  ); };

export default DropArea;
