import _ from 'lodash';
import React from 'react';
import { Grid, Header } from 'buildingBlocks';
import { FORMULA_SECTION_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import { ALL_OPERATORS } from 'containers/StrategyWizard/constants';
import DraggableComponent from './DraggableComponent';

const { verticalDivider, sectionHeader, operatorContainer } = FORMULA_SECTION_STYLES;

const OperatorsSection = () => (
  <Grid.Column width={2} style={verticalDivider}>
    <Header as="h6" content="Operators" style={sectionHeader} />
    <div style={operatorContainer}>
      {_.map(ALL_OPERATORS, (operator) => (
        <DraggableComponent key={operator} dragContent={operator} />
      ))}
    </div>
  </Grid.Column>
);

export default OperatorsSection;
