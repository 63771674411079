import _ from 'lodash';
import React from 'react';
import { Map as ImmutableMap } from 'immutable';
import { Dropdown, Grid } from 'buildingBlocks';
import { AWGDimensions } from 'constantsBase';
import { AWG_WEIGHTS_MODAL_STYLES, CYOD_MODAL_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import { getDimensionText } from 'containers/StrategyWizard/steps/GoalSelection/utils';
import { MetricConfigObj } from 'containers/StrategyWizard/types';
import { CONVERSION_METRICS } from 'containers/StrategyWizard/constants';
import { useWeightsSectionContext } from '../contexts/WeightsSectionProvider';
import { useAWGContext } from '../contexts/AWGProvider';

const DimensionsDropdown = () => {
  const { isCampaignOptType } = useAWGContext();
  const dropdownOptions = isCampaignOptType ? [AWGDimensions.lineItem] : AWGDimensions;
  const placeholderDimension = isCampaignOptType ? AWGDimensions.lineItem : AWGDimensions.insertionOrder;
  const {
    sessionMetrics, selectedMetric, selectedDimension,
    setSelectedDimension, setSessionMetrics,
  } = useWeightsSectionContext();

  const handleOnChange = (_e, data) => {
    setSelectedDimension(data.value);
    // reset weighting and isWeighted when changing dimensions
    const updated = sessionMetrics.updateIn([selectedMetric], (metric: ImmutableMap<string, MetricConfigObj>) => (
      metric.merge({
        dimension: data.value,
        isWeighted: false,
        weighting: ImmutableMap(),
      })
    ));
    setSessionMetrics(updated);
  };

  const allDropdownOptions = _.map(dropdownOptions, (ds) => ({ text: getDimensionText(ds), value: ds }));
  const filteredOptions = _.includes(CONVERSION_METRICS, selectedMetric) ? allDropdownOptions : _.reject(allDropdownOptions, ['value', AWGDimensions.pixel]);

  return (
    <Grid style={CYOD_MODAL_STYLES.noMargin}>
      <Grid.Row columns={2} style={AWG_WEIGHTS_MODAL_STYLES.filterCard}>
        <Grid.Column width={9}>
          <div style={AWG_WEIGHTS_MODAL_STYLES.filterDesc}>
            <span>Assign weights to {selectedDimension === AWGDimensions.dsp ? 'objects in the buying platform' : `${getDimensionText(selectedDimension)}s`}.</span>
          </div>
        </Grid.Column>
        <Grid.Column width={3} style={{ padding: 0 }}>
          <Dropdown
            name="dimension"
            placeholder={getDimensionText(placeholderDimension)}
            options={filteredOptions}
            fluid
            selection
            value={selectedDimension}
            onChange={handleOnChange}
            disabled={isCampaignOptType}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default DimensionsDropdown;
