import React from 'react';
import { Grid } from 'buildingBlocks';
import { REVENUE_TYPES_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';

type ValidationMessagesProps = {
  hasValueErrors: boolean
  hasOutcomeErrors: boolean
  isValueEmpty: boolean
};

const ValidationMessages = (props: ValidationMessagesProps) => {
  const { hasOutcomeErrors, hasValueErrors, isValueEmpty } = props;
  const textAlign = { textAlign: hasValueErrors ? 'right' : 'left', top: '-5px' };

  return (
    <Grid.Row columns={4} style={REVENUE_TYPES_STYLES.errorMessagesDiv}>
      <Grid.Column width={8} /> {/* Empty column to match previous structure */}
      <Grid.Column width={4} style={{ ...REVENUE_TYPES_STYLES.errorMessageStyle, ...textAlign }}>
        {hasOutcomeErrors && !hasValueErrors && <p>Outcome is required.</p>}
        {!hasOutcomeErrors && hasValueErrors && <p>{isValueEmpty ? 'Revenue Value is required' : 'Must be greater than 0'}.</p>}
        {hasOutcomeErrors && hasValueErrors && <p>Indicate outcome and values for all campaigns.</p>}
      </Grid.Column>
    </Grid.Row>
  );
};

export default ValidationMessages;
