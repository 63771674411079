import _ from 'lodash';
import React, { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import { Dropdown } from 'buildingBlocks';
import { ALL_METRICS, ALL_OPERATORS, METRIC_SEARCH_OBJS } from 'containers/StrategyWizard/constants';
import { checkIsMetric } from 'containers/StrategyWizard/utils';
import { FORMULA_SECTION_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import { MetricObj } from 'containers/StrategyWizard/steps/GoalSelection/types';
import { COPILOT_COLORS } from 'globalStyles';
import { generateRandomUUID } from 'utils/formattingUtils';
import keymap from 'utils/keymap';
import { useFormulaContext } from '../contexts/FormulaProvider';
import './customInputElement.css';
import { ActionTypes } from '../contexts/types';

const { METTLES } = COPILOT_COLORS.NEW_DESIGN_SYSTEM;

type CustomInputElementProps = {
  shouldBlink: boolean
  setShouldBlink: Dispatch<SetStateAction<boolean>>
};

const { ENTER, KEY_ARROW_UP, KEY_ARROW_DOWN, BACKSPACE, DELETE, ESCAPE, TAB } = keymap;

const CustomInputElement = ({ shouldBlink, setShouldBlink }: CustomInputElementProps) => {
  const { cursorIdx, insertItemAt, setCursorIdx, setDropzoneActive, addUndoAction } = useFormulaContext();
  const [input, setInput] = useState<string>('');
  const [isNumberInput, setIsNumberInput] = useState<boolean>(false);
  const [openDropdown, setOpenDropdown] = useState<boolean>(!!input);
  const [tabIdx, setTabIdx] = useState<number>(0);
  const [options, setOptions] = useState<Array<MetricObj>>([]);
  const dynamicWidth = `${Math.max(24, (input.length + 1) * 8)}px`;

  const handleCancel = () => {
    setShouldBlink(false);
  };

  const handleAddNewMetric = (metricContent: string) => {
    const newMetricObj = {
      id: generateRandomUUID(),
      content: (_.includes(ALL_OPERATORS, metricContent) || isNumberInput) ? metricContent : _.startCase(metricContent),
    };
    insertItemAt(newMetricObj, cursorIdx);
    setCursorIdx(cursorIdx + 1);
    addUndoAction({
      action: ActionTypes.add,
      content: newMetricObj.content,
      contentId: newMetricObj.id,
      contentIdx: cursorIdx,
      cursorIdx: cursorIdx + 1,
    });
    setShouldBlink(false);
    setDropzoneActive(true);
  };

  const handlePressingEnter = (e, data: { value: string }) => {
    const clickedOrPressedEnter = ['keydown', 'click'].includes(e.type);
    if (clickedOrPressedEnter) {
      if (data.value === 'cancel' || _.endsWith(data.value, 'cancel')) {
        handleCancel();
        return;
      }
      const metricToAdd = _.head(_.filter(ALL_METRICS, (m) => m === data.value));
      if (metricToAdd) {
        handleAddNewMetric(metricToAdd);
      }
    }
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    // do not support commas or typing a letter after a number
    if (_.includes(query, ',') || (isNumberInput && (/[a-zA-Z]/.test(query)))) return;
    if (_.includes(ALL_OPERATORS, query)) {
      handleAddNewMetric(query);
      return;
    }
    if (query === '.') {
      setIsNumberInput(true);
      setInput(query);
      setOptions([]);
      return;
    }
    // handle trying to type a . when there's already one in the query
    if (isNumberInput && (_.includes(query.slice(0, -1), '.') && _.endsWith(query, '.'))) return;
    // using parseInt since _.toNumber treats '' as 0
    if (!_.isNaN(parseInt(query, 10))) {
      // handles when input is . followed by a letter
      if (_.isNaN(parseInt(query, 10)) || (_.startsWith(query, '.') && _.isNaN(parseInt(query.slice(1), 10)))) return;
      setOpenDropdown(false);
      setIsNumberInput(true);
      setOptions([]);
      setInput(query);
      return;
    }
    // handle trying to type anything besides a letter when input isnt a number
    if (!isNumberInput && (/[^a-zA-Z]/.test(query))) return;
    setIsNumberInput(false);
    setInput(query);
    const filteredOptions = _.filter(METRIC_SEARCH_OBJS, (metric) => _.startsWith(metric.value, _.camelCase(_.toLower(query))));
    setOptions(filteredOptions);
  };

  const handleArrowKeyNavigation = (e) => {
    switch (e.keyCode) {
      case (KEY_ARROW_UP):
        if (!tabIdx) return;
        setTabIdx(tabIdx - 1);
        break;
      case (KEY_ARROW_DOWN):
        if (tabIdx === _.size(options)) return;
        setTabIdx(tabIdx + 1);
        break;
      case (TAB):
      case (ENTER):
        e.preventDefault();
        // eslint-disable-next-line no-case-declarations
        const typedEntireMetric = checkIsMetric(_.camelCase(_.toLower(input)));
        if (!tabIdx && !isNumberInput && !typedEntireMetric) {
          handleCancel();
        } else if (isNumberInput || typedEntireMetric) {
          handleAddNewMetric(isNumberInput ? input : _.camelCase(_.toLower(input)));
        } else {
          const optionSelected = options[tabIdx - 1];
          handleAddNewMetric(optionSelected.value);
        }
        break;
      case (ESCAPE):
        handleCancel();
        break;
      case (BACKSPACE):
      case (DELETE):
        if (_.isEmpty(input)) {
          handleCancel();
        }
        break;
      default:
        break;
    }
  };

  return (
    <div
      style={{
        ...FORMULA_SECTION_STYLES.customInput,
        minWidth: '24px',
        width: dynamicWidth,
        // @ts-ignore using css variable here
        '--dropdown-width': dynamicWidth,
        '--custom-input-search-color': METTLES.M900_TROUT,
      }}
    >
      <Dropdown
        floating
        search
        labeled
        defaultOpen={shouldBlink}
        className="hide-icon"
        searchQuery={input}
        onSearchChange={handleSearch}
        selectOnNavigation={false}
        onBlur={handleCancel}
        onKeyDown={handleArrowKeyNavigation}
      >
        <Dropdown.Menu open={openDropdown} style={{ width: '200px', border: 'none' }}>
          {!isNumberInput && (
          <Dropdown.Item
            icon="delete"
            text="Cancel"
            value="cancel"
            className="dropdown-cancel-btn"
            onClick={handlePressingEnter}
            active={tabIdx === 0}
            selected={tabIdx === 0}
          />
          )}
          {options.map((option, idx) => (
            <Dropdown.Item
              // eslint-disable-next-line react/no-array-index-key
              key={idx + 1}
              onClick={handlePressingEnter}
              active={tabIdx === idx + 1}
              selected={tabIdx === idx + 1}
              {...option}
            />
          ))}
          {(!options.length && !isNumberInput) && <Dropdown.Item className="no-item-found">No valid metric or operator found. Try again.</Dropdown.Item>}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default CustomInputElement;
