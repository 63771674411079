import _ from 'lodash';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { GrabFormulaFilters } from 'containers/StrategyWizard/steps/GoalSelection/constants';
import { getFormulaData } from 'containers/StrategyWizard/steps/GoalSelection/utils';
import { Dimmer, Loader } from 'buildingBlocks';
import { DataRange, FormulaStateType, GoalData } from 'containers/StrategyWizard/steps/GoalSelection/types';
import { FORMULA_SECTION_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import { useAsyncEffect } from 'utils/functionHelpers';
import GrabFormulaList from './GrabFormulaList';

const { dimmerStyle, noGoalsStyle } = FORMULA_SECTION_STYLES;

type GrabFormulaListProps = {
  searchValue: string
  filterValue: GrabFormulaFilters
  setOpenModal: Dispatch<SetStateAction<boolean>>
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  setFormulaState: (obj: FormulaStateType) => void
  formulaState: FormulaStateType
};
type NoGoalProps = {
  searchValue: string
};

const GetNoGoalsMessage = ({ searchValue }: NoGoalProps) => (
  <div style={noGoalsStyle}>
    {_.isEmpty(searchValue) ? 'No Goals found.' : `No results found for “${searchValue}”. Try a new search.`}
  </div>
);

const ErrorMessage = ({ message }: { message: string }) => (
  <div style={noGoalsStyle}>{message}</div>
);

const GrabFormulaListContainer = (props: GrabFormulaListProps) => {
  const { searchValue, filterValue, setOpenModal, loading, setLoading, formulaState, setFormulaState } = props;
  const [stratGoals, setStratGoals] = useState<Array<GoalData>>();
  const [goalCount, setGoalCount] = useState<number>(0);
  const [errorMsg, setErrorMessage] = useState<string>('');

  useAsyncEffect(async () => {
    setLoading(true);
    try {
      const formulaData = await getFormulaData(formulaState.limit, formulaState.skip, searchValue, filterValue);
      const goalData = _.get(formulaData, 'strategyGoals');
      setGoalCount(_.get(formulaData, 'strategyGoalCount'));
      setStratGoals(goalData);
    } catch {
      setErrorMessage('Error fetching data.');
    } finally {
      setLoading(false);
    }
  }, [searchValue, filterValue, formulaState]);

  const onDataRangeChange = ({ limit, start }: DataRange) => {
    const skip = start;
    setFormulaState({ limit, startingPage: (skip / limit) + 1, skip });
  };

  return (
    <>
      <GrabFormulaList
        onDataRangeChange={onDataRangeChange}
        goalCount={goalCount}
        filterValue={filterValue}
        setOpenModal={setOpenModal}
        formulaState={formulaState}
        stratGoals={stratGoals}
      />
      {errorMsg && <ErrorMessage message={errorMsg} />}
      {!loading && goalCount === 0 && !errorMsg && <GetNoGoalsMessage searchValue={searchValue} />}
      {loading && (
        <Dimmer active inverted style={dimmerStyle}>
          <Loader active inverted size="large" />
        </Dimmer>
      )}
    </>
  );
};

export default GrabFormulaListContainer;
