import _ from 'lodash';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Form, ObjectDropdown } from 'buildingBlocks';
import { getOptionsWithDspIcon } from 'components/OptionWithIcon';
import { DSP } from 'constantsBase';
import {
  OptimizationType, modalSessionFlightsInfoInitialState, ALL, getAllOption,
} from 'containers/StrategyWizard/steps/AttachFlights/constants';
import { MODAL_STYLES } from 'containers/StrategyWizard/steps/AttachFlights/styles';
import { configuringCrossPlatformStratCheck, configuringLineItemStratCheck } from 'containers/StrategyWizard/steps/AttachFlights/utils';
import { CONVERSION_BASED_GOALS } from 'containers/StrategyWizard/steps/GoalSelection/constants';
import { WizardFormAttachFlights } from 'containers/StrategyWizard/types';
import { GlobalState } from 'reducers';
import { useMemberFetcher } from 'utils/hooks/useMemberFetcher';
import { searchByNameOrExtId } from 'utils/semanticUISearch';
import { Member, User } from 'utils/types';
import { Permission } from 'utils/featureFlags';
import { getOptimizationLevel } from './utils';

type MemberFormFieldProps = {
  selectedOptType: OptimizationType
  hasFlightsAttached: boolean
  setModalSessionFlightsInfo: (x: any) => void
  loadingFlightCandidates: boolean
  memAdvBrandFilter: Array<{ memberId: number, advertiserId: number }> | null
  strategyId?: number
};

const MemberFormField = (props: MemberFormFieldProps) => {
  const {
    selectedOptType,
    hasFlightsAttached,
    setModalSessionFlightsInfo,
    loadingFlightCandidates,
    memAdvBrandFilter,
    strategyId,
  } = props;
  const user = useSelector<GlobalState>((rootState) => rootState.login.user) as User;
  const goalType = useSelector<GlobalState>((state) => state.strategyWizard.goalSelectionStep.goal.type);
  const configuringCrossPlatformStrat = configuringCrossPlatformStratCheck(selectedOptType);
  const configuringLineItemStrat = configuringLineItemStratCheck(selectedOptType);
  const brandSpecificMemIds = memAdvBrandFilter && _.uniq(_.map(memAdvBrandFilter, 'memberId'));
  const permissionsBlackList = configuringCrossPlatformStrat ? [Permission.stratCrossPlatformOptimization] : [];
  const [members, memberFetchError] = useMemberFetcher(user, permissionsBlackList, selectedOptType);
  const { control, setValue } = useFormContext<WizardFormAttachFlights>();
  const brand = useWatch({ name: 'brand' });
  const selectedMember = useWatch({ name: 'member' });
  const optimizationLevel = useWatch({ name: 'optimizationLevel' });
  const isCreateMode = _.isNil(strategyId);

  const newStratWithNoFlights = !hasFlightsAttached && isCreateMode;
  const canOnlyAccessSingleMember = _.size(members) === 1;
  const disabled = !!memberFetchError
    || (configuringCrossPlatformStrat && !isCreateMode && !brand)
    || (configuringCrossPlatformStrat && isCreateMode && hasFlightsAttached && !brand)
    || (!configuringCrossPlatformStrat && (hasFlightsAttached || !isCreateMode))
    || canOnlyAccessSingleMember
    || loadingFlightCandidates;
  const classForStylingOverride = (newStratWithNoFlights && (loadingFlightCandidates || canOnlyAccessSingleMember)) ? 'conditionally-loading-flights' : '';
  // filter out AMZN and DV360 members when configuring child level strategy because there are no available strat types
  const membersToShow = configuringLineItemStrat ? _.filter(members, (mem) => !_.includes([DSP.DBM.id, DSP.AMZN.id], mem.dsp)) : members;
  // remove Amazon members when configuring a cross-platform strat with a pixel supported goal type
  const membersToUse = (configuringCrossPlatformStrat && _.includes(CONVERSION_BASED_GOALS, goalType))
    ? _.filter(membersToShow, (mem) => !_.isEqual(mem.dsp, DSP.AMZN.id))
    : membersToShow;
  const filteredMembers = memAdvBrandFilter ? _.filter(membersToUse, (m) => _.includes(brandSpecificMemIds, m.id)) : membersToUse;
  const displayText = brand ? 'All' : 'Search Members...';

  const handleSeatSelect = (mem: Member) => {
    let optLevel;
    if (_.isEqual(mem.displayName, ALL)) {
      setValue('member', null);
      optLevel = null;
    } else {
      setValue('member', mem);
      optLevel = getOptimizationLevel(mem, selectedOptType);
    }
    setValue('advertiser', null);
    if (!_.isEqual(optimizationLevel, optLevel)) {
      setValue('optimizationLevel', optLevel);
    }
    // reset modalSessionFlightsInfo if there is no brand and no flights attached
    if ((!brand || !configuringCrossPlatformStrat) && !hasFlightsAttached) {
      setModalSessionFlightsInfo(modalSessionFlightsInfoInitialState);
    }
  };

  // if user only has access to a single member, set the member because the member field will be disabled
  useEffect(() => {
    if (canOnlyAccessSingleMember && !_.isEqual(selectedMember, _.head(members))) {
      const accessibleMember = _.head(members) as Member;
      setValue('member', accessibleMember);
      setValue('optimizationLevel', getOptimizationLevel(accessibleMember, selectedOptType));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [members]);

  const options = (selectedMember && configuringCrossPlatformStrat && brand && _.size(filteredMembers) > 1) ? [getAllOption(true), ...getOptionsWithDspIcon(filteredMembers)] : getOptionsWithDspIcon(filteredMembers);

  return (
    <Form.Field style={{ width: '100.75%' }}>
      <label htmlFor="member" style={MODAL_STYLES.formFieldLabel}>Member</label>
      <Controller
        name="member"
        control={control}
        rules={{ required: true }}
        render={() => (
          <ObjectDropdown
            fluid
            selection
            className={classForStylingOverride}
            options={options}
            keyFn={(mem) => (_.isEqual(mem.displayName, ALL) ? mem.displayName : `${DSP.getById(_.get(mem, 'dsp') as number).code} - ${mem.displayName}`)}
            onChange={handleSeatSelect}
            selectOnBlur={false}
            disabled={disabled}
            text={selectedMember ? selectedMember.displayName : displayText}
            placeholder={selectedMember ? null : 'Search Members...'}
            search={{
              searchType: 'local',
              onSearchChange: searchByNameOrExtId(),
            }}
          />
        )}
      />
    </Form.Field>
  );
};

export default MemberFormField;
