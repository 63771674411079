import _ from 'lodash';
import React, { CSSProperties, Dispatch, SetStateAction, useState } from 'react';
import { Map as ImmutableMap } from 'immutable';
import { Icon } from 'buildingBlocks';
import dspIconStyles from 'components/DspIcon/style';
import { AWGDimensions, DSP } from 'constantsBase';
import { WeightObj } from 'containers/StrategyWizard/types';
import { AWG_WEIGHTS_MODAL_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import { getDimensionText } from 'containers/StrategyWizard/steps/GoalSelection/utils';
import { COPILOT_LAYOUT } from 'globalStyles';
import WeightsList from './WeightsList';
import { useWeightsSectionContext } from '../contexts/WeightsSectionProvider';

const { SPACING } = COPILOT_LAYOUT;

type DspAccordionProps = {
  dspId: string
  flights: WeightObj
  headerStyles: CSSProperties
  flightsToDisplay: ImmutableMap<string, WeightObj>
  setFlightsToDisplay: Dispatch<SetStateAction<ImmutableMap<string, WeightObj>>>
};

const DspAccordion = (props: DspAccordionProps) => {
  const { dspId, flights, headerStyles, flightsToDisplay, setFlightsToDisplay } = props;
  const { selectedDimension } = useWeightsSectionContext();
  const [showFlightsList, setShowFlightsList] = useState<boolean>(true);
  const dspCode = DSP.getById(dspId).code;
  const dspHeaderStyle = { ...dspIconStyles[dspCode], ...(!showFlightsList && { marginBottom: SPACING[8] }) };
  const noFlightsFoundMsgText = selectedDimension === AWGDimensions.pixel && _.isString(flights)
    ? <span> Error loading {dspCode} Pixels</span>
    : <span>No {getDimensionText(selectedDimension)}s found.</span>;
  return (
    <>
      <div
        key={dspId}
        style={_.merge({}, AWG_WEIGHTS_MODAL_STYLES.stickyHeader, dspHeaderStyle, headerStyles)}
        className="stickyHeader"
        onClick={() => setShowFlightsList(!showFlightsList)}
        role="presentation"
      >
        <Icon
          name={showFlightsList ? 'angle down' : 'angle right'}
          size="large"
        />
        {dspCode}
      </div>
      <div style={{ ...(!showFlightsList && { display: 'none' }) }}>
        {
          _.size(flights)
            ? (
              <WeightsList
                dspId={dspId}
                weightsList={flights}
                flightsToDisplay={flightsToDisplay}
                setFlightsToDisplay={setFlightsToDisplay}
              />
            )
            : noFlightsFoundMsgText
        }
      </div>
    </>
  );
};

export default DspAccordion;
